<li class="nav-item" *ngFor = "let item of menuItems" 
style="padding-left: 10px;">
  
    <a *ngIf="item.url != ''"
        [routerLink]="[item.url]" 
        [routerLinkActive]="['active']"
        class="nav-link">
      <i class="{{item.icone}}"></i>
      <p style="padding-left: 10px;">{{item.nome}}</p>
    </a>
  
  
    <a *ngIf="item.url == ''" 
      class="nav-link">
      <i class="{{item.icone}}"></i>
      <p style="padding-left: 10px;">{{item.nome}}
      </p>
    </a>
  
  

    <ul class="nav nav-treeview" style="display: none">
      <app-menuitem [menuItems]="item.itemsFilhos"></app-menuitem>
    </ul>
</li>


