<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i>
							Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/paa']"
							>PAAC - Plano Anual de Auditoria e Controles</a
						>
					</li>
					<li class="breadcrumb-item active">{{ titulo }}</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header d-flex pb-0">
						<h3 class="card-title mb-0">
							<i
								*ngIf="this.id"
								class="fas fa-pencil-alt pr-2"
							></i>
							<i *ngIf="!this.id" class="far fa-file pr-2"></i>
							{{ tituloAbas }}
						</h3>

						<ul class="nav nav-tabs ml-auto p-0">
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 0 }"
									(click)="mudarAba(0)"
									>Dados</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 1 }"
									(click)="mudarAba(1)"
									>Indicadores e Critérios</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 2 }"
									(click)="mudarAba(2)"
									>Capacidade de Trabalho</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 3 }"
									(click)="mudarAba(3)"
									>Painel de Distribuição</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 4 }"
									(click)="mudarAba(4)"
									>Alocação de Equipes</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 5 }"
									(click)="mudarAba(5)"
									>Relatoria</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 6 }"
									(click)="mudarAba(6)"
									>Linha do Tempo</a
								>
							</li>
						</ul>
					</div>

					<form
						[formGroup]="form"
						#f="ngForm"
						(keydown.enter)="$event.preventDefault()"
					>
						<div class="card-body pb-0">
							<!-- ABA DADOS -->
							<div #abaDados *ngIf="aba === 0">
								<app-paa-aba-dados-basicos (abaEmitter)="setSubmit($event)">
								</app-paa-aba-dados-basicos>
							</div>
													

							<!-- ABA INDICADORES CRITERIOS -->
							<div #abaIndicesCriterios *ngIf ="aba === 1">
								<div>
								  <app-paa-aba-indices-criterios>
								  </app-paa-aba-indices-criterios>
								</div>
							  </div>

							

							<!-- ABA CAPACIDADE -->
							<div #abaCapacidade *ngIf="aba === 2">
								<app-paa-aba-capacidade>

								</app-paa-aba-capacidade>
							</div>

							<!-- RANKING PRIORIDADE -->
							<!-- <div #abaRanking *ngIf="aba === 3">
								<app-ranking-prioridade
									[rankingPrioridadeDataSource]="
										rankingPrioridadeDataSource
									"
									[rankingPrioridadeEmpresasPublicasDataSource]="
										rankingPrioridadeEmpresasPublicasDataSource
									"
									[semDadosRanking]="semDadosRanking"
									[semDadosRankingEmpresaPublica]="
										semDadosRankingEmpresaPublica
									"
								>
								</app-ranking-prioridade>
							</div> -->
							<!-- Fim -->

							<!-- ABA DISTRIBUICAO -->
							<div #abaDistribuicao *ngIf="aba === 3">
								<div>
									<app-paa-aba-distribuicao>

									</app-paa-aba-distribuicao>
								</div>
							</div>
							<!-- Fim -->

							<!-- ABA ALOCAÇÃO EQUIPES -->
							<div #abaAlocacao *ngIf="aba === 4">
								<div>
									<app-paa-aba-alocacao>
									</app-paa-aba-alocacao>
								</div>
							</div>
							<!-- Fim -->

							<!-- ABA RELATORIA -->
							<div #abaRelatoria *ngIf="aba === 5">
								<app-relatoria
									[aba]="aba"
									[relatoriaDados]="relatoriaDados"
									[visualizar]="visualizarRelatoria"
									[submit]="submit"
									[obterDados]="obterDados.bind(this)"
									[model]="model"
									[tipoTrabalhoId]="id"
									[tipoTrabalhoRef]="obterTipoTrabalho()"
								>
								</app-relatoria>
							</div>
							<!-- Fim -->

							<!-- ABA LINHA DO TEMPO -->
							<div #abaLinhaTempo *ngIf="aba === 6">
								<app-linha-do-tempo 
								[aba]="aba"
								[tipoTrabalhoRef]="obterTipoTrabalho()"
								>
								</app-linha-do-tempo>
							</div>
							<!-- Fim -->
						</div>

						
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
