import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { IMenuItemModel } from 'src/app/models/menu.model';

@Component({
  selector: 'app-menuitem',
  templateUrl: './menuitem.component.html',
  styleUrls: ['./menuitem.component.scss'],
})
export class MenuItemComponent implements OnInit, AfterViewInit{
  @Input() menuItems: IMenuItemModel[];

  constructor() {  }

  async ngOnInit() {
  }
  async ngAfterViewInit() {
  }
}
