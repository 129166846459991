<div class="card-body">
    <div class="row">
        <div class="col-md-12">
            <div
                class="mat-elevation-z1 scroll"
                style="width: 100%"
            >
                <table
                    mat-table
                    [dataSource]="
                        distribuicaoDataSource
                    "
                    #tabela3="matSort"
                    matSort
                    matSortDisableClear
                    class="w-100"
                    [hidden]="distruibuicaoSemDados"
                >
                    <ng-container
                        matColumnDef="unidadeGestora"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            align="center"
                        >
                            Sigla
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.unidadeGestora
                                    ?.sigla
                            }}
                            <span
                                *ngIf="
                                    element
                                        ?.unidadeGestora
                                        ?.auditoriaObrigatoria &&
                                    element?.peaId ==
                                        null &&
                                    element?.peaId ==
                                        undefined
                                "
                                class="
                                    badge
                                    bg-danger
                                    text-xs
                                "
                                >Obrigatória</span
                            >
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="valorRisco"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            align="center"
                        >
                            Valor de Risco Inerente
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            align="center"
                        >
                            <span
                            >
                                {{
                                    element?.valorRisco
                                }}
                            </span>
                        </td>
                    </ng-container>

                    <div
                        *ngFor="
                            let coluna of colunasAdicionarDistribuicao
                        "
                    >
                        <ng-container
                            [matColumnDef]="coluna"
                        >
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                align="center"
                            >
                                {{ coluna }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                align="center"
                            >
                                {{
                                    obterValorColuna(
                                        element,
                                        coluna
                                    )
                                        | number
                                            : "1.2-2"
                                            : "pt-br"
                                }}
                            </td>
                        </ng-container>
                    </div>

                    <ng-container
                        matColumnDef="valorNumerico"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            align="center"
                        >
                            Valor de Risco
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            {{
                                element?.unidadeGestora
                                    ?.valorNumerico
                            }}
                        </td>
                    </ng-container>

                    

                    <ng-container
                        matColumnDef="tipoAuditoria"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            align="center"
                        >
                            Tipo de Auditoria
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            <div *ngIf="mostrarSelectUnidade">
                                <select   
                                [attr.disabled]="visualizar ? true : null"                             
                                id="selectUnidade"
                                [(ngModel)]="
                                    element.tipoAuditoriaId
                                "
                                [ngModelOptions]="{
                                    standalone: true
                                }"
                                [ngClass]="{
                                    bordaVermelho:
                                        element?.tipoAuditoriaId ===
                                        null
                                }"
                                class="
                                    form-control
                                    form-control-sm
                                "
                                (ngModelChange)="
                                    alterarTipoAuditoria(
                                        element
                                    )
                                "
                            >
                                <option
                                    [ngValue]="null"
                                >
                                    Selecione...
                                </option>
                                <option
                                    *ngFor="
                                        let item of tiposAuditorias
                                    "
                                    [ngValue]="item?.id"
                                >
                                    {{ item.nome }}
                                </option>
                            </select>
                            <!-- <p
                                style="
                                    width: 14em;
                                    word-wrap: break-word;
                                "
                            >
                                {{
                                    element
                                        ?.tipoAuditoria
                                        ?.nome
                                }}
                            </p> -->
                            </div>
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="tipoEnfase"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            align="right"
                        >
                            Tipo de Ênfase
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                        <div *ngIf="mostrarTipoEnfase">
                            <select
                                id="selectUnidadeEnfase"
                                [attr.disabled]="visualizar ? true : null" 
                                [(ngModel)]="
                                    element.tipoEnfaseId
                                "
                                [ngModelOptions]="{
                                    standalone: true
                                }"
                                [ngClass]="{
                                    bordaVermelho:
                                        element?.tipoEnfaseId ===
                                        null
                                }"
                                class="
                                    form-control
                                    form-control-sm
                                "
                                (ngModelChange)="
                                    alterarTipoEnfase(
                                        element
                                    )
                                "
                            >
                                <option
                                    [ngValue]="null"
                                >
                                    Selecione...
                                </option>
                                <option
                                    *ngFor="
                                        let item of tiposEnfase
                                    "
                                    [ngValue]="item?.id"
                                >
                                    {{ item.nome }}
                                </option>
                            </select>
                            <!-- <p
                                style="
                                    width: 14em;
                                    word-wrap: break-word;
                                "
                            >
                                {{
                                    element
                                        ?.tipoEnfase
                                        ?.nome
                                }}
                            </p> -->
                        </div>
                            
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ativo">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                            align="center"
                        >
                            Situação
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                        >
                            <span
                                *ngIf="element?.ativo"
                                class="
                                    badge
                                    bg-success
                                    text-xs
                                "
                                >Ativo</span
                            >
                            <span
                                *ngIf="!element?.ativo"
                                class="
                                    badge
                                    bg-gray-dark
                                    text-xs
                                "
                                >Inativo</span
                            >
                            <i
                                *ngIf="
                                    element?.historico
                                "
                                [matTooltip]="
                                    element?.tooltipHistorico
                                "
                                class="
                                    fa fa-info-circle
                                "
                            ></i>
                        </td>
                    </ng-container>

                    <ng-container
                        matColumnDef="actions"
                        *ngIf="mostrarBotaoAtivarInativar()"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                        >
                            <span
                                
                                >Inativar/Reativar</span
                            >
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            style="width: 10%"
                        >
                            <button
                                type="button"
                                class="
                                    btn
                                    btn-warning
                                    btn-xs
                                "
                                *ngIf="!element?.ativo"
                                placement="bottom"
                                (click)="
                                    reativarDistribuicao(
                                        element
                                    )
                                "
                            >
                                <i
                                    class="
                                        far
                                        fa-play-circle
                                    "
                                ></i>
                                Reativar
                            </button>
                            <button
                                type="button"
                                class="
                                    btn
                                    btn-default
                                    btn-xs
                                "
                                *ngIf="element?.ativo"
                                placement="bottom"
                                (click)="
                                    inativarDistribuicao(
                                        element
                                    )
                                "
                            >
                                <i
                                    class="
                                        far
                                        fa-pause-circle
                                    "
                                ></i>
                                Inativar
                            </button>
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="
                            columnsDistribuicao
                        "
                        class="bg-light"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            columns: columnsDistribuicao
                        "
                    ></tr>

                    <!-- Group header -->
                    <ng-container
                        matColumnDef="groupHeader"
                    >
                        <td
                            colspan="7"
                            mat-cell
                            *matCellDef="let groupBy"
                        >
                            <strong
                                >Ano: {{ groupBy.ano }}
                            </strong>
                        </td>
                    </ng-container>

                    <tr
                        mat-row
                        class="bg-light groupRow"
                        *matRowDef="
                            let row;
                            columns: ['groupHeader'];
                            when: agrupadorCapacidade
                        "
                    ></tr>

                    <!-- Group footer -->
                    <ng-container
                        matColumnDef="groupFooterLabel"
                    >
                        <td
                            colspan="5"
                            mat-cell
                            *matCellDef="let groupBy"
                            class="text-right"
                        >
                            <strong
                                >Capacidade de Trabalho
                                Total no Ano:
                            </strong>
                        </td>
                    </ng-container>
                    <ng-container
                        matColumnDef="groupFooterValue"
                    >
                        <td
                            mat-cell
                            *matCellDef="let groupBy"
                            class="text-right"
                        >
                            <strong
                                >{{
                                    groupBy.capacidadeTotalAno
                                }}
                            </strong>
                        </td>
                    </ng-container>
                    <ng-container
                        matColumnDef="groupFooterActions"
                    >
                        <td
                            mat-cell
                            *matCellDef="let groupBy"
                            class="text-right"
                        ></td>
                    </ng-container>
                    <tr
                        mat-row
                        class="groupRow"
                        *matRowDef="
                            let row;
                            columns: [
                                'groupFooterLabel',
                                'groupFooterValue',
                                'groupFooterActions'
                            ];
                            when: subTotalCapacidade
                        "
                    ></tr>
                </table>
                <h5
                    [hidden]="!distruibuicaoSemDados"
                    class="table-no-data"
                >
                    Não há dados no Painel de
                    Distribuição
                </h5>
            </div>
        </div>
    </div>
</div>
<div class="card-footer" [hidden]="visualizar">
    <button
        type="button"
        class="btn btn-info btn-sm mr-1"
        *ngIf="mostrarBotaoEditarColunas()"
        (click)="editarColunas()"
    >
        <i class="far fa-eye"></i> Exibir/Ocultar Colunas
    </button>

    <button
        type="button"
        class="btn btn-primary btn-sm mr-1"
        *ngIf="mostrarBotaoIncluirNovaUG()"
        (click)="incluirUG()"
    >
        <i class="far fa-file"></i> Incluir Nova UG
    </button>

    
    <!-- <button
        type="button"
        class="btn btn-info btn-sm mr-1"
        *ngIf="mostrarBotaoGrafico()"
        (click)="visualizarGrafico()"
    >
        <i class="far fa-eye"></i> Visualizar Gráfico
    </button> -->

    <button
        type="button"
        class="btn btn-default btn-sm mr-1"
        (click)="cancelar()"
    >
        <i class="fas fa-times"></i> Fechar
    </button>
</div>