import { DebugElement, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { PeaService } from 'src/app/services/pea.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import { IPaaModel } from 'src/app/models/paa.model';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { FiltroModel } from 'src/app/models/filtro.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';


@Component({
  selector: 'app-pea-list',
  templateUrl: './pea-list.component.html',
  styleUrls: ['./pea-list.component.scss'],
})


export class PeaListComponent extends BaseListComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public selectedModelYears: Array<number>;
  public enableSelectYear = false;
  public selectedYear: number;

  public displayedColumns: string[] = ['selecao', 'nome', 'periodo', 'indicador', 'situacao', 'status'];

  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public filtro = {} as FiltroModel;

  public totalItens: number;
  public searchPesquisa: string;
  public pageEvent: any;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private peaService: PeaService,
    private router: Router,
    public authService: AuthService,
  ) {
    super();
    this.buscar();
  }

  ngOnInit(): void {
    this.configurarFiltro();
    this.buscar();
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.Visualizar);
    this.permissoes.gerarPAA = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.GerarPAA);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterPEA, EnumAcaoRecurso.Imprimir);
  }

  public novo() {
    if (!this.permissoes.incluir) {
      this.exibirMensagemErro("Você não possui permissão para incluir um PEAC.");
      return;
    }    
    this.router.navigate(['/pea/novo']);
  }

  public atualizarFiltroStatus(valor) {
    this.filtro = {} as FiltroModel;
    this.searchAtivo = valor;
    this.buscar();
  }
  public buscar() {
    this.filtro.pesquisa = this.searchNome;
    this.peaService
      .obter(this.searchNome, this.searchAtivo, false, this.filtro)
      .then((res) => {
        this.totalItens = res.dados.totalItens;
        this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = defaultDataAcessor;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
        this.configurarFiltro();
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err?.mensagem?.descricao ?? "Ocorreu um erro inesperado. Tente novamente mais tarde.",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
  }

  private configurarFiltro() {
    this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
    this.filtro.ativo = this.searchAtivo;
    this.filtro.pesquisa = this.searchNome;
    this.filtro.direcao = this.sort.direction;
    this.filtro.colunaOrder = this.sort.active;
  }

  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
}

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar();
  }

  public reativar() {
    let id = this.modelsSelected()[0].id;
    if (!this.permissoes.inativarReativar) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Você não tem permissão para reativar PEAC.',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

    this.peaService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'PEAC reativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          showConfirmButton: false,
          text: err.mensagem.descricao,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar() {
    let id = this.modelsSelected()[0].id;
    if (!this.permissoes.inativarReativar) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Você não tem permissão para inativar PEAC.',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

    this.peaService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'PEAC inativado com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: err.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar() {
    let id = this.modelsSelected()[0].id;
    if (!this.permissoes?.editar) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Você não tem permissão para editar PEAC.',
      });
      return;
    }
    this.router.navigate([`/pea/${id}`]);
  }

  public modelsSelected(): Array<any>{
    let retorno = this.dataSource.data.filter(x => x.selecionado === true);
    this.enableSelectYear = retorno.length == 1;
    return retorno;
  }

  public selecionar(item: any) {    
    item.selecionado = !item.selecionado;

    this.consultarAnos();
  }

  public excluir() {
    let id = this.modelsSelected()[0].id;
    if (!this.permissoes?.excluir) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Você não tem permissão para excluir PEAC.',
      });
      return;
    }

    this.peaService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'PEAC excluído com sucesso.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            title: 'Atenção',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Erro',
          showConfirmButton: false,
          timer: 5000
        });
      })
      .finally(() => {
        this.buscar();
      });
  }

  public consultarAnos() {
    
    if (
      this.modelsSelected().length == 1 &&
      this.modelsSelected()[0] &&
      this.modelsSelected()[0].situacao &&
      this.modelsSelected()[0].situacao.descricao === 'Homologado'
    ) {
      this.peaService
        .obterAnosPaa(this.modelsSelected()[0].id)
        .then((res) => {
          if (res.sucesso) {
            this.selectedModelYears = res.dados;
            this.enableSelectYear = true;
          } else {
            this.enableSelectYear = false;
          }

        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            showConfirmButton: false,
            text: err.mensagem.descricao,
            timer: 5000,
            timerProgressBar: true,
          });
          this.enableSelectYear = false;
        })
    }else{
      this.enableSelectYear = false;
    }
  }

  public relatorioSimplificado() {
    let id = this.modelsSelected()[0].id;
    this.router.navigate([`/pea/${id}/relatorio-simplificado`]);
  }

  public relatorioConsolidado() {
    const ids = this.modelsSelected().map(pea => pea.id);
    this.router.navigate([`/pea/${ids}/relatorio-consolidado`]);
  }

  public enviarParaAprovacao(id: number) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'warning',
      text: 'Recurso não disponível',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  }

  public itemAgrupador(index: number, item: any): boolean {
    return item.grupo;
  }

  public botaoImpessaoIndividualHidden(): boolean {
    return !this.permissoes.imprimir || this.modelsSelected().length != 1;
  }

  public botaoImpressaoColetivaHidden(): boolean {
    return !this.permissoes.imprimir || this.modelsSelected().length <= 1;
  }

  
  public botaoEditarHidden(): boolean {
    return !this.permissoes.editar
            || this.modelsSelected().length != 1 
            || this.modelsSelected()[0].situacao.nome === 'EmAprovacao'
            || this.modelsSelected()[0].situacao.nome === 'Homologado'
            || !this.modelsSelected()[0].ativo;
  }

  public botaoReativarHidden() : boolean {
    return  !this.permissoes.inativarReativar
            || this.modelsSelected().length != 1 
            || this.modelsSelected()[0].ativo
            || this.modelsSelected()[0].situacao.nome === 'EmAnalise'
            || this.modelsSelected()[0].situacao.nome === 'Homologado';
  }

  public botaoInativarHidden() : boolean {
    return  !this.permissoes.inativarReativar
            || this.modelsSelected().length != 1 
            || this.modelsSelected()[0].situacao.nome === 'EmAnalise'
            || this.modelsSelected()[0].situacao.nome === 'EmAprovacao'
            || this.modelsSelected()[0].situacao.nome === 'Homologado'
            || !this.modelsSelected()[0].ativo;
  }

  public botaoExcluirHidden() : boolean {
    return  !this.permissoes.excluir
            || this.modelsSelected().length != 1  
            || this.modelsSelected()[0].situacao.nome === 'Homologado'
            || this.modelsSelected()[0].situacao.nome === 'EmAprovacao';
  }

  public botaoVisualizarHidden() : boolean {
    return  !this.permissoes.visualizar
            || this.modelsSelected().length != 1;
  }

  public botaoGerarPAAHidden() : boolean {
    return  !this.permissoes.gerarPAA
            || this.modelsSelected().length != 1   
            || !this.enableSelectYear 
            || !(this.modelsSelected()[0].situacao.nome === 'Homologado');
  }

  public selectAnoHidden() : boolean {
    return  !this.permissoes.gerarPAA
            || this.modelsSelected().length != 1   
            || !this.enableSelectYear 
            || !(this.modelsSelected()[0].situacao.nome === 'Homologado');
  }

  public async gerarPaa() {
    let id = this.modelsSelected()[0].id;
    if (this.permissoes.gerarPAA) {
      if (this.selectedYear) {
        const paa: IPaaModel = {} as IPaaModel;
        paa.ativo = true;
        paa.ano = this.selectedYear;
        paa.peaId = id;

        let res = await this.peaService.gerarPaa(id, paa);
        
        
        if(res.sucesso){
          this.exibirMensagemSucesso(res.mensagem.descricao);
          this.consultarAnos();
        }else{
          this.exibirMensagemErro(res.mensagem.descricao);
        }

      } else {
        this.exibirMensagemErro('Selecione um ano válido');
      }
    } else {
      this.exibirMensagemErro('Usuário não tem permissão para gerar PAAC.');
      return;
    }
  }
  
  public visualizar() {
    let id = this.modelsSelected()[0].id;
    if (!this.permissoes?.visualizar) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Você não tem permissão para editar PEAC.',
      });
      return;
    }
    this.router.navigate([`/pea/${id}/visualizar`]);
  }
}
