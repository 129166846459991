import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as cloneDeep from 'lodash/cloneDeep';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { IFluxoAprovacaoModel } from 'src/app/models/fluxo-aprovacao.model';
import { INivelAprovacaoModel } from 'src/app/models/nivel-aprovacao.model';
import { FluxoAprovacaoService } from 'src/app/services/fluxo-aprovacao.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { AcessoCidadaoService } from 'src/app/services/acesso-cidadao.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { IPerfilAcessoCidadaoModel } from 'src/app/models/perfil-acessocidadao.model';

@Component({
  selector: 'app-fluxo-aprovacao-form',
  templateUrl: './fluxo-aprovacao-form.component.html',
  styleUrls: ['./fluxo-aprovacao-form.component.scss']
})
export class FluxoAprovacaoFormComponent extends BaseFormComponent implements OnInit {
  public model: IFluxoAprovacaoModel = {} as IFluxoAprovacaoModel;
  public dataSource = new MatTableDataSource<INivelAprovacaoModel>([]);
  public novoNivel = {} as INivelAprovacaoModel;
  public tabelaPerfilCargo: IPerfilAcessoCidadaoModel[];
  public tabelaPerfilCargoFiltro: IPerfilAcessoCidadaoModel[];
  public origens = [{ codigo: 1, descricao: 'Planejada' }, { codigo: 2, descricao: 'Especial' }]
  public tiposTrabalho = [{ codigo: 1, descricao: 'PEAC' }, { codigo: 2, descricao: 'PAAC' }, { codigo: 3, descricao: 'OPA' }, { codigo: 4, descricao: 'OSA' }, { codigo: 5, descricao: 'Matriz de Constatações' }, { codigo: 6, descricao: 'Relatório de Auditoria' }, { codigo: 7, descricao: 'Planejamento' }, { codigo: 8, descricao: 'PAAC - Especial' }]
  public semDados = true;
  public ocultarOrigem: boolean;

  public displayedColumns: string[] = [
    'selecao',
    'nome',
    'membros',
  ];

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    tipoTrabalho: new FormControl({ value: [] as IEnumModel[], disabled: true }),
    origem: new FormControl({ value: [] as IEnumModel[], disabled: true }),
    perfilCargo: new FormControl({ value: [''], disabled: true }),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private fluxoService: FluxoAprovacaoService,
    private tabelaDominioService: TabelaDominioService,
    private acessoCidadaoService: AcessoCidadaoService,
    public matDialog: MatDialog,
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Fluxo de Aprovação';
      this.form.controls['tipoTrabalho'].enable();
      this.form.controls['origem'].enable();
      this.form.controls['perfilCargo'].enable();
    } else {
      this.titulo = 'Editar Fluxo de Aprovação #' + this.id;
      this.form.controls['tipoTrabalho'].enable();
      this.form.controls['origem'].enable();
      this.form.controls['perfilCargo'].enable();
    }
    if (this.visualizar) {
      this.titulo = 'Visualizar Fluxo de Aprovação #' + this.id;
      this.form.controls['tipoTrabalho'].disable();
      this.form.controls['origem'].disable();
      this.form.controls['perfilCargo'].disable();
    }
  }

  async ngOnInit() {
    this.origemHidden();
    try {
      if (!this.novoRegistro) {
        const res = await this.fluxoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          this.form.controls['id'].setValue(res.dados.id);
          this.form.controls['tipoTrabalho'].setValue(res.dados.tipoDocumento.codigo);
          this.form.controls['origem'].setValue(res.dados.origem != undefined ? res.dados.origem.codigo : 0);
          this.model.origemId = res.dados.origemId != undefined ? res.dados.origemId : 0;
          this.model.origem = res.dados.origem != undefined ? res.dados.origem : null;
          this.model.tipoDocumentoId = res.dados.tipoDocumentoId;
          this.model.tipoDocumento = res.dados.tipoDocumento;
          this.model.nivelAprovacao = this.model.nivelAprovacao.sort((a, b) => (a.ordem > b.ordem) ? 1 : -1);
          this.dataSource = new MatTableDataSource<INivelAprovacaoModel>(res.dados.nivelAprovacao);
          this.semDados = this.dataSource.filteredData.length === 0;
        } else {
          this.toastr.error(res.mensagem.descricao, 'Atenção');
          this.router.navigate(['/fluxos-aprovacao']);
          return;
        }

      }
      await this.acessoCidadaoService.ObterPerfisAcessoCidadao()
      .then((resPerfil) => this.tabelaPerfilCargo = resPerfil.dados);
      
      this.filtrarTabelaPerfilCargo();
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
      this.router.navigate(['/fluxos-aprovacao']);
    }
  }

  public async salvarDados(fechar: boolean) {
    if (this.form.invalid) {
      this.toastr.warning('Formulário inválido!', 'Atenção');
      return;
    }

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<IFluxoAprovacaoModel> = null;

      if (!this.novoRegistro) {
        this.model.origem = null;
        this.model.origemId = this.form.controls['origem'].value;
        res = await this.fluxoService.atualizar(this.model);
      } else {
        this.model.id = null;
        this.model.nome = '';
        this.model.dataAlteracao = null;
        this.model.nivelAprovacao = this.dataSource.data;
        this.model.ativo = true;
        this.model.origemId = this.form.controls['origem'].value != undefined && this.form.controls['origem'].value.length != 0 ? this.form.controls['origem'].value : 0;
        this.model.origem = null;
        this.model.tipoDocumentoId = this.form.controls['tipoTrabalho'].value;
        this.model.tipoDocumento = null;
        res = await this.fluxoService.inserir(this.model);
      }

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);
        if(fechar){
          this.router.navigate(['/fluxos-aprovacao']);
        }else{
          this.router.navigate([`/fluxos-aprovacao/${res.dados.id}`]);
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);

        this.toastr.warning(validacoes, res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  private filtrarTabelaPerfilCargo() {
    this.tabelaPerfilCargoFiltro = this.tabelaPerfilCargo.filter(t => this.dataSource.data.findIndex(d => d.codigoPerfil === t.guid) < 0);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/fluxos-aprovacao']);
  }

  public excluirNivel(ind: number) {
    this.dataSource.data.splice(ind, 1);

    for (let i = ind; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].ordem = i + 1;
    }

    this.dataSource._updateChangeSubscription();
    this.semDados = this.dataSource.filteredData.length === 0;

    this.filtrarTabelaPerfilCargo();
  }

  public incluirNivel() {
    this.novoNivel.ordem = this.dataSource.data.length + 1;
    const perfilSelecionado = this.tabelaPerfilCargo.find(t => t.guid === this.novoNivel.codigoPerfil);
    this.novoNivel.codigoPerfil = perfilSelecionado.guid;
    this.novoNivel.nomePerfil = perfilSelecionado.nome;
    this.novoNivel.fluxoAprovacaoId = this.id;
    this.dataSource.data.push(cloneDeep(this.novoNivel));
    this.dataSource._updateChangeSubscription();
    this.novoNivel = {} as INivelAprovacaoModel;
    this.semDados = false;

    this.filtrarTabelaPerfilCargo();
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].ordem = i + 1;
    }

    this.dataSource._updateChangeSubscription();
  }

  public origemHidden(): boolean {
    if (this.model.nome == 'OPA' || this.model.nome == 'OSA') {
      return false;
    }
    return true;
  }

  public exibirOrigem(): boolean {
    if (this.visualizar) {
      this.form.controls['origem'].setValue(this.model.origemId);
    }
    if (this.form.controls['tipoTrabalho'].value == 3 || this.form.controls['tipoTrabalho'].value == 4) {
      return false;
    }
    return true;
  }
}
