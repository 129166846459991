<div id="modal-content-wrapper">
	<div class="card card-secondary card-outline">
		<div class="card-header bg-light">
			<h4 class="card-title mb-0">{{ tituloModal }}</h4>
			<div class="card-tools">
				<button type="button" class="btn btn-tool" (click)="cancelar()">
					<i class="fas fa-times"></i>
				</button>
			</div>
		</div>
		<div class="card-body p-0 wrapper">
			<div class="table-container p-3">
				<div class="row">
					<div class="form-group col-md-12">
						<div>
							<section class="content-header">
								<div class="container-fluid">
									<div class="row mb-2">
										<div class="col-sm-6">
                                            <h5 [hidden]="!semDados" class="table-no-data">
                                                Sem dados a exibir
                                            </h5>
										</div>
									</div>
								</div>
							</section>
							
								<div
									class="timeline m-0"
									
								>
									<div *ngFor="let element of historicoDados">
										<i class="fas fa-user bg-info"></i>
										<div class="timeline-item">
											<span class="time"
												><i class="fas fa-clock"></i>
												{{element.dataInclusao | date: 'dd MMM yyyy HH:mm'}}</span
											>

											<h3 class="timeline-header">
												<a> Alterado para: {{ element.unidadeGestoraMaturidadeGR.nome }}</a>
											</h3>
                                            <div class="timeline-body">Usuário: {{ element.pessoaNome }}
                                            </div>
										</div>
									</div>
								</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<button
				type="button"
				class="btn btn-default btn-sm"
				(click)="cancelar()"
			>
				<i class="fas fa-times"></i> Cancelar
			</button>
		</div>
	</div>
</div>
