import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatDialog,	MatDialogConfig } from "@angular/material/dialog";
import { Location } from '@angular/common';


//COMPONENTS
import { ModalCapacidadePaaComponent } from "./modal-capacidade/modal-capacidade-paa.component";

//MODELS
import { IPaaInformacoesBasicasModel } from "src/app/models/paa-informacoes-basicas.model";
import { IPaaAbaCapacidadeModel } from "src/app/models/paa-aba-capacidade.model";
import { IPaaAbaCapacidadeCoordenacao } from "src/app/models/paa-aba-capacidade.model";

//SERVICES
import { PaaAbaCapacidadeService } from "src/app/services/paa/paa-aba-capacidade.service";
import { CoordenacaoService } from "src/app/services/coordenacao.service";
import { TipoDispensaService } from "src/app/services/tipo-dispensa.service";
import { PaaService } from "src/app/services/paa.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";



@Component({
	selector: "app-paa-aba-capacidade",
	templateUrl: "./paa-aba-capacidade.component.html",
	styleUrls: ["./paa-aba-capacidade.component.scss"],
})
export class PaaAbaCapacidadeComponent extends BaseFormComponent implements OnInit{
    public model = {} as IPaaAbaCapacidadeModel;

	public mostrarCapacidadeTotal: number;
	public mostrarCapacidadeTotalPea: number;
	public paaInformacoesBasicas: IPaaInformacoesBasicasModel = {} as IPaaInformacoesBasicasModel;
	public capacidadeDataSource = new MatTableDataSource<IPaaAbaCapacidadeCoordenacao>([]);
	public capacidadeSemDados = true;
	@ViewChild('tabela4') public tabela4: MatSort;
	public paaHomologado = true;

  
	
	constructor(route: ActivatedRoute,
				toastr: ToastrService,
				router: Router,
				localeService: BsLocaleService,
				private _location: Location,
				private paaAbaCapacidadeService: PaaAbaCapacidadeService,
				private coordenacaoService: CoordenacaoService,
				private tipoDispensaService: TipoDispensaService,
				public matDialog: MatDialog,
				private paaService: PaaService
	){super(route, toastr, router, localeService, matDialog);}

	public columnsCapacidade = [
		'siglaCoordenacao',
		'capacidadePaa',   
		'capacidadeTotalPea',   
		'nomesAuditores', 
		'actions',
	  ];

	  async ngOnInit() {
		await this.buscarInformacoesBasicas();
		await this.buscar();
		
		//TODO: VERIFICAR PQ A VARIÁVEL VISUALIZAR ESTÁ SENDO RESETADA
		this.visualizar = this.route.routeConfig.path?.includes("visualizar");
	  }

	  async buscar(){
			await this.paaAbaCapacidadeService.obter(this.id)
					.then((res) => {
						if(res.sucesso){
							this.model = res.dados;
							this.mostrarCapacidadeTotal = this.model.capacidadeTotalPaa;
							this.mostrarCapacidadeTotalPea = this.model.capacidadeTotalPea;
							this.capacidadeDataSource = new MatTableDataSource<IPaaAbaCapacidadeCoordenacao>(res.dados.coordenacoes);
							this.capacidadeSemDados = this.capacidadeDataSource.data.length ==0;
						}else{
							this.exibirMensagemErro(res.mensagem.descricao);
						}
					})
					
	  }

	  //METHODS
	  public async visualizarCoordenacao(capacidade: IPaaAbaCapacidadeCoordenacao) {
			let dados =(await this.paaAbaCapacidadeService.obterDetalheCoordenacao(this.id, capacidade.idCoordenacao, capacidade.ano)).dados;		
			
			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = 'modal-paa-component';
			dialogConfig.width = '1080px';
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = {
				dados
			};
			const modal = this.matDialog.open(ModalCapacidadePaaComponent, dialogConfig);
	  }
	
	
	  public agrupadorCapacidade(index: number, item: any): boolean {
		return item.grupo;
	  }
	
	  public subTotalCapacidade(index: number, item: any): boolean {
		return item.subTotal;
	  }

	  public async buscarInformacoesBasicas(){

		await this.paaService.buscarInformacoesBasicas(this.id)
		  .then((res)=> {
			if(res.sucesso)
			  this.paaInformacoesBasicas = res.dados;
		  })
	  }

	  
}