<div class="" cdkDropList (cdkDropListDropped)="onDrop($event)">
	<div class="d-flex align-items-center">
		<button
			type="button"
			class="btn btn-primary btn-sm mr-1"
			(click)="habilitarEdicaoCapituloRelatoria(null, null, null, true)"
			*ngIf="mostrarBotaoEditar()"
		>
			<i class="far fas fa-pen"></i>
			Editar
		</button>
		<button
			type="button"
			class="btn btn-danger btn-sm mr-1"
			*ngIf="mostrarBotaoExcluir()"
			(click)="excluirRelatoria()"
		>
			<i class="far fas fa-trash"></i>
			Excluir
		</button>
		<button
			type="button"
			class="btn btn-info btn-sm mr-1"
			(click)="imprimir()"
			*ngIf="!impressao"
		>
			<i class="far fas fa-print"></i>
			Imprimir
		</button>
	</div>

	<div
		*ngFor="let itemRelatoria of relatoriaDados; let i = index"
		id="imprimir-relatoria"
	>
		<div
			class="mt-4 rounded-cp capitulo"
			cdkDrag
			cdkDragLockAxis="y"
			[cdkDragDisabled]="
				itemRelatoria?.active || habilitarEdicaoRelatoria.activeAll
			"
		>
			<div class="text-black px-4 pt-4 pb-4 rounded-cp">
				<div
					class="title w-100 d-flex justify-content-between align-items-center"
				>
					<div class="d-flex drag-handle">
						<h4
							class="d-flex"
							[ngClass]="{
								excluido:
									itemRelatoria.status.codigo === 4 &&
									model?.situacao?.codigo !== 1
							}"
						>
							<strong> {{ itemRelatoria.numero }}.&nbsp; </strong>
							<p *ngIf="!itemRelatoria?.active">
								{{ itemRelatoria.nome }}
							</p>
							<input
								*ngIf="itemRelatoria?.active"
								[(ngModel)]="itemRelatoria.nome"
								[disabled]="itemRelatoria.capituloFixo"
								[ngModelOptions]="{ standalone: true }"
							/>
						</h4>
						<i
							[hidden]="!itemRelatoria?.active"
							class="far fas fa-pen ml-2 btn-sm"
						></i>
					</div>
					<div class="d-flex align-items-center">
						<div
							class="d-flex align-items-center mr-2"
							*ngIf="
								itemRelatoria.status.codigo === 3 ||
								itemRelatoria.status.codigo === 4
							"
						>
							<button
								type="button"
								data-toggle="dropdown"
								class="btn btn-tool btn-green bg-transparent border-0 mb-2-"
								(click)="
									avaliarExclusao(itemRelatoria.id, true)
								"
								*ngIf="
									itemRelatoria.status.codigo !== 4 &&
									model?.situacao?.codigo === 1
								"
							>
								<i class="fas fa-check text-success"></i>
							</button>
							<button
								type="button"
								data-toggle="dropdown"
								class="btn btn-tool btn-danger bg-transparent border-0 mb-2- mr-2"
								(click)="
									avaliarExclusao(itemRelatoria.id, false)
								"
								*ngIf="!homologado"
							>
								<i class="fas fa-times text-danger"></i>
							</button>
							<p
								class="border-0 mb-0 text-danger"
								*ngIf="
									itemRelatoria.status.codigo === 3 &&
									model?.situacao?.codigo === 1
								"
							>
								Excluído (aguardando aceite)
							</p>
							<p
								class="border-0 mb-0 text-danger"
								*ngIf="
									(itemRelatoria.status.codigo === 3 &&
										model?.situacao?.codigo !== 1) ||
									itemRelatoria.status.codigo === 4
								"
							>
								Excluído
							</p>
						</div>

						<div
							class="btn-group"
							[hidden]="homologado || somenteLeitura"
						>
							<button
								type="button"
								data-toggle="dropdown"
								class="btn btn-tool btn-default bg-transparent border-0 mb-2-"
							>
								<i class="fas fa-ellipsis-h"></i>
							</button>
							<div
								role="menu"
								class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox"
							>
								<a
									class="dropdown-item"
									(click)="
										habilitarEdicaoCapituloRelatoria(
											i,
											true,
											null,
											null,
											true
										)
									"
									[hidden]="
										habilitarEdicaoRelatoria.activeAll ||
										itemRelatoria.status.codigo === 3 ||
										itemRelatoria.status.codigo === 4
									"
									>Editar capítulo</a
								>
								<a
									class="dropdown-item"
									(click)="
										excluirCapitulo(
											itemRelatoria.id,
											itemRelatoria.capituloFixo,
											true
										)
									"
									[hidden]="
										itemRelatoria?.status?.codigo === 3 ||
										itemRelatoria?.status?.codigo === 4
									"
									>Excluir capítulo</a
								>
								<a
									class="dropdown-item"
									(click)="reativarCapitulo(itemRelatoria.id)"
									[hidden]="
										itemRelatoria.status.codigo !== 3 &&
										itemRelatoria.status.codigo !== 4
									"
									>Reativar</a
								>
								<a
									class="dropdown-item"
									(click)="
										modalRelatoriaCapitulo(
											itemRelatoria,
											false
										)
									"
									[hidden]="
										itemRelatoria.status.codigo === 3 ||
										itemRelatoria.status.codigo === 4
									"
									>Incluir capítulo</a
								>
								<a
									class="dropdown-item"
									(click)="
										modalRelatoriaCapitulo(
											itemRelatoria,
											true
										)
									"
									[hidden]="
										itemRelatoria.status.codigo === 3 ||
										itemRelatoria.status.codigo === 4
									"
									>Incluir subcapitulos</a
								>
								<a
									class="dropdown-item"
									(click)="
										modalHistoricoRelatoria(itemRelatoria)
									"
									>Histórico</a
								>
							</div>
						</div>
					</div>
				</div>
				<div class="subtitle mt-2">
					<div
						[hidden]="
							itemRelatoria.status.codigo !== 3 &&
							itemRelatoria.status.codigo !== 4 &&
							(itemRelatoria?.active ||
								habilitarEdicaoRelatoria?.activeAll)
						"
					>
						<div
							[innerHtml]="itemRelatoria.conteudo | sanitizedHtml"
							[ngClass]="{
								excluido:
									itemRelatoria.status.codigo === 4 &&
									model?.situacao?.codigo !== 1
							}"
						></div>
					</div>

					<div
						class="bg-white"
						[hidden]="
							itemRelatoria.status.codigo === 3 ||
							itemRelatoria.status.codigo === 4 ||
							(!itemRelatoria?.active &&
								!habilitarEdicaoRelatoria.activeAll)
						"
					>
						<div class="modal-edit">
							<angular-editor
								[config]="config"
								[id]="i"
								rows="20"
								[(ngModel)]="itemRelatoria.conteudo"
								[ngModelOptions]="{ standalone: true }"
							></angular-editor>
							<div>
								<mat-form-field
									class="tags-chip-list col-md-12"
									appearance="fill"
								>
									<label for="chipList" class="mb-1"
										>Tags</label
									>
									<input matInput [hidden]="true" />
									<mat-chip-list
										*ngIf="tipoTrabalho == 1"
										#chipList
										aria-label="Tags"
										multiple
									>
										<mat-chip
											*ngFor="let keyword of keywords"
											color="primary"
											[selected]="keyword"
											[value]="keyword"
											(click)="addTag(keyword, i)"
										>
											{{ keyword }}
										</mat-chip>
									</mat-chip-list>
									<mat-chip-list
										*ngIf="tipoTrabalho == 2"
										#chipList
										aria-label="Tags"
										multiple
									>
										<mat-chip
											*ngFor="let keyword of keywordsPaa"
											color="primary"
											[selected]="keyword"
											[value]="keyword"
											(click)="addTag(keyword, i)"
										>
											{{ keyword }}
										</mat-chip>
									</mat-chip-list>
									<mat-chip-list
										*ngIf="tipoTrabalho == 8"
										#chipList
										aria-label="Tags"
										multiple
									>
										<mat-chip
											*ngFor="
												let keyword of keywordsPaaEspecial
											"
											color="primary"
											[selected]="keyword"
											[value]="keyword"
											(click)="addTag(keyword, i)"
										>
											{{ keyword }}
										</mat-chip>
									</mat-chip-list>
									<mat-chip-list
										*ngIf="tipoTrabalho == 3"
										#chipList
										aria-label="Tags"
										multiple
									>
										<mat-chip
											*ngFor="let keyword of keywordsOpa"
											color="primary"
											[selected]="keyword"
											[value]="keyword"
											(click)="addTag(keyword, i)"
										>
											{{ keyword }}
										</mat-chip>
									</mat-chip-list>
									<mat-chip-list
										*ngIf="tipoTrabalho == 7"
										#chipList
										aria-label="Tags"
										multiple
									>
										<mat-chip
											*ngFor="
												let keyword of keywordsPlanejamento
											"
											color="primary"
											[selected]="keyword"
											[value]="keyword"
											(click)="addTag(keyword, i)"
										>
											{{ keyword }}
										</mat-chip>
									</mat-chip-list>
									<mat-chip-list
										*ngIf="tipoTrabalho == 4"
										#chipList
										aria-label="Tags"
										multiple
									>
										<mat-chip
											*ngFor="let keyword of keywordsOsa"
											color="primary"
											[selected]="keyword"
											[value]="keyword"
											(click)="addTag(keyword, i)"
										>
											{{ keyword }}
										</mat-chip>
									</mat-chip-list>
								</mat-form-field>
							</div>
						</div>
						<div [hidden]="habilitarEdicaoRelatoria.activeAll">
							<div class="d-flex flex-row-reverse w-100 pt-4">
								<button
									type="button"
									class="btn btn-primary btn-sm mr-1"
									(click)="salvarOne(itemRelatoria, i)"
								>
									<i class="far fas fa-save"></i>
									Salvar
								</button>
								<button
									type="button"
									class="btn btn-default btn-sm"
									(click)="
										habilitarEdicaoCapituloRelatoria(
											i,
											false,
											null,
											null,
											false
										)
									"
								>
									<i class="far fas fa-times"></i>
									Cancelar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			cdkDropList
			(cdkDropListDropped)="onSubDrop(itemRelatoria.id, $event)"
		>
			<div
				class="mt-2 ml-4 rounded-cp capitulo"
				*ngFor="
					let subcapitulo of itemRelatoria.subRelatoriaCapitulos;
					let isubcapitulo = index
				"
			>
				<div
					class="text-black px-4 pt-1 pb-4 rounded-cp"
					cdkDrag
					[cdkDragDisabled]="
						subcapitulo?.active ||
						habilitarEdicaoRelatoria?.activeAll
					"
					cdkDragLockAxis="y"
				>
					<div
						class="title w-100 d-flex justify-content-between align-items-center"
					>
						<div class="d-flex drag-handle">
							<h4
								class="d-flex"
								[ngClass]="{
									excluido:
										subcapitulo.status.codigo === 4 &&
										model?.situacao?.codigo !== 1
								}"
							>
								<strong>
									{{ itemRelatoria.numero }}.{{
										subcapitulo.numero
									}}.&nbsp;
								</strong>
								<p *ngIf="!subcapitulo?.active">
									{{ subcapitulo.nome }}
								</p>
								<input
									*ngIf="subcapitulo?.active"
									[(ngModel)]="subcapitulo.nome"
									[disabled]="subcapitulo.capituloFixo"
									[ngModelOptions]="{ standalone: true }"
								/>
							</h4>
							<i
								[hidden]="!subcapitulo?.active"
								class="far fas fa-pen ml-2 btn-sm"
							></i>
						</div>

						<div class="d-flex align-items-center">
							<div class="d-flex align-items-center mr-2">
								<button
									type="button"
									data-toggle="dropdown"
									class="btn btn-tool btn-green bg-transparent border-0 mb-2-"
									(click)="
										avaliarExclusao(subcapitulo.id, true)
									"
									*ngIf="
										subcapitulo.status.codigo === 3 &&
										model?.situacao?.codigo === 1
									"
								>
									<i class="fas fa-check text-success"></i>
								</button>
								<button
									type="button"
									data-toggle="dropdown"
									class="btn btn-tool btn-danger bg-transparent border-0 mb-2- mr-2"
									(click)="
										avaliarExclusao(subcapitulo.id, false)
									"
									*ngIf="
										subcapitulo.status.codigo === 3 &&
										model?.situacao?.codigo === 1
									"
								>
									<i class="fas fa-times text-danger"></i>
								</button>
								<p
									class="border-0 mb-0 text-danger"
									*ngIf="
										subcapitulo.status.codigo === 3 &&
										model?.situacao?.codigo === 1
									"
								>
									Excluído (aguardando aceite)
								</p>
								<p
									class="border-0 mb-0 text-danger"
									*ngIf="
										subcapitulo.status.codigo === 4 ||
										(subcapitulo.status.codigo === 3 &&
											model?.situacao?.codigo !== 1)
									"
								>
									Excluído
								</p>
							</div>
							<div class="btn-group" [hidden]="somenteLeitura">
								<button
									type="button"
									data-toggle="dropdown"
									class="btn btn-tool btn-default bg-transparent border-0 mb-2-"
								>
									<i class="fas fa-ellipsis-h"></i>
								</button>
								<div
									role="menu"
									class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox"
								>
									<a
										class="dropdown-item"
										(click)="
											habilitarEdicaoCapituloRelatoria(
												i,
												true,
												isubcapitulo
											)
										"
										[hidden]="
											habilitarEdicaoRelatoria.activeAll
										"
										[hidden]="
											subcapitulo.status.codigo === 3 ||
											subcapitulo.status.codigo === 4
										"
										>Editar</a
									>
									<a
										class="dropdown-item"
										(click)="
											excluirCapitulo(
												subcapitulo.id,
												subcapitulo.capituloFixo,
												false
											)
										"
										[hidden]="
											subcapitulo.status.codigo === 3 ||
											subcapitulo.status.codigo === 4
										"
										>Excluir</a
									>
									<a
										class="dropdown-item"
										(click)="
											reativarCapitulo(subcapitulo.id)
										"
										[hidden]="
											subcapitulo.status.codigo !== 3 &&
											subcapitulo.status.codigo !== 4
										"
										>Reativar</a
									>
									<a
										class="dropdown-item"
										(click)="
											modalRelatoriaCapitulo(
												subcapitulo,
												true
											)
										"
										[hidden]="
											itemRelatoria.status.codigo === 3 ||
											itemRelatoria.status.codigo === 4
										"
										>Incluir subcapitulos</a
									>
									<a
										class="dropdown-item"
										(click)="
											modalHistoricoRelatoria(subcapitulo)
										"
										>Histórico</a
									>
								</div>
							</div>
						</div>
					</div>
					<div class="subtitle mt-1">
						<div
							[hidden]="
								subcapitulo.status.codigo !== 3 &&
								subcapitulo.status.codigo !== 4 &&
								(subcapitulo?.active ||
									habilitarEdicaoRelatoria.activeAll)
							"
						>
							<div
								[innerHtml]="
									subcapitulo.conteudo | sanitizedHtml
								"
								[ngClass]="{
									excluido:
										subcapitulo.status.codigo === 4 &&
										model?.situacao?.codigo !== 1
								}"
							></div>
						</div>

						<div class="px-1">
							<div
								class="bg-white"
								[hidden]="
									subcapitulo.status.codigo === 3 ||
									subcapitulo.status.codigo === 4 ||
									(!subcapitulo?.active &&
										!habilitarEdicaoRelatoria.activeAll)
								"
							>
								<angular-editor
									[config]="config"
									[id]="i"
									rows="20"
									[(ngModel)]="subcapitulo.conteudo"
									[ngModelOptions]="{ standalone: true }"
								></angular-editor>

								<div
									[hidden]="
										habilitarEdicaoRelatoria.activeAll
									"
								>
									<div
										class="d-flex flex-row-reverse w-100 pt-4"
									>
										<button
											type="button"
											class="btn btn-primary btn-sm mr-1"
											(click)="
												salvarOne(
													subcapitulo,
													i,
													isubcapitulo
												)
											"
										>
											<i class="far fas fa-save"></i>
											Salvar
										</button>
										<button
											type="button"
											class="btn btn-default btn-sm"
											(click)="
												habilitarEdicaoCapituloRelatoria(
													i,
													false,
													isubcapitulo,
													null,
													false
												)
											"
										>
											<i class="far fas fa-times"></i>
											Cancelar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		class="d-flex align-items-center justify-content-right mt-4"
		[hidden]="somenteLeitura"
	>
		<div class="d-flex justify-content-between">
			<div>
				<button
					type="button"
					class="btn btn-primary btn-sm mr-1"
					(click)="salvarTodos()"
					*ngIf="habilitarEdicaoRelatoria.activeAll"
				>
					<i class="far fas fa-save"></i>
					Salvar relatoria
				</button>
				<button
					type="button"
					class="btn btn-default btn-sm mr-1"
					(click)="habilitarEdicaoRelatoria.activeAll = false"
					*ngIf="habilitarEdicaoRelatoria.activeAll"
				>
					<i class="far fas fa-times"></i>
					Cancelar Edição
				</button>
			</div>
		</div>
	</div>
</div>
