import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { TipoDispensaFormComponent } from 'src/app/pages/tipo-dispensa-form/tipo-dispensa-form.component';

@Injectable()
export class TipoDispensaGuard implements CanDeactivate<TipoDispensaFormComponent> {
  canDeactivate(component: TipoDispensaFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  }
}

import { UnidadeGestoraFormComponent } from 'src/app/pages/unidade-gestora-form/unidade-gestora-form.component';

@Injectable()
export class UnidadeGestoraGuard implements CanDeactivate<UnidadeGestoraFormComponent> {
  canDeactivate(component: UnidadeGestoraFormComponent): Observable<boolean> | boolean {
    if (component.novoRegistro) {
      if (component.submit) { return true; }
      return component.matDialog.open(ModalCancelarComponent).afterClosed();
    } else { return true; }
  } 
}