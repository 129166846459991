import { IPaaEquipeModel } from './../models/paa-equipe.model';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IPaaModel } from '../models/paa.model';
import { IBaseModel } from '../models/base.model';
import { IPaaDistribuicaoModel } from '../models/paa-distribuicao.model';
import { IPaaDistribuicaoColunasModel } from '../models/paa-distribuicao-colunas.model';
import { IPaaGraficoModel } from '../models/paa-grafico.model';
import { IPaaDistribuicaoHistoricoModel } from '../models/paa-distribuicao.historico.model';
import { IEntranharDocumentoModel } from '../models/edocs/entranhar-documento.model';
import { LocationStrategy } from '@angular/common';
import { IPaaCoordenacaoDispensaModel } from '../models/paa-coordenacao-dispensa.model';
import { IPaaDistribuicaoImprimirModel } from '../models/paa-distribuicao-imprimir.model';
import { ImprimirModel } from '../models/imprimir-model';
import { IValidacaoNivelModel } from '../models/validaca-nivel-aprovacao.model';
import { IAlocacaoPendenciasModel } from '../models/paa-alocacao-pendencias.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { filterBy } from '../core/helpers/filterby';
import { IPaaDadosBasicosModel } from '../models/paa-dados-basicos.model';
import { FiltroModel } from '../models/filtro.model';
import { IPaaInformacoesBasicasModel } from '../models/paa-informacoes-basicas.model';
import { IPaaCoordenacaoCapacidadeModel } from '../models/paa-coordenacao-capacidade.model';

@Injectable({
  providedIn: 'root'
})
export class PaaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async atualizarPaa(data: IPaaDadosBasicosModel): Promise<IBaseModel<IPaaDadosBasicosModel>> {
    
    return this.httpClient
      .put<IBaseModel<IPaaDadosBasicosModel>>(`${this.apiBaseUrl}/paa`, data)
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/paa/${id}/excluir`, {})
      .toPromise();
  }



  public obter(    
    pesquisa: string,
    ativo: boolean,
    simplificado = false,
    filtro?: FiltroModel
  ): Promise<IBaseModel<PaginacaoModel<IPaaModel[]>>> {
    
    let params = new HttpParams();
    if (ativo !== undefined) {
      params = params.append('pesquisa', pesquisa || '');

      if (ativo !== undefined) {
        params = params.append('ativo', ativo.toString());
      }

      if (simplificado) {
        params = params.append('simplificado', simplificado.toString());
      }
    }


    if(filtro != null && Object.keys(filtro).length > 0) {
      
      
      return this.httpClient
      .get<IBaseModel<PaginacaoModel<IPaaModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/paa`), { params })
      .toPromise();
    }

    return this.httpClient
      .get<IBaseModel<PaginacaoModel<IPaaModel[]>>>(`${this.apiBaseUrl}/paa`, { params })
      .toPromise();
  }

  
  public async obterPorId(id: number): Promise<IBaseModel<IPaaModel>> {
    
    return this.httpClient
      .get<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/${id}`)
      .toPromise();
  }

  public async atualizar(data: IPaaModel): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/${data.id}`, data)
      .toPromise();
  }

  public async enviarAprovacao(id: number, idPerfilSelecionado: string): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/${id}/enviar-aprovacao/${idPerfilSelecionado}`, null)
      .toPromise();
  }

  public async aprovar(id: number): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/${id}/aprovar`, null)
      .toPromise();
  }

  public async rejeitar(id: number, justificativa: string): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/${id}/rejeitar`, { justificativa })
      .toPromise();
  }

  public async enviarHomologacao(id: number): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/${id}/enviar-homologacao`, null)
      .toPromise();
  }

  public async verificarNivelAprovacao(id: number): Promise<IBaseModel<IValidacaoNivelModel>> {
    return this.httpClient
      .get<IBaseModel<IValidacaoNivelModel>>(`${this.apiBaseUrl}/paa/${id}/verificar-nivel-aprovacao`)
      .toPromise();
  }

  public async homologar(id: number, entranharDocumento: IEntranharDocumentoModel): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .put<IBaseModel<IPaaModel>>(`${this.apiBaseUrl}/paa/${id}/homologar`, entranharDocumento)
      .toPromise();
  }


  public obterPainelDistribuicaoColunas(id: number): Promise<IBaseModel<IPaaDistribuicaoColunasModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPaaDistribuicaoColunasModel[]>>(`${this.apiBaseUrl}/paa/${id}/painel-distribuicao/colunas`)
      .toPromise();
  }

  public async AlterarSatusDistribuicaoColunas(item: IPaaDistribuicaoColunasModel[]): Promise<IBaseModel<IPaaDistribuicaoColunasModel[]>> {
    return this.httpClient
      .post<IBaseModel<IPaaDistribuicaoColunasModel[]>>(`${this.apiBaseUrl}/paa/painel-distribuicao/distribuicao-colunas`, item)
      .toPromise();
  }
  
  public async obterAlocacoesEquipe(id: number): Promise<IBaseModel<IPaaEquipeModel[]>> {
    const params = new HttpParams();

    return this.httpClient
      .get<IBaseModel<IPaaEquipeModel[]>>(`${this.apiBaseUrl}/paa/${id}/equipes`, { params })
      .toPromise();
  }

  public async obterEquipe(id: number): Promise<IBaseModel<IPaaEquipeModel>> {
    const params = new HttpParams();

    return this.httpClient
      .get<IBaseModel<IPaaEquipeModel>>(`${this.apiBaseUrl}/paa/${id}/obter-equipe`, { params })
      .toPromise();
  }

  public async obterEquipesPorPaaId(id: number): Promise<IBaseModel<IPaaEquipeModel[]>> {
    const params = new HttpParams();

    return this.httpClient
      .get<IBaseModel<IPaaEquipeModel[]>>(`${this.apiBaseUrl}/paa/${id}/obter-equipes`, { params })
      .toPromise();
  }

  public async obterGrafico(id: number): Promise<IBaseModel<IPaaGraficoModel[]>> {
    const params = new HttpParams();

    return this.httpClient
      .get<IBaseModel<IPaaGraficoModel[]>>(`${this.apiBaseUrl}/paa/${id}/obter-grafico-equipe`, { params })
      .toPromise();
  }

  public async validarAlocacaoEquipe(data: IPaaEquipeModel): Promise<IBaseModel<IAlocacaoPendenciasModel>> {
    return this.httpClient
      .post<IBaseModel<IAlocacaoPendenciasModel>>(`${this.apiBaseUrl}/paa/validar-alocacao-equipe`, data)
      .toPromise();
  }

  public async atualizarAlocacaoEquipe(data: IPaaEquipeModel): Promise<IBaseModel<IPaaEquipeModel>> {
    return this.httpClient
      .put<IBaseModel<IPaaEquipeModel>>(`${this.apiBaseUrl}/paa/atualizar-alocacao-equipe`, data)
      .toPromise();
  }

    public obterDispensasPeriodo(dataInicio: string, dataFim: string): Promise<IBaseModel<IPaaCoordenacaoDispensaModel[]>> {
    let params = new HttpParams();
    params = params.append('dataInicio', dataInicio);
    params = params.append('dataFim', dataFim);
    return this.httpClient
      .get<IBaseModel<IPaaCoordenacaoDispensaModel[]>>(`${this.apiBaseUrl}/paa/dispensas-periodo/`, { params })
      .toPromise();
  }

  public async imprimir(id: number): Promise<IBaseModel<ImprimirModel>> {
    return this.httpClient
      .get<IBaseModel<ImprimirModel>>(`${this.apiBaseUrl}/paa/${id}/imprimir`, {})
      .toPromise();
  }

  public async VerificaDataAlocacao(dataReferencia: string, isDataInicial: boolean, idTipoAuditoria:number): Promise<IBaseModel<Date>> {
    let params = new HttpParams();
    params = params.append('dataReferencia', dataReferencia);
    params = params.append('isDataInicial', isDataInicial.toString());
    params = params.append('idTipoAuditoria', idTipoAuditoria.toString());

    return this.httpClient
    .get<IBaseModel<Date>>(`${this.apiBaseUrl}/paa/validaDataAlocacao`, {params})
    .toPromise();
  }

  
  public async adicionarDistribuicao(item: IPaaDistribuicaoModel): Promise<IBaseModel<IPaaDistribuicaoModel>> {
    return this.httpClient
      .post<IBaseModel<IPaaDistribuicaoModel>>(`${this.apiBaseUrl}/paa/painel-distribuicao/adicionar`, item)
      .toPromise();
  }

  public async buscarInformacoesBasicas(id: number): Promise<IBaseModel<IPaaInformacoesBasicasModel>>{
    return this.httpClient
          .get<IBaseModel<IPaaInformacoesBasicasModel>>(`${this.apiBaseUrl}/paa/${id}/obterInformacoesBasicas`)
          .toPromise();
  }

  public async atualizarCapacidadeTrabalho(id: number, item: IPaaCoordenacaoCapacidadeModel): Promise<IBaseModel<IPaaCoordenacaoCapacidadeModel[]>> {
    return this.httpClient
      .post<IBaseModel<IPaaCoordenacaoCapacidadeModel[]>>(`${this.apiBaseUrl}/paa/${id}/capacidade-trabalho`, item)
      .toPromise();
  }
  public obterPainelDistribuicao(id: number): Promise<IBaseModel<IPaaDistribuicaoModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPaaDistribuicaoModel[]>>(`${this.apiBaseUrl}/paa/${id}/painel-distribuicao`)
      .toPromise();
  }

  public async obterCapacidadeTrabalhoPorIdAndAno(id: number, ano: number): Promise<IBaseModel<IPaaCoordenacaoCapacidadeModel[]>> {
    let params = new HttpParams();
    params = params.append('ano', ano.toString());
    
    return this.httpClient
      .get<IBaseModel<IPaaCoordenacaoCapacidadeModel[]>>(`${this.apiBaseUrl}/paa/${id}/capacidade-trabalho`, { params })
      .toPromise();
  } 

  public obterHistoricoDistribuicao(id: number, unidadeGestoraId: number): Promise<IBaseModel<IPaaDistribuicaoHistoricoModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPaaDistribuicaoHistoricoModel[]>>(`${this.apiBaseUrl}/paa/${id}/unidade-gestora/${unidadeGestoraId}/historico-distribuicao`)
      .toPromise();
  }

}
