<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/perguntas']">Perguntas</a>
          </li>
          <li class="breadcrumb-item active">{{ titulo }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
              <i *ngIf="!this.id" class="far fa-file pr-2"></i>
              {{ titulo }}
            </h3>
          </div>
          <form [formGroup]="form" #f="ngForm"  (keydown.enter)="$event.preventDefault()">
            <div class="card-body">
              <div class="row">
                <div class="col-md-9">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label for="nome" class="mb-1">Nome</label>
                      <input type="text" [attr.disabled]="visualizar ? true : null" id="nome"
                        class="form-control form-control-sm" formControlName="nome" maxlength="100" [ngClass]="{
                          'is-invalid':
                            f.submitted && form.controls['nome'].invalid
                        }" />
                      <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                        <div *ngIf="form.controls['nome'].errors.required">
                          Campo obrigatório
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-12">
                      <label for="inputDescricao" class="mb-1">Descrição</label>
                      <textarea rows="3" id="inputDescricao" [attr.disabled]="visualizar ? true : null"
                        class="form-control form-control-sm" formControlName="descricao" maxlength="500" [ngClass]="{
                          'is-invalid':
                            f.submitted && form.controls['descricao'].invalid
                        }">
                      </textarea>
                      <div *ngIf="
                          f.submitted && form.controls['descricao'].invalid
                        " class="invalid-feedback">
                        <div *ngIf="form.controls['descricao'].errors.required">
                          Campo obrigatório
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group col-md-12">
                    <label for="selectTipo" class="mb-1">Tipo de Entrada</label>
                    <select id="selectTipo" [attr.disabled]="visualizar ? true : null" formControlName="tipoEntradaId"
                      class="form-control form-control-sm" [ngClass]="{
                        'is-invalid':
                          f.submitted && form.controls['tipoEntradaId'].invalid
                      }" (change)="onChangeTipoEntrada($event)">
                      <option *ngFor="let item of enumTipoEntrada" [value]="item.codigo">
                        {{ item.descricao }}</option>
                    </select>
                    <div *ngIf="
                        f.submitted && form.controls['tipoEntradaId'].invalid
                      " class="invalid-feedback">
                      <div *ngIf="form.controls['tipoEntradaId'].errors.required">
                        Campo obrigatório
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12" [hidden]="!permiteCadastroOpcoes">
                    <div cdkDropList #optionList="cdkDropList" [cdkDropListData]="optionList"
                      (cdkDropListDropped)="onDrop($event)">
                      <mat-toolbar class="table-header">
                        <mat-toolbar-row>
                          <div class="input-group input-group-sm" style="width: 100%;">
                            <input type="text" [hidden]="visualizar" name="table_search"
                              class="form-control float-right" placeholder="Nova opção" formControlName="novaOpcao"
                              (keyup.enter)="incluirNovaOpcao()" />

                            <div class="input-group-append">
                              <button [hidden]="visualizar" type="button" class="btn btn-default"
                                (click)="incluirNovaOpcao()">
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </mat-toolbar-row>
                      </mat-toolbar>
                      <ul class="todo-list connectedSortable ui-sortable" data-widget="todo-list">
                        <li class="d-flex flex-wrap" *ngFor="let opcao of opcoesDataSource;let i = index" cdkDrag [cdkDragDisabled]="visualizar">
                          <span class="ui-sortable-handle">
                            {{i + 1}}
                          </span>
                          <div class="ml-3">
                            {{ opcao.nome }}
                          </div>
                          <div class="ml-auto">
                            <div class="btn-group">
                              <button [hidden]="visualizar" type="button" class="btn btn-danger btn-xs text-left"
                                (click)="excluirOpcao(opcao.id)">
                                <i class="far fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <h5 [hidden]="!semDados" class="table-no-data">
                        Nenhuma opção disponível
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="btn-group" [hidden]="visualizar">
                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                    <i class="far fa-save"></i> Salvar
                </button>
                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                </div>
              </div>

              <button type="button" [hidden]="visualizar" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Cancelar
              </button>
              <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                <i class="fas fa-times"></i> Sair
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>