<div [class]="homologado ? '' : 'homologado'">
  <div class="relatorio content" *ngIf="opa">
    <div class="card card-outline">
      <div class="card-body">
        <section id="header">
          <h3>Ordem de Planejamento de Auditoria nº {{ opa?.sequencial | number: '3.' }}/{{ opa?.ano }}</h3>
        </section>
        <hr />

        <section id="introducao">
          <div class="row">
            <div class="col-md-6">
              <label>Origem:&nbsp;</label>
              <span [innerHTML]="opa?.origem?.nome"></span>
            </div>
            <div class="col-md-6">
              <label>Tipo:&nbsp;</label>
              <span [innerHTML]="opa?.tipoAuditoria?.nome"></span>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-12">
              <label>Unidade Gestora a ser Auditada:&nbsp;</label>
              <span [innerHTML]="opa?.unidadeGestora?.nome"></span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label>Objeto:&nbsp;</label>
              <span [innerHTML]="opa?.objeto"></span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label>Subtipo de auditoria:&nbsp;</label>
              <ul>
                <li *ngFor="let item of opa?.subtiposAuditoria">
                  {{item.subtipoAuditoria?.nome}}
                </li>
              </ul>
            </div>
          </div>
          
        </section>

        <hr />
        <section id="periodoPlanejamento">
          <h4>Período do Planejamento</h4>
          
          <div class="row">
            <div class="col-md-6">
              <label>Data Início de Realização:&nbsp;</label>
              <span>{{ (opa?.dataInicioRealizacao ? opa?.dataInicioRealizacao : opa?.dataInicioPrevista) | date:'dd/MM/yyyy' }}</span>
            </div>
            <div class="col-md-6">
              <label>Data Fim de Realização:&nbsp;</label>
              <span>{{ (opa?.dataFimRealizacao ? opa?.dataFimRealizacao : opa?.dataFimPrevista) | date:'dd/MM/yyyy' }}</span>
            </div>
          </div>

        </section>

        <hr />
        <section id="equipePlanejamento">
          <h4>Equipe de Planejamento</h4>

          <div>
            <table>
              <tr>
                <th>Nome</th>
                <th>Cargo</th>
              </tr>

              <tr *ngFor="let item of opa.opaEquipe?.auditores">
                <td>{{item?.nomeAuditor}}</td>
                <td>{{item?.cargo}}</td>
              </tr>
            </table>
          </div>
        </section>

        <hr />
        <section id="elaboracao">
          <h4>Elaboração</h4>

          <div class="row">
            <div class="col-md-12">
              <label>{{opa?.auditorCoordenacao?.nomeAuditor}}</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <span>{{ opa?.auditorCoordenacao?.coordenacao?.nome }}</span>
            </div>
          </div>

        </section>

        <hr />
        <section id="aprovacoes">
          <h4>Aprovações</h4>

          <div class="cards">
            <div class="card" *ngFor="let item of opa?.historicoAprovacoes">
                <div class="row">
                  <div class="form-group">
                    <label>{{item?.nomeUsuario}}</label>
                  </div>
                </div>
      
                <div class="row">
                  <div class="form-group">
                    <span>{{item?.cargo}}</span>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <hr *ngIf="opa.notaRodape"/>
        <section id="rodape" *ngIf="opa?.notaRodape">
          <span>{{opa?.notaRodape}}</span>
        </section>
      </div>
      <div class="card-footer no-print">
        <button
          type="button"
          class="btn btn-default btn-sm mr-1"
          (click)="cancelar()"
        >
          <i class="fas fa-times"></i> Fechar
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm mr-1"
          (click)="print()"
        >
          <i class="fas fa-print"></i> Imprimir
        </button>
      </div>
    </div>
  </div>
</div>
