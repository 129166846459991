import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as cloneDeep from 'lodash/cloneDeep';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { IBaseModel } from 'src/app/models/base.model';
import { ICoordenacaoModel } from 'src/app/models/coordenacao.model';
import { AcessoCidadaoService } from 'src/app/services/acesso-cidadao.service';
import { CoordenacaoService } from 'src/app/services/coordenacao.service';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';
import { MatSort, MatSortable } from '@angular/material/sort';
import { ICoordenacaoTipoTrabalhoModel } from 'src/app/models/coordenacao-tipo-trabalho.model';
import { ModalCoordenacaoTipoTrabalhoComponent } from './modal-tipo-trabalho/modal-tipo-trabalho.component';
import { IAgentePublicoModel } from 'src/app/models/agente-publico.model';
import { IAgentePublicoDisponibilidadeModel } from 'src/app/models/agente-publico-disponibilidade.model';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { MatPaginator } from '@angular/material/paginator';
import { ICoordenacaoAgentePublicoDispensaModel } from 'src/app/models/coordenacao-agentepublico-dispensa.model';
import { ICoordenacaoAgentePublicoDisponibilidadeModel } from 'src/app/models/coordenacao-agentepublico-disponibilidade.model';


const sortingDataAccessorEquipe = (item, property) => {
	switch (property) {
	  default:
		  return item[property];
	}
};

const sortingDataAccessorTipoTrabalho = (item, property) => {
  switch (property) {
	   case 'nome':
		  return item.tipoTrabalho.nome;
	  default:
		  return item[property];
	}
};

const sortingDataAccessorDispensa = (item, property) => {
  switch (property) {
    case 'dataInício':
      return item.dataInicioDate;
    case 'dataFim':
      return item.dataFimDate;
	  default:
		  return item[property];
	}
};

const sortingDataAccessorDisponibilidade = (item, property) => {
  switch (property) {
	  default:
		  return item[property];
	}
};

@Component({
  selector: 'app-coordenacao-form',
  templateUrl: './coordenacao-form.component.html',
  styleUrls: ['./coordenacao-form.component.scss']
})
export class CoordenacaoFormComponent extends BaseFormComponent implements OnInit, AfterViewInit {
  public model: ICoordenacaoModel = {} as ICoordenacaoModel;
  public modelAgentePublico : IAgentePublicoModel;
  public modelAuditor: IAuditorCoordenacaoModel = {} as IAuditorCoordenacaoModel;
  public modelAuditorOld: IAuditorCoordenacaoModel = {} as IAuditorCoordenacaoModel;
  public auditoresDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>([]);
  public coordenacaoTipoTrabalhoDataSource = new MatTableDataSource<ICoordenacaoTipoTrabalhoModel>([]);
  public semDados = true;
  public semDadosTipoTrabalho = true;
  public openPoppup = false;
  public validateForm = false;
  public aba = 0;
  public displayedColumns: string[] = ['nomeAuditor', 'cargo', 'ativo', 'actions'];
  public displayedTipoTrabalhoColumns: string[] = ['nome', 'qtdMediaDias', 'qtdMembros', 'ativo', 'actions'];

  public idTipoTrabalhoSelecionado = 0;
  public permiteAdicionarTipoTrabalho = true;
  public modelCoordenacaoTipoTrabalho: ICoordenacaoTipoTrabalhoModel = {} as ICoordenacaoTipoTrabalhoModel;
  public selectedYear: number;
  public selectedModelYears: Array<number> = [];
  public selectedModelYearsAbaEquipe: Array<number> = [];
  public tiposTrabalhosInputs: IAgentePublicoDisponibilidadeModel[] = [] as IAgentePublicoDisponibilidadeModel[];
  public disponibilidadeDataSource = new MatTableDataSource<ICoordenacaoAgentePublicoDisponibilidadeModel>([]);
  public dispensaDataSource = new MatTableDataSource<ICoordenacaoAgentePublicoDispensaModel>([]);
  public columnsDistribuicao = ['nome','auditoria', 'consultoria', 'fiscalizaçãoPreventiva', 'inspeção', 'investigaçãoPreliminar', 'outrasAtividades', 'ouvidoria', 'pad', 'par', 'sindicância','total']; // Adicione mais se necessário
  public columnsDispensa = ['nome','descrição', 'dataInício', 'dataFim', 'quantidadeDeDias']; // Adicione mais se necessário
  public disponibilidadeSemDados = true;
  public dispensaSemDados = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("tabela1") public sortTabela1: MatSort;
  @ViewChild("tabelaAuditores") public sortTabelaAuditores: MatSort;
  @ViewChild("tabelaDispensa") public sortTabelaDispensa: MatSort;
  @ViewChild("tabelaDisponibilidade") public sortTabelaDisponibilidade: MatSort;


  public form = new FormGroup({
    sigla: new FormControl('', Validators.required),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    tipoAuditoriaId: new FormControl(''),
  });
  
  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private coordenacaoService: CoordenacaoService,
    private acessoCidadaoService: AcessoCidadaoService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);



    this.form = new FormGroup({
      sigla: new FormControl('', Validators.required),
      nome: new FormControl('', Validators.required),
      descricao: new FormControl('', Validators.required),
      tipoAuditoriaId: new FormControl(''),
    });
  }

  async ngOnInit() {
    await this.popularComboAnos();
    await this.popularComboAnosEquipe();
    this.aba = 0;
    await this.buscar();
    this.atribuirTitulo();
  }

  ngAfterViewInit() {
    this.sortTabela1.sort({id:'nome', start:'asc'} as MatSortable);
    this.coordenacaoTipoTrabalhoDataSource.sortingDataAccessor = sortingDataAccessorTipoTrabalho;
    this.coordenacaoTipoTrabalhoDataSource.sort = this.sortTabela1;

  }

  async buscar(){
    try {
      
      if (!this.novoRegistro) {
        //this.buscarDisponibilidadeAgentePublico();
        const res = await this.coordenacaoService.obterPorIdAno(this.id, this.selectedYear);
        if (res.sucesso) {
          this.model = res.dados;
          
          this.coordenacaoTipoTrabalhoDataSource = new MatTableDataSource<ICoordenacaoTipoTrabalhoModel>(this.model.coordenacaoTipoTrabalho);
          this.coordenacaoTipoTrabalhoDataSource.sortingDataAccessor = sortingDataAccessorTipoTrabalho;
          this.coordenacaoTipoTrabalhoDataSource.sort = this.sortTabela1;
          this.coordenacaoTipoTrabalhoDataSource._updateChangeSubscription();
          this.semDadosTipoTrabalho = this.coordenacaoTipoTrabalhoDataSource.data.length === 0;
          

        } else {
          this.toastr.error(res.mensagem.descricao, 'Atenção');
          this.router.navigate(['/coordenacoes']);
          return;
        }
      }

      

      this.form.patchValue(this.model);
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
      this.router.navigate(['/coordenacoes']);
    }
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    this.validaForm();

    this.submit = true;

    this.atualizarModel(this.form.value);
    this.model.ano = this.selectedYear;

    
    this.model.auditorCoordenacao = this.auditoresDataSource.data;

    const novoAno = this.selectedYear;

this.model.auditorCoordenacao = this.auditoresDataSource.data.map((auditor: IAuditorCoordenacaoModel) => {
  return {
    ...auditor,
    ano: novoAno
  };
});


    try {
      let res: IBaseModel<ICoordenacaoModel> = null;

      if (!this.novoRegistro) {
        res = await this.coordenacaoService.atualizar(this.model);
      } else {
        res = await this.coordenacaoService.inserir(this.model);
      }

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);

        if(salvarEFechar == true){
          this.router.navigate(['/coordenacoes']);
        }else{
          this.router.navigate([`/coordenacoes/${res.dados.id}`]);
          this.buscar();
        }

      } else {        
        const validacoes = this.montarMensagensValidacao(res);
        if(validacoes != "")
          this.exibirMensagemErro(validacoes);        
      }

      
    } catch (err) {
      this.submit = false;
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }
  
  validaForm(){
    if (this.form.invalid) {
      this.exibirMensagemErro('Formulário invalido');
      return;
    }

    if (!this.novoRegistro && this.auditoresDataSource.data.filter(a => a.cargo === 1).length === 0) {
      this.exibirMensagemErro('É necessário um auditor \'Coordenador Titular\'');
      return
    }
  }

  public async incluirNovoAuditor(e: Event) {
    e.preventDefault();
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário invalido');
      this.validateForm = true;
      return;
    }
    // Duplicidade de Agente Publico
    if (this.auditoresDataSource.data.findIndex(a => a.auditorId === this.modelAuditor.auditorId) >= 0) {
      this.modelAuditor = {} as IAuditorCoordenacaoModel;
      this.exibirMensagemErro('Auditor já associado a coordenação.');
      return;
    }

    // Permitir somente um coordenador
    if (this.modelAuditor.cargo === 1 && this.auditoresDataSource.data.filter(_ => _.cargo === 1 && _.ativo === true).length > 0) {
      this.modelAuditor = {} as IAuditorCoordenacaoModel;
      this.exibirMensagemAlerta('Já existe um auditor titular nesta coordenação.');
      return;
    }

    if ((this.modelAuditor.cargo === 2 || this.modelAuditor.cargo === 3) && this.auditoresDataSource.data.filter(_ => _.cargo === 2 || _.cargo === 3).length > 0) {
      this.modelAuditor = {} as IAuditorCoordenacaoModel;
      this.exibirMensagemErro('Já existe um auditor substituindo ou respondendo nesta coordenação.');
      return;
    }

    const jaCadastrado = await this.coordenacaoService.obterAuditorPorCoordenacao(this.id, this.modelAuditor?.auditorId, this.modelAuditor?.cargo, this.selectedYear);
    if (jaCadastrado.sucesso) {
      if ((this.modelAuditor.cargo === 1) && jaCadastrado.dados?.filter(_ => _.coordenacaoId !== this.id && _.cargo === this.modelAuditor.cargo).length > 0) {
        let storedToken: any = localStorage.getItem("auditorGrid");
        this.modelAuditorOld = JSON.parse(storedToken);
        this.auditoresDataSource.data.push(cloneDeep(this.modelAuditorOld));
        this.auditoresDataSource._updateChangeSubscription();
        this.modelAuditor = {} as IAuditorCoordenacaoModel;
        this.exibirMensagemAlerta('Este servidor já esta associado a outra coordenação com este cargo.');
        return;
      }
    }
    else {
      this.modelAuditor = {} as IAuditorCoordenacaoModel;
      this.exibirMensagemErro(jaCadastrado.mensagem.descricao);
      return;
    }

    this.modelAuditor.ativo = true;
    this.auditoresDataSource.data.push(cloneDeep(this.modelAuditor));
    this.auditoresDataSource._updateChangeSubscription();
    this.modelAuditor = {} as IAuditorCoordenacaoModel;
    this.semDados = false;
  }

  public excluirAuditor(element: IAuditorCoordenacaoModel) {
    Swal.fire({
      title: 'Excluir Auditor',
      text: 'Tem certeza que deseja excluir esse Auditor?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        debugger
        if (result.value) {
          this.auditorExcluir(element)
          return;
        }
        return;
      });
  }

  public auditorExcluir(element: IAuditorCoordenacaoModel) {
    
    var indiceAuditorExcluir = this.auditoresDataSource.data.indexOf(element);

    this.auditoresDataSource.data.splice(indiceAuditorExcluir, 1);
    //this.auditoresDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>(this.model.auditorCoordenacao);
    this.auditoresDataSource._updateChangeSubscription();
    this.semDados = this.auditoresDataSource.filteredData.length === 0;
  }

  public editarAuditor(element: IAuditorCoordenacaoModel, index: number) {
    this.modelAuditor = element;
    this.auditoresDataSource.data.splice(index, 1);
    this.auditoresDataSource._updateChangeSubscription();
    this.semDados = this.auditoresDataSource.filteredData.length === 0;
    localStorage.setItem("auditorGrid", JSON.stringify(element));
  }

  public async inativarReativarAuditor(element: IAuditorCoordenacaoModel, index: number, acao: boolean) {
    
    if(acao == false 
      && element.cargo == 1 
      && this.auditoresDataSource.data.filter(x => x.cargo == 1 && x.ativo == true).length < 2){
        this.exibirMensagemAlerta('É necessário um auditor \'Coordenador Titular\'');
      }else{
        
        const jaCadastrado = await this.coordenacaoService.obterAuditorPorCoordenacao(element.coordenacaoId, element.auditorId, element.cargo, this.selectedYear);
        if (jaCadastrado.sucesso) {
            element.ativo = acao;
            this.auditoresDataSource._updateChangeSubscription();
            this.semDados = this.auditoresDataSource.filteredData.length === 0;
          }
        else {
          this.exibirMensagemErro(jaCadastrado.mensagem.descricao);
          return;
        }  
      }

  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.router.navigate(['/coordenacoes']);
  }

  public async exibirModalAuditor() {
    try {
      const res = await this.acessoCidadaoService.ObterAgentesPublicosSecont();
      if (res.sucesso) {
        const idsExistentes = this.auditoresDataSource.data.map(a => a.auditorId);
        const dadosFiltrados = res.dados.filter(agente => !idsExistentes.includes(agente.id));

        const modal = this.exibirModal('Selecionar Agente Público', dadosFiltrados, false);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.modelAuditor.auditorId = data.id;
            this.modelAuditor.nomeAuditor = data.nome;
          }
        }
        );
      } else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
      }
    } catch (err) {
      this.toastr.error(err.mensagem.descricao, 'Atenção');
    }
  }
  
  public mudarAba(ind: number) {

    if(this.novoRegistro){
      this.exibirMensagemErro("Necessário salvar a coordenação.")
      return;
    }

    this.aba = ind;

    switch(ind){
      case 0:
        this.buscar();
      case 1:
        this.obterAbaEquipe();
      case 2:
        this.obterAbaDisponibilidade();
      default:
        console.warn('aba não implementada');
    }
  }


  public async obterAbaEquipe(){
    
    if (!this.novoRegistro) {
      
      const res = await this.coordenacaoService.obterPorIdAno(this.id, this.selectedYear);
      if (res.sucesso) {
        this.model = res.dados;
        
        this.sortTabelaAuditores.sort({id:'ativo', start:'desc'} as MatSortable);
        this.auditoresDataSource = new MatTableDataSource<IAuditorCoordenacaoModel>(this.model.auditorCoordenacao);
        this.auditoresDataSource.sortingDataAccessor = sortingDataAccessorEquipe;
        this.auditoresDataSource.sort = this.sortTabelaAuditores;
        this.auditoresDataSource._updateChangeSubscription();
        this.semDados = this.auditoresDataSource.filteredData.length === 0;

      } else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        this.router.navigate(['/coordenacoes']);
        return;
      }
    }
  }

  public async obterAbaDisponibilidade(){
    await this.buscarDisponibilidadeAgentePublico();
  }

  public inativarReativarTipoTrabalho(element: ICoordenacaoTipoTrabalhoModel, acao: boolean) {
    const titulo = acao == true ? 'Reativar ' : 'Inativar ' + 'Tipo de trabalho';
    const texto = 'Tem certeza que deseja ' + (acao == true ? ' reativar ' : ' inativar ') + 'esse tipo de trabalho?';
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.value) {
          this.tipoTrabalhoInativarReativar(element.id, acao)
          return;
        }
        return;
      });
  }
  public tipoTrabalhoInativarReativar(id: number, acao:boolean){
    if(acao == true){
      this.coordenacaoService
      .reativarTipoTrabalho(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Dados alterados com sucesso.');
          this.buscar();
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao, true);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }else{
      this.coordenacaoService
      .inativarTipoTrabalho(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Dados alterados com sucesso.');
          this.buscar();
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }
  }

  public excluirTipoTrabalho(element: ICoordenacaoTipoTrabalhoModel) {
    Swal.fire({
      title: 'Excluir Tipo trabalho',
      text: 'Tem certeza que deseja excluir esse tipo de trabalho?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.value) {
          this.tipoTrabalhoExcluir(element)
          return;
        }
        return;
      });
  }

  public tipoTrabalhoExcluir(element: ICoordenacaoTipoTrabalhoModel) {
    this.coordenacaoService
      .excluirTipoTrabalho(element.id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Dados alterados com sucesso.');
          this.buscar();
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }


  public adicionarCoordenacaoTipoTrabalho(){
    
    this.abrirModalTipoTrabalho();
  }

  public async onSelectTipoTrabalho(element) {
    this.buscarDisponibilidadeAgentePublico();
    if(this.coordenacaoTipoTrabalhoDataSource.data.filter(d => d.tipoTrabalhoId == element.target.value).length > 0){
      this.permiteAdicionarTipoTrabalho = false;
    }else{
      this.idTipoTrabalhoSelecionado = element.target.value;      
      this.permiteAdicionarTipoTrabalho = true;
    }
  }

  public async onSelectEquipe(element) {
    this.obterAbaEquipe();
  }

  public abrirModalTipoTrabalho(element?: ICoordenacaoTipoTrabalhoModel){
		const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        id: element ? element.id : 0,
		    anoSelecionado: this.selectedYear,
        idCoordenacao: this.id,
        qtdMediaDias: element ? element.qtdMediaDias : 1,
        qtdMembros: element ? element.qtdMembros : 1,
        tipoTrabalhoId: element ? element.tipoTrabalhoId : 0
      };
      
	  const modal = this.matDialog.open(
      ModalCoordenacaoTipoTrabalhoComponent,
        dialogConfig
      );
      modal.afterClosed().subscribe((sucesso) => {
        if (sucesso) {
          this.buscar();
        }
      });
	  }

    public async popularComboAnos(){
      const anoAtual = new Date().getFullYear();
      this.selectedModelYears.push(anoAtual + 4);
      this.selectedModelYears.push(anoAtual + 3);
      this.selectedModelYears.push(anoAtual + 2);
      this.selectedModelYears.push(anoAtual + 1);
      this.selectedModelYears.push(anoAtual);
      this.selectedModelYears.push(anoAtual - 1);
      this.selectedYear = anoAtual;
      }

      public async popularComboAnosEquipe(){
        const anoAtual = new Date().getFullYear();
        this.selectedModelYearsAbaEquipe.push(anoAtual + 4);
        this.selectedModelYearsAbaEquipe.push(anoAtual + 3);
        this.selectedModelYearsAbaEquipe.push(anoAtual + 2);
        this.selectedModelYearsAbaEquipe.push(anoAtual + 1);
        this.selectedModelYearsAbaEquipe.push(anoAtual);
        this.selectedModelYearsAbaEquipe.push(anoAtual - 1);

        this.selectedYear = anoAtual;
        }      

      public async onChangeSelectAno(element) {
        this.buscar();
      }

      public async buscarDisponibilidadeAgentePublico() {
        
        const res = await this.coordenacaoService.getDadosDisponibilidade(this.id, this.selectedYear);
        if(res.sucesso){
          this.sortTabelaDisponibilidade.sort({id:'nome', start:'asc'} as MatSortable) ;

          this.disponibilidadeDataSource = new MatTableDataSource<ICoordenacaoAgentePublicoDisponibilidadeModel>(res.dados);
          this.disponibilidadeDataSource.sortingDataAccessor = sortingDataAccessorDisponibilidade;
          this.disponibilidadeDataSource.sort = this.sortTabelaDisponibilidade;
          this.disponibilidadeSemDados = this.disponibilidadeDataSource.data.length === 0;	
        }
        
        const retDispensa = await this.coordenacaoService.getDadosDispensa(this.id, this.selectedYear);
        if(res.sucesso){
          this.sortTabelaDispensa.sort({id:'nome', start:'asc'} as MatSortable);
          
          this.dispensaDataSource =  new MatTableDataSource<ICoordenacaoAgentePublicoDispensaModel>(retDispensa.dados);
          this.dispensaDataSource.sortingDataAccessor = sortingDataAccessorDispensa;
          this.dispensaDataSource.sort = this.sortTabelaDispensa;
          this.dispensaSemDados = this.dispensaDataSource.data.length === 0;
        }
      
      }
    
      // public calculateTotal() {
        
      //   let total = 0;
      //   if(this.disponibilidadeDataSource.data.length > 0){
      //     total = this.disponibilidadeDataSource.data.map((item) => item.percentual)
      //     .reduce((acum, currentElement) => {
      //       return acum + currentElement;
      //     });
      //   }
    
      //   return total;
      //   }
        
    
        public formatarNome(nome: string): string {
            // Separa as palavras com base em letras maiúsculas e adiciona espaços
            const palavrasSeparadas = nome.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
    
            // Capitaliza a primeira letra de cada palavra, considerando palavras com caracteres especiais
            const nomeFormatado = palavrasSeparadas
                .split(' ')  // Divide a string em palavras
                .map(palavra => 
                    palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
                )
                .join(' ');  // Junta as palavras de volta em uma única string com espaços
    
            return nomeFormatado;
        }

        get desabilitaBotoesGridTipoTrabalho(): boolean{
          const anoAtual = new Date().getFullYear();
          return this.selectedYear < anoAtual;
        }

      atribuirTitulo(){
        if (this.novoRegistro) {
          this.titulo = 'Nova Coordenação';
        } else {
          this.titulo = 'Editar Coordenação - ' + this.model.nome;
        }
        if (this.visualizar) {
          this.titulo = 'Visualizar Coordenação - ' + this.model.nome;
        }
      }
}
