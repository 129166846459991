import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { IPerguntaOpcaoModel } from 'src/app/models/pergunta-opcao.model';
import { IPerguntaModel } from 'src/app/models/pergunta.model';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-pergunta-form',
  templateUrl: './pergunta-form.component.html',
  styleUrls: ['./pergunta-form.component.scss']
})
export class PerguntaFormComponent extends BaseFormComponent implements OnInit {
  public enumTipoEntrada = [] as IEnumModel[];

  public model: IPerguntaModel = {} as IPerguntaModel;
  public opcoesDataSource = [] as IPerguntaOpcaoModel[];
  public semDados = true;
  public permiteCadastroOpcoes = false;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    tipoEntradaId: new FormControl(null, Validators.required),
    novaOpcao: new FormControl(''),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Nova Pergunta';
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.opcoesDataSource,
        event.previousIndex,
        event.currentIndex);
    }
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.perguntaService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar) {
            this.titulo = 'Visualizar Pergunta - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Pergunta - ' + this.model.nome;
          }

          this.opcoesDataSource = this.model.perguntaOpcao.sort((a, b) => a['ordem'].toString().localeCompare(b['ordem'].toString()));
          this.semDados = this.opcoesDataSource.length === 0;
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/perguntas']);
          return;
        }
      }

      // Tabelas de domínio
      await this.tabelaDominioService.obterTipoEntradaPergunta().then((res) => this.enumTipoEntrada = res.dados);
      this.permiteCadastroOpcoes = this.model.tipoEntradaId === 3;
      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/perguntas']);
    }
  }

  public cancelar() {
    this.submit = false;
    this.router.navigate(['/perguntas']);
  }

  public onChangeTipoEntrada(event: any) {
    this.permiteCadastroOpcoes = event.target.value === '3';
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    this.submit = true;
    if (this.form.invalid) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        text: 'Formulário Inválido',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return;
    }

    this.atualizarModel(this.form.value);
    this.model.perguntaOpcao = [] as IPerguntaOpcaoModel[];
    if (this.model.tipoEntradaId.toString() === '3') {
      this.model.perguntaOpcao = this.opcoesDataSource.map((_, i) => ({..._, ordem: i}));
    }

    try {
      let res: IBaseModel<IPerguntaModel> = null;

      if (!this.novoRegistro) {
        res = await this.perguntaService.atualizar(this.model);
      } else {
        res = await this.perguntaService.inserir(this.model);
      }

      if (res.sucesso) {
        if (res.mensagem.nome === 'PerguntaEmUsoAlteradaNovasOpcoes') {
          Swal.fire({
            title: 'A pergunta foi alterada com sucesso!',
            html: 'O(s) Critério(s) abaixo precisa(m) ser alterado(s) com métricas para a(s) nova(s) resposta(s): <br>' + this.montarMensagensValidacao(res),
            icon: 'success',
          }).then(() => {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.router.navigate(['/perguntas']);
          });
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });

          if(salvarEFechar == true)
            this.router.navigate(['/perguntas']);
          else{
            if(this.novoRegistro)
              this.router.navigate([`/perguntas/${res.dados.id}`]);
          }
        }
      } else {
        const validacoes = this.montarMensagensValidacao(res);

        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'warning',
          text: `${res.mensagem.descricao}${validacoes}`,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async onSubmit() {
    this.salvarDados(false);
  }

  public incluirNovaOpcao() {
    const novaOpcao = this.form.controls['novaOpcao'].value;
    if (novaOpcao && novaOpcao.length > 0) {
      this.opcoesDataSource.push({ nome: novaOpcao } as IPerguntaOpcaoModel);
      this.semDados = false;

      this.form.controls['novaOpcao'].setValue('');
    }
  }

  public excluirOpcao(ind: number) {
    this.opcoesDataSource.splice(ind, 1);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.submit = false;
    this.router.navigate(['/perguntas']);
  }
}
