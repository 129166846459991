import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IBaseModel } from '../models/base.model';
import { IOpaModel } from '../models/opa.model';
import { LocationStrategy } from '@angular/common';
import { IFiltroOpa } from '../models/filtros/filtro-opa.model';
import { IEnumModel } from '../models/enum.model';
import { IValidacaoNivelModel } from '../models/validaca-nivel-aprovacao.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { IEntranharDocumentoModel } from '../models/edocs/entranhar-documento.model';

@Injectable({
  providedIn: 'root'
})
export class OpaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro: IFiltroOpa): Promise<IBaseModel<PaginacaoModel<IOpaModel[]>>> {
    const params = this.recuperarParametrosFiltro(filtro);

    return this.httpClient
      .get<IBaseModel<PaginacaoModel<IOpaModel[]>>>(`${this.apiBaseUrl}/opa`, { params })
      .toPromise();
  }

  public obterOrigens(): Promise<IBaseModel<IEnumModel[]>> {
    return this.httpClient
      .get<IBaseModel<IEnumModel[]>>(`${this.apiBaseUrl}/opa/origens`, {})
      .toPromise();
  }

  public obterStatus(): Promise<IBaseModel<IEnumModel[]>> {
    return this.httpClient
      .get<IBaseModel<IEnumModel[]>>(`${this.apiBaseUrl}/opa/status`, {})
      .toPromise();
  }

  public obterPorId(id: number): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .get<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/${id}`)
      .toPromise();
  }

  public obterRelatorio(id: number): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .get<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/${id}/relatorio`)
      .toPromise();
  }

  public gerarNova(): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .get<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/novo`)
      .toPromise();
  }

  public async inserir(data: IOpaModel): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .post<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa`, data)
      .toPromise();
  }

  public async atualizar(data: IOpaModel): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .put<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/opa/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number, justificativa: string): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/opa/${id}/inativar`, { justificativa })
      .toPromise();
  }

  public imprimir(id: number): Promise<any>{
    return this.httpClient
      .get(`${this.apiBaseUrl}/opa/${id}/imprimir`)
      .toPromise();
  }

  public async enviarAprovacao(id: number): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .put<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/${id}/enviar-aprovacao`, null)
      .toPromise();
  }

  public async aprovar(id: number): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .put<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/${id}/aprovar`, null)
      .toPromise();
  }

  public async homologar(id: number, entranharDocumento: IEntranharDocumentoModel): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .put<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/${id}/homologar`, entranharDocumento)
      .toPromise();
  }

  public async gerarNovaVersao(data: IOpaModel): Promise<IBaseModel<IOpaModel>> {
    return this.httpClient
      .post<IBaseModel<IOpaModel>>(`${this.apiBaseUrl}/opa/${data.id}/gerar-versao`, data)
      .toPromise();
  }

  private recuperarParametrosFiltro(filtro: IFiltroOpa): HttpParams {
    let params = new HttpParams();
    params = params.append('pagina', filtro.pagina.toString());
    params = params.append('itensPorPagina', filtro.itensPorPagina.toString());
    params = params.append('colunaOrder', filtro.colunaOrder.toString());
    params = params.append('direcao', filtro.direcao.toString());

    if (filtro.dataInicial){
      params = params.append('dataInicial', filtro.dataInicial.toString());
    }
    if (filtro.dataFinal){
      params = params.append('dataFinal', filtro.dataFinal.toString());
    }
    if (filtro.ativo !== undefined && filtro.ativo !== null) {
      params = params.append('ativo', filtro.ativo.toString());
    }
    if (filtro.auditor) {
      params = params.append('auditor', filtro.auditor.toString());
    }
    if (filtro.coordenador) {
      params = params.append('coordenador', filtro.coordenador.toString());
    }
    if (filtro.numeroObjeto) {
      params = params.append('numeroObjeto', filtro.numeroObjeto);
    }
    if (filtro.origem) {
      params = params.append('origem', filtro.origem.toString());
    }
    if (filtro.status) {
      params = params.append('status', filtro.status.toString());
    }
    if (filtro.subtipoAuditoria) {
      params = params.append('subtipoAuditoria', filtro.subtipoAuditoria.toString());
    }
    if (filtro.tipoAuditoria) {
      params = params.append('tipoAuditoria', filtro.tipoAuditoria.toString());
    }
    if (filtro.unidadeGestora) {
      params = params.append('unidadeGestora', filtro.unidadeGestora.toString());
    }

    return params;
  }

  public async verificarNivelAprovacao(id: number): Promise<IBaseModel<IValidacaoNivelModel>> {
    return this.httpClient
      .get<IBaseModel<IValidacaoNivelModel>>(`${this.apiBaseUrl}/opa/${id}/verificar-nivel-aprovacao`)
      .toPromise();
  }

}
