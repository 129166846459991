<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">Pendências</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Pendências
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="mat-elevation-z4">
            <mat-toolbar class="table-header">
              <mat-toolbar-row>
                <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel"
                  (click)="editar(this.selectedModel)">
                  <i class="far fas fa-pen"></i> Visualizar
                </button>
                <span class="fill-remaining-space"></span>
                <div class="input-group input-group-sm" style="width: 200px;">
                  <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="configurarFiltro(); buscar()"
                    placeholder="Pesquisar por nome" [(ngModel)]="searchNome" />

                  <div class="input-group-append">
                    <button type="button" class="btn btn-info" (click)="configurarFiltro(); buscar()">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
                <div class="btn-group">
                    <button type="button"
                        data-toggle="dropdown" class="btn
                        btn-tool btn-default
                        dropdown-toggle">
                        <i class="fas fa-filter"></i>
                    </button>
                    <div role="menu" class="dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-checkbox">
                        <a (click)="searchStatus= undefined; configurarFiltro();
                            buscar()" class="dropdown-item"><i
                                [ngClass]="searchStatus ===
                                undefined
                                ? 'fas fa-check-circle'
                                : 'far fa-circle'"></i>Exibir
                            todos</a>
                        <a (click)="searchStatus= 1; configurarFiltro();
                            buscar()" class="dropdown-item"><i
                                [ngClass]="searchStatus ===
                                1
                                ? 'fas fa-check-circle'
                                : 'far fa-circle'"></i>Exibir
                            somente pendentes</a>
                        <a (click)="searchStatus= 2; configurarFiltro();
                            buscar()" class="dropdown-item"><i
                                [ngClass]="searchStatus ===
                                2
                                ? 'fas fa-check-circle'
                                : 'far fa-circle'"></i>Exibir
                            somente resolvidos</a>
                    </div>
                </div>
              </mat-toolbar-row>
            </mat-toolbar>
            <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear
              class="mat-elevation-z8" matSortActive="id" matSortDirection="asc" [hidden]="semDados">
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

              <ng-container matColumnDef="selecao">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                    (change)="selectedModel = ($event.checked ? element : null)"
                    [checked]="selectedModel?.id === element.id">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Pendência </th>
                <td mat-cell *matCellDef="let element"> {{element?.tipoDePendencia}} </td>
              </ng-container>

              <ng-container matColumnDef="dataRecebimento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data de Recebimento </th>
                <td mat-cell *matCellDef="let element"> {{element?.dataDoRecebimento | date: 'shortDate'}}</td>
              </ng-container>

              <ng-container matColumnDef="dataPrazo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Prazo </th>
                <td mat-cell *matCellDef="let element"> {{element?.dataDoPrazo | date: 'shortDate'}}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.status?.codigo === 1" class="badge bg-warning text-xs">{{element?.status?.descricao}}</span>
                  <span *ngIf="element?.status?.codigo === 2" class="badge bg-success text-xs">{{element?.status?.descricao}}</span>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selecionar(row)" class="clickable"
                [ngClass]="{'bg-light': selectedModel?.id === row.id}"></tr>
            </table>
            <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
            <mat-paginator #TableOnePaginator="matPaginator"  [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons [length]="totalItens" showFirstLastButtons (page)="selectedModel = null; pageEvent = pageChanged($event)">
            </mat-paginator>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
