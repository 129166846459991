<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">
              <i class="fas fa-home"></i>
              Página Inicial</a>
          </li>
          <li class="breadcrumb-item active">OPA - Ordem de Planejamento de Auditoria</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              OPA - Ordem de Planejamento de Auditoria
            </h3>
          </div>

          <div class="card-body p-2">

            <div class="row">
              <div class="col-md-3 form-group">
                <label for="txtDataInicial" class="mb-1">Período Inicial</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <input type="date" id="txtDataInicial" placeholder="Datepicker" bsdatepicker=""
                    class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="filtroModel.dataInicial">
                </div>
              </div>
              <div class="col-md-3 form-group">
                <label for="txtDataFinal" class="mb-1">Período Final</label>
                <div class="input-group input-group-sm" style="width: 100%;">
                  <input type="date" id="txtDataFinal" placeholder="Datepicker" bsdatepicker=""
                    class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="filtroModel.dataFinal">
                </div>
              </div>
              <div class="col-md-3 form-group">
                <label for="selectOrigem" class="mb-1">Origem</label>
                <ng-select [(ngModel)]="filtroModel.origem">
                  <ng-option *ngFor="let origem of origens" [value]="origem.codigo">{{origem.descricao}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-3 form-group">
                <label for="selectTipoAuditoria" class="mb-1">Tipo de Auditoria</label>
                <ng-select [(ngModel)]="filtroModel.tipoAuditoria">
                  <ng-option *ngFor="let tipo of tiposAuditoria" [value]="tipo.id">{{tipo.nome}}</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 form-group">
                <label for="selectSubtipoAuditoria" class="mb-1">Subtipo de Auditoria</label>
                <ng-select [(ngModel)]="filtroModel.subtipoAuditoria">
                  <ng-option *ngFor="let subtipo of subtiposAuditoria" [value]="subtipo.id">{{subtipo.nome}}</ng-option>
                </ng-select>
              </div>

              <div class="col-md-3 form-group">
                <label for="selectUnidadeGestora" class="mb-1">Unidade Gestora a ser Auditada</label>
                <ng-select [(ngModel)]="filtroModel.unidadeGestora">
                  <ng-option *ngFor="let unidade of unidadesGestoras" [value]="unidade.id">{{unidade.sigla}}</ng-option>
                </ng-select>
              </div>

              <div class="col-md-3 form-group">
                <label for="selectAuditor" class="mb-1">Equipe de Auditoria</label>
                <ng-select [(ngModel)]="filtroModel.auditor">
                  <ng-option *ngFor="let auditor of coordenadores" [value]="auditor.auditorId">{{auditor.nomeAuditor}}</ng-option>
                </ng-select>
              </div>

              <div class="col-md-3 form-group">
                <label for="selectCoordenador" class="mb-1">Coordenador da Auditoria</label>
                <ng-select [(ngModel)]="filtroModel.coordenador">
                  <ng-option *ngFor="let auditor of coordenadores" [value]="auditor.id">{{auditor.nomeAuditor}}</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 form-group">
                <label for="selectSubtipoAuditoria" class="mb-1">Status</label>
                <ng-select [(ngModel)]="filtroModel.status">
                  <ng-option *ngFor="let status of statusOpa" [value]="status.codigo">{{status.descricao}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-3 form-group">
                <label for="selectSituacao" class="mb-1">Situação</label>
                <ng-select [(ngModel)]="situacaoSelecionada">
                  <ng-option *ngFor="let situacao of situacoes" [value]="situacao.codigo">{{situacao.descricao}}</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <button type="button" class="btn btn-success btn-sm mr-1" (click)="novo()" [hidden]="!permissoes.incluir">
                    <i class="far fa-file"></i> Novo
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mr-1"
                    [disabled]="selectedModel?.situacao?.codigo !== 1" (click)="editar(selectedModel.id)" [hidden]="!permissoes.editar">
                    <i class="far fas fa-pen"></i> Editar
                  </button>
                  <button type="button" class="btn btn-warning btn-sm mr-1"
                    [hidden]="!selectedModel || selectedModel.ativo || !permissoes.inativarReativar" mwlConfirmationPopover popoverTitle="Ativar"
                    popoverMessage="Tem certeza que deseja reativar essa OPA?" placement="bottom" confirmText="Sim"
                    cancelText="Não" (confirm)="reativar(selectedModel.id)">
                    <i class="far fa-play-circle"></i>
                    Reativar
                  </button>
                  <button type="button" class="btn btn-warning btn-sm mr-1"
                    [hidden]="(selectedModel && !selectedModel.ativo) || !permissoes.inativarReativar" [disabled]="!selectedModel?.ativo"
                    (click)="inativar(selectedModel.id)">
                    <i class="far fa-pause-circle"></i>
                    Inativar
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1"
                    [disabled]="!selectedModel" (click)="visualizar(selectedModel.id)"
                    [hidden]="!permissoes.visualizar">
                    <i class="far fas fa-eye"></i>
                    Visualizar
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1"
                    [disabled]="!selectedModel" (click)="imprimir(selectedModel.id)"  [hidden]="!permissoes.imprimir">
                    <i class="far fas fa-print"></i> Imprimir
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mr-1" *ngIf="selectedModel?.situacao?.codigo === 4"
                    [disabled]="!selectedModel" (click)="novaVersao(selectedModel.id)">
                    <i class="far fa-save"></i> Gerar Nova Versão
                  </button>
                  <!--Botões de ações -->
                  <span class="fill-remaining-space"></span>

                  <div class="input-group input-group-sm" style="width: 300px;">
                    <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscarComFiltro()"
                      placeholder="Pesquisar por Número da OPA ou Objeto" [(ngModel)]="filtroModel.numeroObjeto" />

                    <div class="input-group-append">
                      <button type="button" class="btn btn-info" (click)="buscarComFiltro()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </mat-toolbar-row>
              </mat-toolbar>
              <table mat-table [dataSource]="dataSource"  matSort matSortDisableClear class="w-100 mat-elevation-z8"
                 matSortActive="numero" matSortDirection="desc" [hidden]="semDados">

                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="selectedModel = ($event.checked ? element : null);"
                      [checked]="selectedModel?.id === element.id">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="numero">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> Nº da OPA</th>
                  <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
                </ng-container>

                <ng-container matColumnDef="unidadeGestora">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> UG a ser auditado</th>
                  <td mat-cell *matCellDef="let element"> {{element?.unidadeGestora?.sigla}} </td>
                </ng-container>

                <ng-container matColumnDef="origem">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Origem</th>
                  <td mat-cell *matCellDef="let element"> {{element?.origem?.descricao}} </td>
                </ng-container>

                <ng-container matColumnDef="dataInicio">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> 	Data Início</th>
                  <td mat-cell *matCellDef="let element"> {{ (element?.dataInicioRealizacao ? element?.dataInicioRealizacao : element?.dataInicioPrevista) | date:'dd/MM/yyyy' }} </td>
                </ng-container>

                <ng-container matColumnDef="dataFim">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Fim</th>
                  <td mat-cell *matCellDef="let element"> {{ (element?.dataFimRealizacao ? element?.dataFimRealizacao : element?.dataFimPrevista) | date:'dd/MM/yyyy' }} </td>
                </ng-container>

                <ng-container matColumnDef="coordenacao">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Coordenação</th>
                  <td mat-cell *matCellDef="let element"> {{element?.tipoAuditoria?.coordenacao?.sigla}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element?.situacao?.codigo === 1" class="badge bg-success text-xs">{{element?.situacao?.descricao}}</span>
                    <span *ngIf="element?.situacao?.codigo === 2" class="badge bg-warning text-xs">{{element?.situacao?.descricao}}</span>
                    <span *ngIf="element?.situacao?.codigo === 4" class="badge bg-primary text-xs">{{element?.situacao?.descricao}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ativo">
                  <th mat-header-cell *matHeaderCellDef  mat-sort-header> Situação </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.ativo" class="badge bg-success text-xs">Ativo</span>
                    <span *ngIf="!element.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  (click)="selecionar(row)" class="clickable"
                  [ngClass]="{'bg-light': selectedModel?.id === row.id && selectedModel?.grupo === row.grupo}">
                </tr>

              </table>
              <h5 [hidden]="!semDados" class="table-no-data">Sem dados a exibir</h5>
              <mat-paginator [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons (page)="selectedModel = null; pageEvent = pageChanged($event)" [length]="totalItens"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
