import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { getPtBrPaginatorIntl } from './core/helpers/ptbr-paginator-intl';
import { AuthInterceptor } from './core/http/auth.interceptor';
import { CapituloRelatorioFormComponent } from './pages/capitulo-relatorio-form/capitulo-relatorio-form.component';
import { CapituloRelatorioListComponent } from './pages/capitulo-relatorio-list/capitulo-relatorio-list.component';
import { CoordenacaoFormComponent } from './pages/coordenacao-form/coordenacao-form.component';
import { CoordenacaoListComponent } from './pages/coordenacao-list/coordenacao-list.component';
import { CalendarioFormComponent } from './pages/calendario-form/calendario-form.component';
import { CalendarioListComponent } from './pages/calendario-list/calendario-list.component';
import { CriterioFormComponent } from './pages/criterio-form/criterio-form.component';
import { ModalMetricaNumericoComponent } from './pages/criterio-form/modal-metrica-numerico/modal-metrica-numerico.component';
import { ModalMetricaOpcoesComponent } from './pages/criterio-form/modal-metrica-opcoes/modal-metrica-opcoes.component';
import { CriterioListComponent } from './pages/criterio-list/criterio-list.component';
import { ErroComponent } from './pages/erro/erro.component';
import { FaixaClassificacaoFormComponent } from './pages/faixa-classificacao-form/faixa-classificacao-form.component';
import { FluxoAprovacaoFormComponent } from './pages/fluxo-aprovacao-form/fluxo-aprovacao-form.component';
import { FluxoAprovacaoListComponent } from './pages/fluxo-aprovacao-list/fluxo-aprovacao-list.component';
import { IndicadorFormComponent } from './pages/indicador-form/indicador-form.component';
import { IndicadorListComponent } from './pages/indicador-list/indicador-list.component';
import { InicialComponent } from './pages/inicial/inicial.component';
import { ModeloRelatorioFormComponent } from './pages/modelo-relatorio-form/modelo-relatorio-form.component';
import { ModeloRelatorioListComponent } from './pages/modelo-relatorio-list/modelo-relatorio-list.component';
import { ParametrizacaoDocumentoEdocsFormComponent } from './pages/parametrizacao-documento-edocs-form/parametrizacao-documento-edocs-form.component';
import { ModalParametrizacaoFormComponent } from './pages/parametrizacao-list/modal-parametrizacao-form/modal-parametrizacao-form.component';
import { ParametrizacaoListComponent } from './pages/parametrizacao-list/parametrizacao-list.component';
import { ModalCapacidadeComponent } from './pages/pea-form/modal-capacidade/modal-capacidade.component';
import { ModalImportarArquivoCriterioComponent } from './pages/pea-form/modal-importar-arquivo-criterio/modal-importar-arquivo-criterio.component';
import { ModalPreencherFormularioComponent } from './pages/pea-form/modal-preencher-formulario/modal-preencher-formulario.component';
import { ModalSelecionarCoordenacoesComponent } from './pages/pea-form/modal-selecionar-coordenacoes/modal-selecionar-coordenacoes.component';
import { ModalSelecionarUgComponent } from './pages/pea-form/modal-selecionar-ug/modal-selecionar-ug.component';
import { ModalVisualizarRespostasComponent } from './pages/pea-form/modal-visualizar-respostas/modal-visualizar-respostas.component';
import { PeaFormComponent } from './pages/pea-form/pea-form.component';
import { PeaListComponent } from './pages/pea-list/pea-list.component';
import { PeaRelatorioComponent } from './pages/pea-relatorio/pea-relatorio.component';
import { PaaRelatorioComponent } from './pages/paa-relatorio/paa-relatorio.component';
import { PerguntaFormComponent } from './pages/pergunta-form/pergunta-form.component';
import { PerguntaListComponent } from './pages/pergunta-list/pergunta-list.component';
import { PeriodoFormComponent } from './pages/periodo-form/periodo-form.component';
import { PeriodoListComponent } from './pages/periodo-list/periodo-list.component';
import { QuestionarioFormComponent } from './pages/questionario-form/questionario-form.component';
import { QuestionarioListComponent } from './pages/questionario-list/questionario-list.component';
import { TipoAuditoriaFormComponent } from './pages/tipo-auditoria-form/tipo-auditoria-form.component';
import { TipoAuditoriaListComponent } from './pages/tipo-auditoria-list/tipo-auditoria-list.component';
import { TipoCriterioFormComponent } from './pages/tipo-criterio-form/tipo-criterio-form.component';
import { TipoCriterioListComponent } from './pages/tipo-criterio-list/tipo-criterio-list.component';
import { TipoDispensaFormComponent } from './pages/tipo-dispensa-form/tipo-dispensa-form.component';
import { TipoDispensaListComponent } from './pages/tipo-dispensa-list/tipo-dispensa-list.component';
import { UnidadeGestoraFormComponent } from './pages/unidade-gestora-form/unidade-gestora-form.component';
import { ModalAssociarComponent } from './pages/unidade-gestora-list/modal-associar/modal-associar.component';
import { ModalImportacaoComponent } from './pages/unidade-gestora-list/modal-importacao/modal-importacao.component';
import { UnidadeGestoraListComponent } from './pages/unidade-gestora-list/unidade-gestora-list.component';
import { AuthService } from './services/auth.service';
import { AppButtonComponent } from './shared/components/app-button/app-button.component';
import { BaseFormComponent } from './shared/components/base-form/base-form.component';
import { BaseListComponent } from './shared/components/base-list/base-list.component';
import { LookupModalComponent } from './shared/components/lookup-modal/lookup-modal.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { UserDropdownMenuComponent } from './shared/layout/header/user-dropdown-menu/user-dropdown-menu.component';
import { MainComponent } from './shared/layout/main/main.component';
import { SidebarComponent } from './shared/layout/sidebar/sidebar.component';
import { PaaListComponent } from './pages/paa-list/paa-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaaElaborarComponent } from './pages/paa-elaborar/paa-elaborar.component';
import { MatSelectModule } from '@angular/material/select';
import { ModalCapacidadeDispensaComponent } from './pages/paa-elaborar/modal-capacidade-dispensa/modal-capacidade-dispensa.component';
import { ModalAlocarEquipeComponent } from './pages/paa-elaborar/modal-alocar-equipe/modal-alocar-equipe.component';
import { ModalSelecionarUGComponent } from './pages/paa-elaborar/modal-selecionar-ug/modal-selecionar-ug.component';
import { ModalSelecionarColunasComponent } from './pages/paa-elaborar/modal-selecionar-colunas/modal-selecionar-colunas.component';
import { ModalCadastrarCriterioComponent } from './pages/paa-elaborar/modal-cadastrar-criterio/modal-cadastrar-criterio.component';
import { ModalCadastrarIndicadorComponent } from './pages/paa-elaborar/modal-cadastrar-indicador/modal-cadastrar-indicador.component';
import { ModalConfirmarReativacaoDistribuicaoComponent } from './pages/paa-elaborar/modal-confirmar-reativacao-distribuicao/modal-confirmar-reativacao-distribuicao.component';
import { PendenciaListComponent } from './pages/pendencia-list/pendencia-list.component';
import { ModalGraficoDistribuicaoComponent } from './pages/paa-elaborar/modal-grafico-distribuicao/modal-grafico-distribuicao.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalConfirmarRejeitarComponent } from './shared/components/modal-confirmacao-rejeitar/modal-confirmacao-rejeitar.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ModalPreencherFormularioPaaComponent } from './pages/paa-elaborar/modal-preencher-formulario/modal-preencher-formulario-paa.component';
import { OpaListComponent } from './pages/opa/list/opa-list.component';
import { PeaGuard } from './core/guards/pea.guard';
import { QuestionarioGuard } from './core/guards/questionario.guard';
import { ModalDelegarQuestionarioComponent } from './pages/questionario-form/modal-delegar/modal-delegar-component';
import { ModalCancelarComponent } from './shared/components/modal-cancelar/modal-cancelar-component';
import { TipoCriterioGuard } from './core/guards/tipoCriterio.guard';
import { CriterioGuard } from './core/guards/criterio.guard';
import { PeriodoGuard } from './core/guards/periodo.guard';
import { IndicadorGuard } from './core/guards/Indicador.guard';
import { TipoAuditoriaGuard } from './core/guards/tipoAuditoria.guard';
import { TipoDispensaGuard } from './core/guards/tipoDispensa.guard';
import { UnidadeGestoraGuard } from './core/guards/tipoDispensa.guard';
import { CoordenacaoGuard } from './core/guards/coordenacao.guard';
import { ModalAutuacaoComponent } from './pages/pea-form/modal-autuacao/modal-autuacao.component';
import { ModalEntranharComponent } from './pages/paa-elaborar/modal-entranhar/modal-entranhar.component';
import { TecnicaAuditoriaListComponent } from './pages/tecnica-auditoria-list/tecnica-auditoria-list.component';
import { TecnicaAuditoriaFormComponent } from './pages/tecnica-auditoria-form/tecnica-auditoria-form.component';
import { LogListComponent } from './pages/log-sistema-list/log-list.component';
import { CustoRecursoListComponent } from './pages/custo-recurso-list/custo-recurso-list.component';
import { CustoRecursoFormComponent } from './pages/custo-recurso-form/custo-recurso-form.component';
import { ProcedimentoAuditoriaListComponent } from './pages/procedimento-auditoria-list/procedimento-auditoria-list.component';
import { ProcedimentoAuditoriaFormComponent } from './pages/procedimento-auditoria-form/procedimento-auditoria-form.component';
import { LogFormComponent } from './pages/log-sistema-form/log-form.component';
import { RecomendacaoListComponent } from './pages/recomendacao-list/recomendacao-list.component';
import { RecomendacaoFormComponent } from './pages/recomendacao-form/recomendacao-form.component';
import { ConstatacaoListComponent } from './pages/constatacao-list/constatacao-list.component';
import { ConstatacaoFormComponent } from './pages/constatacao-form/constatacao-form.component';
import { ModalRecomendacaoComponent } from './pages/constatacao-form/modal-recomendacao/modal-recomendacao.component';
import { QuestaoListComponent } from './pages/questao-list/questao-list.component';
import { QuestaoFormComponent } from './pages/questao-form/questao-form.component';
import { ModalConstatacaoComponent } from './pages/constatacao-form/modal-constatacao/modal-constatacao.component';
import { ModalProcedimentoAuditoriaComponent } from './pages/procedimento-auditoria-form/modal-procedimento-auditoria/modal-procedimento-auditoria.component';
import { OpaFormComponent } from './pages/opa/form/opa-form.component';
import { LookupMultiselectModalComponent } from './shared/components/lookup-multiselect-modal/lookup-multiselect-modal.component';
import { ModalAlocarEquipeOpaComponent } from './pages/opa/modal/modal-alocar-equipe/modal-alocar-equipe-opa.component';
import { MatrizPlanejamentoListComponent } from './pages/matriz-planejamento-list/matriz-planejamento-list.component';
import { MatrizPlanejamentoFormComponent } from './pages/matriz-planejamento-form/matriz-planejamento-form.component';
import { MatrizPlanejamentoRelatorioComponent } from './pages/matriz-planejamento-relatorio/matriz-planejamento-relatorio.component';
import { ImprimirRolResponsaveisComponent } from './pages/rol-responsavel-relatoria/imprimir-rol-responsaveis/imprimir-rol-responsaveis.component';
import { RolResponsaveisFormComponent } from './pages/rol-responsaveis-form/rol-responsaveis-form.component';
import { ModalIncluirResponsavelComponent } from './pages/rol-responsaveis-form/modal-incluir-responsaveis/modal-incluir-responsavel.component';
import { ModalDetalharValorRiscoComponent } from './pages/pea-form/modal-detalhar-valor-risco/modal-detalhar-valor-risco.component';
import { NaturezaResponsabilidadeFormComponent } from './pages/natureza-responsabilidade-form/natureza-responsabilidade-form.component';
import { ModalCapituloComponent } from './pages/modelo-relatorio-form/modal-capitulo/modal-capitulo.component';
import { ModalEnviarQuestionarioCriterioComponent } from './pages/pea-form/modal-enviar-questionario-criterio/modal-enviar-questionario-criterio.component';
import { ModalVisualizarQuestionarioCriterioComponent } from './pages/pea-form/modal-visualizar-questionario-criterio/modal-visualizar-questionario-criterio.component';
import { ModalVisualizarQuestionarioCriterioPaaComponent } from './pages/paa-elaborar/modal-visualizar-questionario-criterio/modal-visualizar-questionario-criterio.component';
import { ModalAlocarEquipePlanejamentoComponent } from './pages/planejamento-form/modal-alocar-equipe/modal-alocar-equipe-planejamento.component';
import { CalendarioGuard } from './core/guards/Calendario.guard';
import { NaturezaResponsabilidadeGuard } from './core/guards/naturezaResponsabilidade.guard';
import { PerguntaGuard } from './core/guards/pergunta.guard';
import { ModeloRelatorioGuard } from './core/guards/modeloRelatorio.guard';
import { SubtipoAuditoriaListComponent } from './pages/subtipo-auditoria-list/subtipo-auditoria-list.component';
import { SubtipoAuditoriaFormComponent } from './pages/subtipo-auditoria-form/subtipo-auditoria-form.component';
import { NaturezaResponsabilidadeListComponent } from './pages/natureza-responsabilidade-list/natureza-responsabilidade-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { ModalConfirmarInativacaoComponent } from './shared/components/modal-confirmar-inativacao/modal-confirmar-inativacao.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PlanejamentoListComponent } from './pages/planejamento-list/planejamento-list.component';
import { TecnicaAuditoriaGuard } from './core/guards/tecnicaAuditoria.guard';
import { CustoRecursoGuard } from './core/guards/custoRecurso.guard';
import { ProcedimentoAuditoriaGuard } from './core/guards/procedimentoAuditoria.guard';
import { QuestaoGuard } from './core/guards/questao.guard';
import { MatrizPlanejamentoGuard } from './core/guards/matrizPlanejamento.guard';
import { RecomendacaoGuard } from './core/guards/recomendacao.guard';
import { SubtipoAuditoriaGuard } from './core/guards/subtipoAuditoria.guard';
import { PlanejamentoFormComponent } from './pages/planejamento-form/planejamento-form.component';
import { ModalUgPlanejamentoComponent } from './pages/planejamento-form/modal-ug-planejamento/modal-ug-planejamento.component';
import { OpaRelatorioComponent } from './pages/opa/relatorio/opa-relatorio.component';
import { ModalImportarArquivoCriterioPaaComponent } from './pages/paa-elaborar/modal-importar-arquivo-criterio-paa/modal-importar-arquivo-criterio-paa.component';
import { ModalSelecionarResponsavelComponent } from './pages/rol-responsaveis-form/modal-selecionar-responsavel/modal-selecionar-responsavel.component';
import { ModalIncluirPeriodoExercicioComponent } from './pages/rol-responsaveis-form/modal-incluir-periodo-exercicio/modal-incluir-periodo-exercicio.component';
import { RolResponsaveisListComponent } from './pages/rol-responsaveis-list/rol-responsaveis-list.component';
import { ImprimirRolResponsavelUnidadeGestoraComponent } from './pages/rol-responsavel-relatoria-unidade-gestora/imprimir-rol-responsaveis/imprimir-rol-responsavel-unidade-gestora.component';
import { ModalSelecionarUgImprimirComponent } from './pages/rol-responsavel-relatoria-unidade-gestora/imprimir-rol-responsaveis/modal-selecionar-ug-imprimir/modal-selecionar-ug-imprimir.component';
import { AprovacaoListComponent } from './pages/aprovacao-list/aprovacao-list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ModalAvaliarComponent } from './pages/aprovacao-list/modal-avaliar/modal-avaliar.component';
import { FaseAtividadeListComponent } from './pages/fase-atividade-list/fase-atividade-list.component';
import { OsaComponent } from './pages/osa/osa.component';
import { OsaListComponent } from './pages/osa/osa-list/osa-list.component';
import { FiltroComponent } from './pages/osa/filtro-component/filtro-component.component';
import { OsaFormComponent } from './pages/osa/osa-form/osa-form.component';
import { OsaAbasComponent } from './pages/osa/osa-form/osa-abas/osa-abas.component';
import { AbaInformacoesBasicasComponent } from './pages/osa/osa-form/aba-informacoes-basicas/aba-informacoes-basicas.component';
import { FaseAtividadeFormComponent } from './pages/fase-atividade-form/fase-atividade-form.component';
import { FaseAtividadeGuard } from './core/guards/faseAtividade.guard';
import { AbaCronogramaComponent } from './shared/components/aba-cronograma/aba-cronograma.component';
import { ModalAtividadeSubatividadeComponent } from './shared/components/aba-cronograma/modal-atividade-subatividade/modal-atividade-subatividade.component';
import { OsaImprimirComponent } from './pages/osa/osa-imprimir/osa-imprimir.component';
import { AbaAmostraComponent } from './shared/components/aba-amostra/aba-amostra.component';
import { InserirAmostraComponent } from './shared/components/aba-amostra/inserir-amostra/inserir-amostra.component';
import { ImprimirAmostraComponent } from './shared/components/aba-amostra/imprimir-amostra/imprimir-amostra.component';
import { ModalRelatoriaCapituloComponent } from './shared/components/capitulo/modal-relatoria-capitulo/modal-relatoria-capitulo.component';
import { CapituloComponent } from './shared/components/capitulo/capitulo.component';
import { RelatoriaComponent } from './shared/components/relatoria/relatoria.component';
import { ModalHistoricoRelatoriaComponent } from './shared/components/capitulo/modal-historico-relatoria/modal-historico-relatoria.component';
import { ModalVisualizarHistoricoComponent } from './shared/components/capitulo/modal-historico-relatoria/modal-visualizar-historico/modal-visualizar-historico.component';
import { ImprimirRelatoriaComponent } from './shared/components/relatoria/imprimir-relatoria/imprimir-relatoria.component';
import { LinhaDoTempoComponent } from './shared/components/linha-do-tempo/linha-do-tempo.component';
import { SanitizedHtmlPipe } from './shared/components/sanitized-html/sanitized-html.pipe';
import { FaixaClassificacaoComponentComponent } from './shared/components/faixa-classificacao-component/faixa-classificacao-component.component';
import { RankingPrioridadeComponent } from './shared/components/ranking-prioridade/ranking-prioridade.component';
import { ModalRankingPrioridadeComponent } from './pages/paa-elaborar/modal-ranking-prioridade/modal-ranking-prioridade.component';
import { LoadingInterceptor } from './core/http/loading.interceptor';
import { KeyboardlockDirective } from './shared/directives/keyboardlock.directive';
import { AlocarEquipeComponent } from './shared/components/alocar-equipe/alocar-equipe.component';
import { AuditoriaListComponent } from './pages/auditoria-list/auditoria-list.component';
import { AbaSolicitacaoTecnicaComponent } from './shared/components/aba-solicitacao-tecnica/aba-solicitacao-tecnica.component';
import { IncluirSolicitacaoComponent } from './shared/components/aba-solicitacao-tecnica/modal-incluir-solicitacao/modal-incluir-solicitacao.component';
import { ModalJustificativaComponent } from './shared/components/modal-justificativa/modal-justificativa.component';
import { ModalIncluirItemComponent } from './shared/components/aba-solicitacao-tecnica/modal-incluir-item/modal-incluir-item.component';
import { AbaMatrizPlanejamentoComponent } from './shared/components/aba-matriz-planejamento/aba-matriz-planejamento.component';
import { ListagemQuestoesComponent } from './shared/components/aba-matriz-planejamento/listagem-questoes/listagem-questoes.component';
import { ModalVisualizarRespostaComponent } from './shared/components/aba-solicitacao-tecnica/modal-visualizar-resposta/modal-visualizar-resposta.component';
import { ModalAdicionarQuestoesComponent } from './shared/components/aba-matriz-planejamento/modal-adicionar-questoes/modal-adicionar-questoes.component';
import { SolicitacaoTecnicaListComponent } from './pages/solicitacao-tecnica/list/solicitacao-tecnica-list.component';
import { ModalVisualizarItemComponent } from './shared/components/aba-solicitacao-tecnica/modal-visualizar-item/modal-visualizar-item.component';
import { ModalArquivoComponent } from './shared/components/modal-arquivo/modal-arquivo.component';
import { ModalEditarQuestaoComponent } from './shared/components/aba-matriz-planejamento/modal-editar-questao/modal-editar-questao.component';
import { SolicitacaoTecnicaFormComponent } from './pages/solicitacao-tecnica/solicitacao-tecnica-form/solicitacao-tecnica-form.component';
import { ImprimirSolicitacaoComponent } from './shared/components/aba-solicitacao-tecnica/imprimir-solicitacao/imprimir-solicitacao.component';
import { ModalItemSolicitacaoComponent } from './pages/solicitacao-tecnica/modal-item-solicitacao/modal-item-solicitacao.component';
import { ModalIncluirUgComponent } from './shared/components/aba-solicitacao-tecnica/modal-incluir-ug/modal-incluir-ug.component';
import { ModalIncluirUgComponentComponent } from './shared/components/unidade-gestora/modal-incluir-ug-component/modal-incluir-ug-component.component';
import { UnidadeGestoraComponent } from './shared/components/unidade-gestora/unidade-gestora.component';
import { ModalUgComponent } from './shared/components/aba-matriz-planejamento/modal-ug/modal-ug.component';
import { ModalDelegarItemComponent } from './pages/solicitacao-tecnica/modal-delegar-item/modal-delegar-item/modal-delegar-item.component';
import { CustosRecursosComponent } from './shared/components/custos-recursos/custos-recursos.component';
import { ModalCustoRecursoPlanejamentoComponent } from './shared/components/custos-recursos/modal-custo-recurso-planejamento/modal-custo-recurso-planejamento.component';
import { ImprimirComponent } from './pages/planejamento-form/imprimir/imprimir.component';
import { ModalAvaliarProrrogacaoComponent } from './shared/components/aba-solicitacao-tecnica/modal-avaliar-prorrogacao/modal-avaliar-prorrogacao.component';
import { MatrizPlanejamentoVisualizarComponent } from './pages/planejamento-form/matriz-planejamento-visualizar/matriz-planejamento-visualizar.component';
import { TipoEnfaseListComponent } from './pages/tipo-enfase-list/tipo-enfase-list.component';
import { TipoEnfaseFormComponent } from './pages/tipo-enfase-form/tipo-enfase-form.component';
import { TipoEnfaseGuard } from './core/guards/tipoEnfase.guard';
import { HistoricoModalComponent } from './shared/components/historico-modal/historico-modal.component';
import { GroupByPipe } from './shared/pipes/groupby-pipe';
import {ModalEnviarQuestionarioCriterioPaaComponent} from './pages/paa-elaborar/modal-enviar-questionario-criterio/modal-enviar-questionario-criterio.component';
import { MenuItemComponent } from './shared/layout/menuitem/menuitem.component';
import { AgentePublicoListComponent } from './pages/agente-publico-list/agente-publico-list.component';
import { AgentePublicoFormComponent } from './pages/agente-publico-form/agente-publico-form.component';
import { AgentePublicoAbaDadosBasicosComponent } from './pages/agente-publico-form/aba-dados-basicos/aba-dados-basicos.component';
import { AgentePublicoAbaDisponibilidadeComponent } from './pages/agente-publico-form/aba-disponibilidade/aba-disponibilidade.component';
import { AgentePublicoAbaDispensasComponent } from './pages/agente-publico-form/aba-dispensas/aba-dispensas.component';
import { ModalAdicionarDispensaComponent } from './pages/agente-publico-form/aba-dispensas/modal-adicionar-dispensa/modal-adicionar-dispensa.component';
import { ModalCoordenacaoTipoTrabalhoComponent } from './pages/coordenacao-form/modal-tipo-trabalho/modal-tipo-trabalho.component';
import { PaaGuard } from './core/guards/paa.guard';
import { PaaAbaIndicesCriteriosComponent } from './pages/paa-elaborar/aba-indices-criterios/paa-aba-indices-criterios.component';
import { PaaAbaDadosBasicosComponent } from './pages/paa-elaborar/aba-dados/paa-aba-dados-basicos.component';
import { PaaAbaCapacidadeComponent } from './pages/paa-elaborar/aba-capacidade/paa-aba-capacidade.component';
import { PaaAbaDistribuicaoComponent } from './pages/paa-elaborar/aba-distribuicao/paa-aba-distribuicao.component';
import { ModalCapacidadePaaComponent } from './pages/paa-elaborar/aba-capacidade/modal-capacidade/modal-capacidade-paa.component';
import { PaaAbaAlocacaoComponent } from './pages/paa-elaborar/aba-alocacao/paa-aba-alocacao.component';

defineLocale('pt-br', ptBrLocale);
registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AppComponent,
        InicialComponent,
        PeriodoListComponent,
        PeriodoFormComponent,
        TipoCriterioListComponent,
        TipoCriterioFormComponent,
        NaturezaResponsabilidadeListComponent,
        MainComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        AppButtonComponent,
        UserDropdownMenuComponent,
        BaseFormComponent,
        BaseListComponent,
        ErroComponent,
        PerguntaFormComponent,
        PerguntaListComponent,
        CriterioListComponent,
        CriterioFormComponent,
        LookupModalComponent,
        LookupMultiselectModalComponent,
        IndicadorListComponent,
        IndicadorFormComponent,
        RolResponsaveisListComponent,
        RolResponsaveisFormComponent,
        TipoDispensaListComponent,
        TipoDispensaFormComponent,
        FaixaClassificacaoFormComponent,
        CoordenacaoListComponent,
        CoordenacaoFormComponent,
        CalendarioListComponent,
        CalendarioFormComponent,
        TipoAuditoriaListComponent,
        TipoAuditoriaFormComponent,
        CapituloRelatorioListComponent,
        CapituloRelatorioFormComponent,
        ParametrizacaoDocumentoEdocsFormComponent,
        ModeloRelatorioListComponent,
        ModeloRelatorioFormComponent,
        PeaListComponent,
        PeaFormComponent,
        UnidadeGestoraListComponent,
        UnidadeGestoraFormComponent,
        QuestionarioListComponent,
        QuestionarioFormComponent,
        FluxoAprovacaoListComponent,
        FluxoAprovacaoFormComponent,
        ModalImportacaoComponent,
        ModalSelecionarUgComponent,
        ModalSelecionarCoordenacoesComponent,
        ModalImportarArquivoCriterioComponent,
        ModalPreencherFormularioComponent,
        ModalCapacidadeComponent,
        PeaRelatorioComponent,
        ModalVisualizarRespostasComponent,
        ModalAssociarComponent,
        ModalMetricaNumericoComponent,
        ModalMetricaOpcoesComponent,
        ParametrizacaoListComponent,
        ModalParametrizacaoFormComponent,
        PaaListComponent,
        PaaElaborarComponent,
        ModalCapacidadeDispensaComponent,
        ModalAlocarEquipeComponent,
        ModalSelecionarUGComponent,
        ModalSelecionarColunasComponent,
        ModalCadastrarCriterioComponent,
        ModalCadastrarIndicadorComponent,
        ModalConfirmarInativacaoComponent,
        ModalConfirmarReativacaoDistribuicaoComponent,
        PendenciaListComponent,
        ModalGraficoDistribuicaoComponent,
        ModalConfirmarRejeitarComponent,
        OpaListComponent,
        PaaRelatorioComponent,
        ModalPreencherFormularioPaaComponent,
        OpaListComponent,
        OpaFormComponent,
        ModalDelegarQuestionarioComponent,
        ModalAutuacaoComponent,
        ModalEntranharComponent,
        TecnicaAuditoriaListComponent,
        TecnicaAuditoriaFormComponent,
        LogListComponent,
        LogFormComponent,
        CustoRecursoListComponent,
        CustoRecursoFormComponent,
        ProcedimentoAuditoriaListComponent,
        ProcedimentoAuditoriaFormComponent,
        RecomendacaoListComponent,
        RecomendacaoFormComponent,
        ConstatacaoListComponent,
        ConstatacaoFormComponent,
        ModalRecomendacaoComponent,
        QuestaoListComponent,
        QuestaoFormComponent,
        ModalConstatacaoComponent,
        ModalProcedimentoAuditoriaComponent,
        ModalAlocarEquipeOpaComponent,
        MatrizPlanejamentoListComponent,
        MatrizPlanejamentoFormComponent,
        MatrizPlanejamentoRelatorioComponent,
        ModalCapituloComponent,
        AprovacaoListComponent,
        ModalAvaliarComponent,
        ModalDetalharValorRiscoComponent,
        NaturezaResponsabilidadeFormComponent,
        ModalCapituloComponent,
        ModalEnviarQuestionarioCriterioComponent,
        ModalVisualizarQuestionarioCriterioComponent,
        ModalVisualizarQuestionarioCriterioPaaComponent,
        SubtipoAuditoriaListComponent,
        SubtipoAuditoriaFormComponent,
        PlanejamentoListComponent,
        PlanejamentoFormComponent,
        ModalAlocarEquipePlanejamentoComponent,
        ModalUgPlanejamentoComponent,
        OpaRelatorioComponent,
        ModalCancelarComponent,
        ModalImportarArquivoCriterioPaaComponent,
        ModalCustoRecursoPlanejamentoComponent,
        ModalIncluirResponsavelComponent,
        ModalSelecionarResponsavelComponent,
        ModalIncluirPeriodoExercicioComponent,
        ImprimirRolResponsaveisComponent,
        ImprimirRolResponsavelUnidadeGestoraComponent,
        ModalSelecionarUgImprimirComponent,
        FaseAtividadeListComponent,
        OsaComponent,
        OsaListComponent,
        FiltroComponent,
        OsaFormComponent,
        OsaAbasComponent,
        AbaInformacoesBasicasComponent,
        FaseAtividadeFormComponent,
        AbaCronogramaComponent,
        ModalAtividadeSubatividadeComponent,
        OsaImprimirComponent,
        AbaAmostraComponent,
        InserirAmostraComponent,
        ImprimirAmostraComponent,
        ModalRelatoriaCapituloComponent,
        ModalHistoricoRelatoriaComponent,
        CapituloComponent,
        RelatoriaComponent,
        ImprimirRelatoriaComponent,
        LinhaDoTempoComponent,
        SanitizedHtmlPipe,
        FaixaClassificacaoComponentComponent,
        RankingPrioridadeComponent,
        ModalRankingPrioridadeComponent,
        KeyboardlockDirective,
        AlocarEquipeComponent,
        AuditoriaListComponent,
        AbaSolicitacaoTecnicaComponent,
        IncluirSolicitacaoComponent,
        ModalJustificativaComponent,
        ModalIncluirItemComponent,
        ModalVisualizarRespostaComponent,
        AbaMatrizPlanejamentoComponent,
        ListagemQuestoesComponent,
        ModalAdicionarQuestoesComponent,
        SolicitacaoTecnicaListComponent,
        ModalVisualizarItemComponent,
        ModalArquivoComponent,
        ModalEditarQuestaoComponent,
        ImprimirSolicitacaoComponent,
        ModalItemSolicitacaoComponent,
        ModalIncluirUgComponent,
        UnidadeGestoraComponent,
        ModalIncluirUgComponentComponent,
        SolicitacaoTecnicaFormComponent,
        ModalUgComponent,
        ModalDelegarItemComponent,
        CustosRecursosComponent,
        ImprimirComponent,
        ModalAvaliarProrrogacaoComponent,
        MatrizPlanejamentoVisualizarComponent,
        TipoEnfaseListComponent,
        TipoEnfaseFormComponent,
        GroupByPipe,
        ModalEnviarQuestionarioCriterioPaaComponent,
        HistoricoModalComponent,
        MenuItemComponent,
        AgentePublicoListComponent,
        AgentePublicoFormComponent,
        AgentePublicoAbaDadosBasicosComponent,
        AgentePublicoAbaDisponibilidadeComponent,
        AgentePublicoAbaDispensasComponent,
        ModalAdicionarDispensaComponent,
        ModalCoordenacaoTipoTrabalhoComponent,
        PaaAbaIndicesCriteriosComponent,
        PaaAbaDadosBasicosComponent,
        PaaAbaCapacidadeComponent,
        PaaAbaDistribuicaoComponent,
        ModalCapacidadePaaComponent,
        PaaAbaAlocacaoComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            enableHtml: true,
        }),
        NgbModule,
        RouterModule,
        ConfirmationPopoverModule.forRoot({
            appendToBody: true,
            confirmButtonType: 'danger',
        }),
        BsDatepickerModule.forRoot(),
        MatTableModule,
        CdkTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatToolbarModule,
        NgxSpinnerModule,
        HttpClientModule,
        MatCheckboxModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        MatDialogModule,
        DragDropModule,
        ModalModule.forRoot(),
        MatTabsModule,
        MatSelectModule,
        MatTooltipModule,
        AngularEditorModule,
        MatRadioModule,
        MatChipsModule,
        NgSelectModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        { provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl() },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        AuthService,
        PeaGuard,
        QuestionarioGuard,
        TipoCriterioGuard,
        CriterioGuard,
        IndicadorGuard,
        PeriodoGuard,
        TipoAuditoriaGuard,
        UnidadeGestoraGuard,
        TipoDispensaGuard,
        CoordenacaoGuard,
        ModeloRelatorioGuard,
        PerguntaGuard,
        NaturezaResponsabilidadeGuard,
        CalendarioGuard,
        TecnicaAuditoriaGuard,
        CustoRecursoGuard,
        ProcedimentoAuditoriaGuard,
        QuestaoGuard,
        MatrizPlanejamentoGuard,
        RecomendacaoGuard,
        SubtipoAuditoriaGuard,
        FaseAtividadeGuard,
        ImprimirRelatoriaComponent,
        TipoEnfaseGuard,
        PaaGuard
    ],
    entryComponents: [
        LookupModalComponent,
        LookupMultiselectModalComponent,
        ModalImportacaoComponent,
        ModalSelecionarUgComponent,
        ModalSelecionarUgImprimirComponent,
        ModalSelecionarCoordenacoesComponent,
        ModalImportarArquivoCriterioComponent,
        ModalEnviarQuestionarioCriterioComponent,
        ModalVisualizarQuestionarioCriterioComponent,
        ModalVisualizarQuestionarioCriterioPaaComponent,
        ModalPreencherFormularioComponent,
        ModalCapacidadeComponent,
        ModalVisualizarRespostasComponent,
        ModalAssociarComponent,
        ModalMetricaNumericoComponent,
        ModalMetricaOpcoesComponent,
        ModalParametrizacaoFormComponent,
        ModalPreencherFormularioPaaComponent,
        ModalCancelarComponent,
        ModalDelegarQuestionarioComponent,
        ModalAutuacaoComponent,
        ModalEntranharComponent,
        ModalAlocarEquipeOpaComponent,
        ModalAutuacaoComponent,
        ModalEntranharComponent,
        ModalVisualizarHistoricoComponent,
        ImprimirRelatoriaComponent,
        ModalEnviarQuestionarioCriterioPaaComponent,
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule {}
