import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as AdminLte from 'admin-lte';
import $ from 'jquery';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IMenuModel } from 'src/app/models/menu.model';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('main-sidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();

  public model: IMenuModel = {} as IMenuModel;

  constructor(public authService: AuthService,
              public menuService: MenuService) { }
  
  async ngOnInit(): Promise<void>{
    await this.authService.obterPerfilUsuario();
    this.obterMenu();
  }

  ngAfterViewInit() {
    $('[data-widget="treeview"]').each(() => {
      AdminLte.Treeview._jQueryInterface.call($(this), 'init');
    });
  }

  public obterMenu(){
    this.menuService.obter()
    .then((res) => {
      if(res.sucesso){
        this.model = res.dados;
        
      }
    })
  }

  public get enumRecursoPerfil(): typeof EnumRecursoPerfil {
    return EnumRecursoPerfil;
  }

  public get enumAcaoRecurso(): typeof EnumAcaoRecurso {
  return EnumAcaoRecurso;
}
}
