<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-sm-12">
				<ol class="breadcrumb float-sm-left">
					<li class="breadcrumb-item">
						<a [routerLink]="['/']">
							<i class="fas fa-home"></i> Página Inicial</a
						>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/pea']">PEAC - Plano Estratégico de Auditoria e Controles </a>
					</li>
					<li class="breadcrumb-item active">{{ titulo }}</li>
				</ol>
			</div>
		</div>
	</div>
</div>

<div class="content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-outline card-lightblue">
					<div class="card-header d-flex pb-0 flex-column">
						<h3 class="card-title mb-0">
							<i
								*ngIf="this.id"
								class="fas fa-pencil-alt pr-2"
							></i>
							<i *ngIf="!this.id" class="far fa-file pr-2"></i>
							{{ titulo }}
						</h3>

						<ul class="nav nav-tabs ml-auto p-0 mt-2">
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 0 }"
									(click)="mudarAba(0)"
									>Dados</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 1 }"
									(click)="mudarAba(1)"
									>Carga dos Critérios</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 2 }"
									(click)="mudarAba(2)"
									>Capacidade de Trabalho</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									(click)="mudarAba(3)"
									[ngClass]="{ active: aba === 3 }"
									>Ranking de Prioridade</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 4 }"
									(click)="mudarAba(4)"
									>Distribuição</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 5 }"
									(click)="mudarAba(5)"
									>Relatoria</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									[ngClass]="{ active: aba === 6 }"
									(click)="mudarAba(6)"
									>Linha do Tempo</a
								>
							</li>
						</ul>
					</div>

					<form
						[formGroup]="form"
						#f="ngForm"
						(keydown.enter)="$event.preventDefault()"
					>
						<div class="card-body pb-0">
							<!-- ABA DADOS -->
							<div #abaDados [hidden]="aba !== 0">
								<div class="row">
									<div class="form-group col-md-12">
										<label for="nome" class="mb-1"
											>Nome</label
										>
										<input
											[attr.disabled]="
												visualizar ? true : null
											"
											type="text"
											id="nome"
											[ngModel]="modelDadosBasicos.nome"
											class="form-control form-control-sm"
											formControlName="nome"
											onkeyup="this.value= this.value.toUpperCase();"
											maxlength="100"
											[ngClass]="{
												'is-invalid':
													submit &&
													form.controls['nome']
														.invalid
											}"
											[readonly]="peaHomologado"
										/>
										<div
											*ngIf="
												submit &&
												form.controls['nome'].invalid
											"
											class="invalid-feedback"
										>
											<div
												*ngIf="
													form.controls['nome'].errors
														.required
												"
											>
												Campo obrigatório
											</div>
										</div>
									</div>
								</div>

                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="periodoId" class="mb-1">Período</label>
                                        <div class="input-group input-group-sm" style="width: 100%;">
                                            <input type="text" id="periodoId" class="form-control form-control-sm select-color"
                                                readonly [ngModel]="modelDadosBasicos.periodo?.nome"
                                                [ngModelOptions]="{ standalone: true }" [ngClass]="{
                          'is-invalid':
                            submit && form.controls['periodoId'].invalid
                        }" />
                                            <div class="input-group-append" *ngIf="!peaHomologado">
                                                <button [hidden]="visualizar" type="button" class="btn btn-info"
                                                    (click)="exibirModalPeriodo()">
                                                    <i class="fas fa-search"></i>
                                                </button>
                                            </div>
                                            <div *ngIf="submit && form.controls['periodoId'].invalid"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls['periodoId'].errors.required">
                                                    Campo obrigatório
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="inputIndicador" class="mb-1">Indicador</label>
                                        <div class="input-group input-group-sm" style="width: 100%;">
                                            <input type="text" id="inputIndicador" class="form-control form-control-sm select-color"
                                                readonly [ngModel]="modelDadosBasicos.indicador?.nome"
                                                [ngModelOptions]="{ standalone: true }" [ngClass]="{
                          'is-invalid':
                            submit && form.controls['indicadorId'].invalid
                        }" />
                                            <div class="input-group-append" *ngIf="!peaHomologado">
                                                <button [hidden]="visualizar" type="button" class="btn btn-info"
                                                    (click)="exibirModalIndicador()">
                                                    <i class="fas fa-search"></i>
                                                </button>
                                            </div>
                                            <div *ngIf="submit && form.controls['indicadorId'].invalid"
                                                class="invalid-feedback">
                                                <div *ngIf="form.controls['indicadorId'].errors.required">
                                                    Campo obrigatório
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

								<div id="tables" class="row">
									<div class="col-md-6">
										<div class="mat-elevation-z1">
											<table
												mat-table
												[dataSource]="ugDataSource"
												matSort
												matSortDisableClear
												matSort
												matSortActive="nome"
												matSortDirection="asc"
											>
												<tr
													mat-header-row
													*matHeaderRowDef="[
														'nome',
														'actions'
													]"
													class="bg-light"
												></tr>

												<ng-container
													matColumnDef="nome"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													>
														Unidade(s) Gestora(s)
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
														class="text"
													>
														{{ element.nome }}
													</td>
												</ng-container>

												<ng-container
													cdkColumnDef="actions"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													>
														<button
															[hidden]="
																visualizar
															"
															type="button"
															class="
																btn
																btn-success
																btn-xs
															"
															(click)="
																exibirModalUg()
															"
															*ngIf="
																!peaHomologado
															"
														>
															Adicionar
														</button>
													</th>
													<td
														mat-cell
														*matCellDef="
															let element;
															let i = index
														"
														class="block"
													>
														<div
															class="btn-group"
															*ngIf="
																!peaHomologado
															"
														>
															<button
																[hidden]="
																	visualizar
																"
																type="button"
																class="
																	btn
																	btn-danger
																	btn-xs
																	text-left
																"
																(click)="
																	excluirUg(i)
																"
															>
																<i
																	class="
																		far
																		fa-trash-alt
																	"
																></i>
															</button>
														</div>
													</td>
												</ng-container>

												<tr
													mat-row
													*matRowDef="
														let row;
														columns: [
															'nome',
															'actions'
														]
													"
												></tr>
											</table>
											<h5
												[hidden]="!ugSemDados"
												class="table-no-data"
											>
												Nenhuma Unidade Gestora
												selecionada
											</h5>
										</div>
									</div>

									<div class="col-md-6">
										<div class="mat-elevation-z1">
											<table
												mat-table
												[dataSource]="
													coordenacoesDataSource
												"
											>
												<tr
													mat-header-row
													*matHeaderRowDef="[
														'nome',
														'actions'
													]"
													class="bg-light"
												></tr>

												<ng-container
													matColumnDef="nome"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													>
														Coordenação(ões)
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															element.coordenacao
																.sigla
														}}
														-
														{{
															element.coordenacao
																.nome
														}}
													</td>
												</ng-container>

												<ng-container
													cdkColumnDef="actions"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													>
														<button
															[hidden]="
																visualizar
															"
															type="button"
															class="
																btn
																btn-success
																btn-xs
															"
															(click)="
																exibirModalCoordenacoes()
															"
															*ngIf="
																!peaHomologado
															"
														>
															Adicionar
														</button>
													</th>
													<td
														mat-cell
														*matCellDef="
															let element;
															let i = index
														"
														class="block"
													>
														<div
															class="btn-group"
															*ngIf="
																!peaHomologado
															"
														>
															<button
																[hidden]="
																	visualizar
																"
																type="button"
																class="
																	btn
																	btn-danger
																	btn-xs
																	text-left
																"
																(click)="
																	excluirCoordenacao(
																		i
																	)
																"
															>
																<i
																	class="
																		far
																		fa-trash-alt
																	"
																></i>
															</button>
														</div>
													</td>
												</ng-container>

												<tr
													mat-row
													*matRowDef="
														let row;
														columns: [
															'nome',
															'actions'
														]
													"
												></tr>
											</table>
											<h5
												[hidden]="!coordenacaoSemDados"
												class="table-no-data"
											>
												Nenhuma Coordenação selecionada
											</h5>
										</div>
									</div>
								</div>
							</div>

							<!-- ABA CARGA CRITERIOS -->
							<div #abaDados [hidden]="aba !== 1">
								<div class="row">
									<div class="col-md-12">
										<div class="mat-elevation-z1">
											<mat-toolbar class="table-header">
												<mat-toolbar-row>
													<div
														class="
															input-group
															input-group-sm
														"
														style="width: 100%"
													>
														<input
															type="text"
															name="table_search"
															class="
																form-control
																float-right
															"
															disabled
															[ngModel]="
																model.indicador
																	?.nome
															"
															[ngModelOptions]="{
																standalone: true
															}"
														/>
													</div>
												</mat-toolbar-row>
											</mat-toolbar>
											<table
												mat-table
												[dataSource]="
													criterioDataSource
												"
												class="w-100"
												matSort
												matSortDisableClear
												matSortActive="id"
												matSortDirection="asc"
												[hidden]="criterioSemDados"
											>
												<tr
													mat-header-row
													*matHeaderRowDef="[
														'criterio',
														'tipo',
														'acoes',
														'respostas'
													]"
												></tr>

												<ng-container
													matColumnDef="criterio"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Critério
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															element.criterio
																.nome
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="tipo"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Tipo de Entrada
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															element.criterio
																.tipoEntrada
																.descricao
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="acoes"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Ações a serem tomadas
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														<div
															*ngIf="
																!peaHomologado
															"
														>
															<button
																[hidden]="
																	visualizar
																"
																type="button"
																class="
																	btn
																	btn-default
																	btn-xs
																"
																*ngIf="
																	element
																		.criterio
																		.tipoEntrada
																		.nome ===
																	'ImportacaoArquivo'
																"
																(click)="
																	importarArquivoCriterio(
																		element.criterioId
																	)
																"
															>
																Importar Arquivo
															</button>
															<button
																[hidden]="
																	visualizarBotaoFormulario
																"
																type="button"
																class="
																	btn
																	btn-default
																	btn-xs
																"
																*ngIf="
																	element
																		.criterio
																		.tipoEntrada
																		.nome ===
																	'Formulario'
																"
																(click)="
																	preencherFormularioCriterio(
																		element.criterioId
																	)
																"
															>
																Preencher
																Formulário
															</button>
															<span
																*ngIf="
																	element
																		.criterio
																		.tipoEntrada
																		.nome ===
																	'Questionario'
																"
															>
																{{
																	element.questionariosEnviados
																		? "Enviado"
																		: "Pendente de Envio"
																}}
															</span>
														</div>
													</td>
												</ng-container>

												<ng-container
													matColumnDef="respostas"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														mat-sort-header
													>
														Respostas
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														<a
															*ngIf="
																element.quantidadeRespostas >
																0
															"
															(click)="
																exibirRespostas(
																	element.criterioId
																)
															"
															style="
																cursor: pointer;
															"
														>
															<i
																class="
																	far
																	fa-eye
																"
															></i
														></a>
													</td>
												</ng-container>

												<tr
													mat-row
													*matRowDef="
														let row;
														columns: [
															'criterio',
															'tipo',
															'acoes',
															'respostas'
														]
													"
												></tr>
											</table>
											<h5
												[hidden]="!criterioSemDados"
												class="table-no-data"
											>
												Nenhuma Carga de Critérios a
												exibir
											</h5>
										</div>
									</div>
								</div>
							</div>

							<!-- ABA CAPACIDADE -->
							<div #abaDados [hidden]="aba !== 2">
								<div class="row">
									<div class="col-md-12">
										<div class="mat-elevation-z1">
											<table
												mat-table
												[dataSource]="
													capacidadeDataSource
												"
												class="w-100"
												[hidden]="capacidadeSemDados"
											>
												<ng-container
													matColumnDef="coordenacao"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													>
														Coordenação
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														{{
															element.coordenacaoNome
														}}
													</td>

												</ng-container>

												<ng-container
													matColumnDef="diasDisponiveis"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														class="text-right"
													>
														Dias Disponíveis
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
														class="text-right"
													>
														{{
															element.diasDisponiveis
														}}

														<button
															type="button"
															class="
																btn
																btn-xs
																custom-button
															"
															(click)="
																descricaoDiasUteis(
																	element,
																	false
																)
															"
														>
															<i
																class="
																	fas
																	fa-eye
																"
															></i>
														</button>
													</td>
												</ng-container>

												<ng-container
													matColumnDef="equipesDisponiveis"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														class="text-right"
													>
														Nº Equipes Disponíveis
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
														class="text-right"
													>
														{{
															element.equipesDisponiveis
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="diasPorAuditoria"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														class="text-right"
													>
														Quant. Dias <br />por
														Auditoria
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
														class="text-right"
													>
														{{
															element.diasPorAuditoria
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="auditoriasPorEquipe"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														class="text-right"
													>
														Nº Auditorias <br />por
														Equipe
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
														class="text-right"
													>
														{{
															element.auditoriasPorEquipe
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="capacidadeTotalCoordenacao"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
														class="text-right"
													>
														Capacidade Total
														<br />por Coordenação
													</th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
														class="text-right"
													>
														{{
															element.capacidadeTotalCoordenacao
														}}
													</td>
												</ng-container>

												<ng-container
													matColumnDef="actions"
												>
													<th
														mat-header-cell
														*matHeaderCellDef
													></th>
													<td
														mat-cell
														*matCellDef="
															let element
														"
													>
														<button
															[hidden]="
																visualizar
															"
															type="button"
															class="
																btn
																btn-primary
																btn-xs
															"
															(click)="
																editarCapacidade(
																	element,
																	false
																)
															"
															*ngIf="
																!peaHomologado
															"
														>
															<i
																class="
																	fas
																	fa-eye
																"
															></i>
														</button>
														<a
															*ngIf="visualizar"
															style="
																cursor: pointer;
															"
															(click)="
																editarCapacidade(
																	element,
																	true
																)
															"
														>
															<i
																class="
																	far
																	fa-eye
																"
															></i
														></a>
													</td>
												</ng-container>

												<tr
													mat-header-row
													*matHeaderRowDef="
														columnsCapacidade
													"
													class="bg-light"
												></tr>
												<tr
													mat-row
													*matRowDef="
														let row;
														columns: columnsCapacidade;
														when: !agrupadorCapacidade
													"
												></tr>

												<!-- Group header -->
												<ng-container
													matColumnDef="groupHeader"
												>
													<td
														colspan="7"
														mat-cell
														*matCellDef="
															let groupBy
														"
													>
														<strong
															>Ano:
															{{ groupBy.ano }}
														</strong>
													</td>
												</ng-container>

												<tr
													mat-row
													class="bg-light groupRow"
													*matRowDef="
														let row;
														columns: [
															'groupHeader'
														];
														when: agrupadorCapacidade
													"
												></tr>

												<!-- Group footer -->
												<ng-container
													matColumnDef="groupFooterLabel"
												>
													<td
														colspan="5"
														mat-cell
														*matCellDef="
															let groupBy
														"
														class="text-right"
													>
														<strong
															>Capacidade de
															Trabalho Total no
															Ano:
														</strong>
													</td>
												</ng-container>
												<ng-container
													matColumnDef="groupFooterValue"
												>
													<td
														mat-cell
														*matCellDef="
															let groupBy
														"
														class="text-right"
													>
														<strong
															>{{
																groupBy.capacidadeTotalAno
															}}
														</strong>
													</td>
												</ng-container>
												<ng-container
													matColumnDef="groupFooterActions"
												>
													<td
														mat-cell
														*matCellDef="
															let groupBy
														"
														class="text-right"
													></td>
												</ng-container>
												<tr
													mat-row
													class="groupRow"
													*matRowDef="
														let row;
														columns: [
															'groupFooterLabel',
															'groupFooterValue',
															'groupFooterActions'
														];
														when: subTotalCapacidade
													"
												></tr>
											</table>
											<h5
												[hidden]="!capacidadeSemDados"
												class="table-no-data"
											>
												Sem capacidade de trabalho
											</h5>
										</div>
									</div>
								</div>
							</div>

							<!-- RANKING PRIORIDADE -->
							<div #abaDados [hidden]="aba !== 3 || novoRegistro">
								<app-ranking-prioridade
									[rankingPrioridadeDataSource]="
										rankingPrioridadeDataSource
									"
									[rankingPrioridadeEmpresasPublicasDataSource]="
										rankingPrioridadeEmpresasPublicasDataSource
									"
									[semDadosRanking]="semDadosRanking"
									[semDadosRankingEmpresaPublica]="
										semDadosRankingEmpresaPublica
									"
								>
								</app-ranking-prioridade>
							</div>
							<!-- Fim -->

							<!-- ABA DISTRIBUICAO -->
							<div #abaDados [hidden]="aba !== 4">
								<div class="row">
									<mat-toolbar class="table-header">
										<mat-toolbar-row>
											<div>
												<mat-checkbox
													[hidden]="visualizar"
													color="primary"
													name="customizaFaixa"
													[(ngModel)]="
														model.classificacaoPersonalizada
													"
													[ngModelOptions]="{
														standalone: true
													}"
													[disabled]="peaHomologado"
												>
													Classificação Personalizada
												</mat-checkbox>
											</div>
											<span
												class="fill-remaining-space"
											></span>
											<div
												class="
													input-group input-group-sm
												"
												[hidden]="
													!model.classificacaoPersonalizada
												"
												style="width: 300px"
											>
												<button
													[hidden]="visualizar"
													class="
														btn btn-danger btn-sm
														mr-1
														float-right
													"
													(click)="
														removerClassificacaoPersonalizada()
													"
												>
													<i class="fa fa-trash"></i>
													Remover classificação
													personalizada
												</button>
											</div>
										</mat-toolbar-row>
									</mat-toolbar>
								</div>

								<div class="row px-2">
									<div class="column">
										<label>Baixo</label>
										<h6>
											{{ pisoBaixo }}
											<strong>a</strong>
											{{ tetoBaixo }}
										</h6>
									</div>
									<div class="column ml-3">
										<label>Médio</label>
										<h6>
											{{ pisoMedio }}
											<strong>a</strong>
											{{ tetoMedio }}
										</h6>
									</div>
									<div class="column ml-3">
										<label>Alto</label>
										<h6>
											{{ pisoAlto }}
											<strong>a</strong>
											{{ tetoAlto }}
										</h6>
									</div>
								</div>

								<app-faixa-classificacao-component
									[model]="modelFaixa"
									[desabilitado]="
										!model.classificacaoPersonalizada ||
										visualizar
									"
								>
								</app-faixa-classificacao-component>

								<div class="row justify-content-between">
									<div class="card-distrivuicao">
										<h4 for="input5" class="mb-3">
											Padrão
										</h4>

										<div class="form-group p-2">
											<label for="input5" class="mb-1"
												>Contempladas</label
											>
											<div
												class="
													mat-elevation-z1
													tableDistribuicao-container
												"
											>
												<table
													class="tableDistribuicao"
													mat-table
													[dataSource]="
														distribuicaoDataSource
													"
												>
													<ng-container
														[matColumnDef]="column"
														*ngFor="
															let column of columnsDistribuicao
														"
													>
														<th
															mat-header-cell
															*matHeaderCellDef
														>
															{{ column }}
														</th>
														<td
															mat-cell
															*matCellDef="
																let element
															"
															[className]="
																'distribuicao-' +
																element[
																	column +
																		'_class'
																]
															"
														>
															<ng-container
																*ngIf="
																	element[
																		column
																	]
																"
															>
																<div
																	class="
																		text-distribuicao
																	"
																>
																	{{
																		element[
																			column
																		]
																	}}
																</div>
																<br /><small
																	>({{
																		element[
																			column +
																				"_indice"
																		]
																	}})</small
																>
															</ng-container>
														</td>
													</ng-container>

													<tr
														mat-header-row
														*matHeaderRowDef="
															columnsDistribuicao
														"
														class="bg-light"
													></tr>
													<tr
														mat-row
														*matRowDef="
															let row;
															columns: columnsDistribuicao
														"
													></tr>
												</table>
												<h5
													[hidden]="
														!distribuicaoSemDados
													"
													class="table-no-data"
												>
													Nenhuma Unidade Gestora
													contemplada
												</h5>
											</div>
										</div>

										<div class="form-group p-2">
											<label for="input5" class="mb-1"
												>Não Contempladas</label
											>
											<div
												class="
													mat-elevation-z1
													tableDistribuicao-container
												"
											>
												<table
													class="tableDistribuicao"
													mat-table
													[dataSource]="
														distribuicaoNaoContempladosDataSource
													"
												>
													<ng-container
														[matColumnDef]="column"
														*ngFor="
															let column of columnsDistribuicao
														"
													>
														<th
															mat-header-cell
															*matHeaderCellDef
														>
															{{ column }}
														</th>
														<td
															mat-cell
															*matCellDef="
																let element
															"
														>
															<ng-container
																*ngIf="
																	element[
																		column
																	]
																"
															>
																<div
																	class="
																		text-distribuicao
																	"
																>
																	{{
																		element[
																			column
																		]
																	}}
																</div>
																<br /><small
																	>({{
																		element[
																			column +
																				"_indice"
																		]
																	}})</small
																>
															</ng-container>
														</td>
													</ng-container>

													<tr
														mat-header-row
														*matHeaderRowDef="
															columnsDistribuicao
														"
														class="bg-light"
													></tr>
													<tr
														mat-row
														*matRowDef="
															let row;
															columns: columnsDistribuicao
														"
													></tr>
												</table>
												<h5
													[hidden]="
														!distribuicaoNaoContempladosSemDados
													"
													class="table-no-data"
												>
													Nenhuma Unidade Gestora não
													contemplada
												</h5>
											</div>
										</div>
									</div>

									<div class="card-distrivuicao">
										<h4 for="input5" class="mb-3">
											Personalizada
										</h4>

										<div class="form-group p-2">
											<label for="input5" class="mb-1"
												>Contempladas</label
											>
											<div
												class="
													mat-elevation-z1
													tableDistribuicao-container
												"
											>
												<table
													class="tableDistribuicao"
													mat-table
													[dataSource]="
														distribuicaoPersonalizadoDataSource
													"
												>
													<ng-container
														[matColumnDef]="column"
														*ngFor="
															let column of columnsDistribuicaoPersonalizado
														"
													>
														<th
															mat-header-cell
															*matHeaderCellDef
														>
															{{ column }}
														</th>
														<td
															mat-cell
															*matCellDef="
																let element
															"
															[className]="
																'distribuicao-' +
																element[
																	column +
																		'_class'
																]
															"
														>
															<ng-container
																*ngIf="
																	element[
																		column
																	]
																"
															>
																<div
																	class="
																		text-distribuicao
																	"
																>
																	{{
																		element[
																			column
																		]
																	}}
																</div>
																<br /><small
																	>({{
																		element[
																			column +
																				"_indice"
																		]
																	}})</small
																>
															</ng-container>
														</td>
													</ng-container>

													<tr
														mat-header-row
														*matHeaderRowDef="
															columnsDistribuicaoPersonalizado
														"
														class="bg-light"
													></tr>
													<tr
														mat-row
														*matRowDef="
															let row;
															columns: columnsDistribuicaoPersonalizado
														"
													></tr>
												</table>
												<h5
													[hidden]="
														!distribuicaoSemDadosPersonalizado
													"
													class="table-no-data"
												>
													Nenhuma Unidade Gestora
													contemplada
												</h5>
											</div>
										</div>

										<div class="form-group p-2">
											<label for="input5" class="mb-1"
												>Não Contempladas</label
											>
											<div
												class="
													mat-elevation-z1
													tableDistribuicao-container
												"
											>
												<table
													class="tableDistribuicao"
													mat-table
													[dataSource]="
														distribuicaoNaoContempladosPersonalizadoDataSource
													"
												>
													<ng-container
														[matColumnDef]="column"
														*ngFor="
															let column of columnsDistribuicaoPersonalizado
														"
													>
														<th
															mat-header-cell
															*matHeaderCellDef
														>
															{{ column }}
														</th>
														<td
															mat-cell
															*matCellDef="
																let element
															"
														>
															<ng-container
																*ngIf="
																	element[
																		column
																	]
																"
															>
																<div
																	class="
																		text-distribuicao
																	"
																>
																	{{
																		element[
																			column
																		]
																	}}
																</div>
																<br /><small
																	>({{
																		element[
																			column +
																				"_indice"
																		]
																	}})</small
																>
															</ng-container>
														</td>
													</ng-container>

													<tr
														mat-header-row
														*matHeaderRowDef="
															columnsDistribuicaoPersonalizado
														"
														class="bg-light"
													></tr>
													<tr
														mat-row
														*matRowDef="
															let row;
															columns: columnsDistribuicaoPersonalizado
														"
													></tr>
												</table>
												<h5
													[hidden]="
														!distribuicaoNaoContempladosSemDadosPersonalizado
													"
													class="table-no-data"
												>
													Nenhuma Unidade Gestora não
													contemplada
												</h5>
											</div>
										</div>
									</div>
								</div>
								<div class="row p-2">
									<table class="legenda">
										<tr>
											<td class="pr-2">
												<strong>Legenda:</strong>
											</td>
											<td
												class="distribuicao-Obrigatoria"
											>
												Obrigatórias
											</td>
											<td
												class="
													distribuicao-EmpresaPublica
												"
											>
												Empresas Públicas
											</td>
											<td class="distribuicao-RiscoAlto">
												Risco Alto
											</td>
											<td class="distribuicao-RiscoMedio">
												Risco Médio
											</td>
											<td class="distribuicao-RiscoBaixo">
												Risco Baixo
											</td>
										</tr>
									</table>
								</div>
							</div>
							<!-- Fim -->

							<!-- ABA RELATORIA -->
							<app-relatoria
								[aba]="aba"
								[relatoriaDados]="relatoriaDados"
								[visualizar]="visualizarRelatoria"
								[submit]="submit"
								[obterDados]="obterDados.bind(this)"
								[model]="model"
								[tipoTrabalhoId]="id"
							>
							</app-relatoria>
							<!-- Fim -->

							<!-- ABA LINHA DO TEMPO -->
							<app-linha-do-tempo [aba]="aba">
							</app-linha-do-tempo>
							<!-- Fim -->
						</div>

                        <div class="card-footer">
                            <div class="btn-group" [hidden]="visualizar" *ngIf="(aba === 0 || aba === 4 && novoRegistro === true) && !peaHomologado">
                                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                                    <i class="far fa-save"></i> Salvar
                                </button>
                                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                                <div class="dropdown-menu" role="menu">
                                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                                </div>
                            </div>
                            <div class="btn-group" [hidden]="visualizar" *ngIf="(aba === 4 && novoRegistro === false) && !peaHomologado">
                                <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                                    <i class="far fa-save"></i> Salvar
                                </button>
                                <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                                <div class="dropdown-menu" role="menu">
                                <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                                <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                                </div>
                            </div>
                           
                            <button type="button" class="btn btn-primary btn-sm mr-1"
                                *ngIf="aba === 3 && semDadosRanking" (click)="gerarRankingDistribuicao()"
                                [hidden]="visualizar">
                                <i class="far fa-save"></i> Gerar Ranking de Distribuição
                            </button>

							<button
								[hidden]="visualizar"
								type="button"
								class="btn btn-default btn-sm mr-1"
								(click)="cancelar()"
							>
								<i class="fas fa-times"></i> Fechar
							</button>

							<div
								*ngIf="
									!novoRegistro &&
									aba === 1 &&
									!peaHomologado &&
									peaQuestionario
								"
								class="btn-group btn-sm mr-1"
							>
								<button
									type="button"
									[hidden]="visualizar"
									class="btn btn-primary btn-sm"
								>
									<i class="far fa-folder-open"></i>
									Questionário
								</button>

								<button
									[hidden]="visualizar"
									type="button"
									class="
										btn btn-primary
										dropdown-toggle dropdown-icon
									"
									data-toggle="dropdown"
								>
									<span class="sr-only">Toggle Dropdown</span>
								</button>
								<div class="dropdown-menu" role="menu">
									<button
										[hidden]="visualizar"
										type="button"
										class="btn btn-default dropdown-item"
										(click)="
											exibirModalEnviarQuestionarioCriterio()
										"
									>
										Enviar Questionário
									</button>
									<button
										[hidden]="visualizar"
										type="button"
										class="
											btn btn-default
											text-center
											dropdown-item
										"
										(click)="
											exibirModalVisualizarQuestionarioCriterio()
										"
									>
										Visualizar
									</button>
									<button
										[hidden]="visualizar"
										type="button"
										class="
											btn btn-default
											text-center
											dropdown-item
										"
										(click)="confirmarCancelamento()"
									>
										Cancelar Envio
									</button>
								</div>
							</div>

							<button
								[hidden]="visualizar"
								type="button"
								class="btn btn-primary btn-sm mr-1"
								*ngIf="
									!novoRegistro &&
									habilitarHomologarRejeitar &&
									!habilitarUltimoNivelAprovacao
								"
								(click)="aprovar()"
							>
								<i class="fas fa-paper-plane"></i> Aprovar
							</button>

							<button
								[hidden]="visualizar"
								type="button"
								class="btn btn-warning btn-sm mr-1"
								*ngIf="aba === 5
								"
								[disabled]="!model.habilitarEnviarAprovacao"
								(click)="enviarAprovar()"
							>
								<i class="far fa-save"></i> Enviar para
								Aprovação
							</button>
							<button
								[hidden]="!visualizar"
								type="button"
								class="btn btn-default btn-sm"
								(click)="cancelar()"
							>
								<i class="fas fa-times"></i> Fechar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
