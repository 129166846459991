import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import Swal from 'sweetalert2';
import { IAgentePublicoDispensaModel } from 'src/app/models/agente-publico-dispensa.model';
import { ITipoDispensaModel } from 'src/app/models/tipo-dispensa.model';
import { TipoDispensaService } from 'src/app/services/tipo-dispensa.service';
import { AgentePublicoService } from 'src/app/services/agente-publico.service';

@Component({
  selector: 'app-modal-adicionar-dispensa',
  templateUrl: './modal-adicionar-dispensa.component.html',
  styleUrls: ['./modal-adicionar-dispensa.component.scss'],
})

export class ModalAdicionarDispensaComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  public model = {} as IAgentePublicoDispensaModel;
  public minDate: Date;
  public maxDate: Date;
  public houveSugestaoData: boolean;
  public dataInvalida: boolean;
  public dataFinalSugerida: Date;
  public anoSelecionado: number;

  public dataObrigatoria = false;

  public tabelaTipoDispensa = [] as ITipoDispensaModel[];
  public selectedTipoDispensa = {} as ITipoDispensaModel;


  constructor(
    private dialogRef: MatDialogRef<ModalAdicionarDispensaComponent>,
    public toastr: ToastrService,
    private tipoDispensaService: TipoDispensaService,
    private agentePublicoService: AgentePublicoService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    
    
    if(data.id && data.id != 0){
      this.model.id = this.data.id;
      this.model.agentePublicoId = this.data.agentePublicoId;
      this.anoSelecionado = this.data.anoSelecionado;
      this.model.tipoDispensaId = this.data.tipoDispensaId;
      this.model.dataInicio = new Date(this.data.dataInicio);
      this.model.dataFim = new Date(this.data.dataFim);
      this.model.qtdDias = this.data.qtdDias;
      
    }else{
      this.model.agentePublicoId = data?.agentePublicoId;
    this.anoSelecionado = data?.anoSelecionado;

    this.minDate = new Date(data?.anoSelecionado, 1 - 1, 1);
    this.maxDate = new Date(data?.anoSelecionado, 12 - 1, 31);
    }
    

  }

  ngOnInit(): void {
    this.buscarTiposDispensa();
  }


  ngOnChangeDataInicio(event:any): void {
    if(event)
      this.validaData(this.model.dataInicio, true);
    if(this.model.tipoDispensaId){
      const qtdDias = this.tabelaTipoDispensa.find(t => t.id === this.model.tipoDispensaId).quantidadeDiasPadrao;
      if(qtdDias > 0){
        const novaData = new Date(this.model.dataInicio);
        novaData.setDate(novaData.getDate() + qtdDias);
        this.model.dataFim = new Date(novaData);
      }
      
    }
    }
  
  ngOnChangeDataFim(event:any): void {
    if(event)
      this.validaData(this.model.dataFim, false);
  }

  public async validaData(dataReferencia: Date, isDataInicial: boolean){
    if(!dataReferencia)
      return false;
  }



  
  ngAfterViewInit(): void {
    
  }

  public verificarDatas() {
    if (this.model.dataInicio == null || this.model.dataFim == null) {
      this.setDataObrigatoria();
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'Por favor, preencha os campo com a data incial e data fim',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      return false;
    }
    return true;
  }

  public setDataObrigatoria() {
    this.dataObrigatoria = true;
  }

  public async concluir() {
    if(this.dataInvalida == true){
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: "Data inválida",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    }

    if(this.houveSugestaoData && this.model.dataFim > this.dataFinalSugerida)
    {
      Swal.fire({
        title: 'Atenção',
        text: "O período informado é maior do que o definido para o tipo de auditoria. Deseja continuar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((res) => {
        if (res.value) {
          if (this.verificarDatas()) {
            
            this.inserirAtualizar();
          }
        }
      });
    }else{
      if (this.verificarDatas()) {
        
        this.inserirAtualizar();
      }
    }

  }

  private async inserirAtualizar(){
    
    if(this.model.id && this.model.id != 0){
      this.atualizar();
    }else{
      this.inserir();
    }
  }

  private async inserir() {
    
    try{
      await this.agentePublicoService.inserirDispensa(this.model)
      .then(res => {
        if(res.sucesso){
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.dialogRef.close(true);
          }
        else{
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "error",
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      });
    }catch(err){
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        text: err,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
    
  }

  private async atualizar() {
    
    try{
      await this.agentePublicoService.atualizarDispensa(this.model)
      .then(res => {
        if(res.sucesso){
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.dialogRef.close(true);
          }
        else{
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "error",
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      });
    }catch(err){
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        text: err,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
    
  }
  public async buscarTiposDispensa(){
    const tipos = await this.tipoDispensaService
                        .obter('', true)
                        .then(res => {
                            if(res.sucesso)
                              this.tabelaTipoDispensa = res.dados;
                            
                        });
    
  }

  nomeAcaoBotaoSalvar(): string{
    return this.model.id && this.model.id != 0 ? "Atualizar" : "Inserir"
  }
  

  public onSelectTpoDispensa(e) {
    this.selectedTipoDispensa = this.tabelaTipoDispensa.find(t => t.id === this.model.tipoDispensaId);
  }  

  public cancelar() {
    this.dialogRef.close(false);
  }

  
}

