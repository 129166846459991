import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IAutuacaoModel } from 'src/app/models/edocs/autuacao.model';
import { ILocalAtuacaoModel } from 'src/app/models/edocs/local-atuacao.model';
import { IPapelModel } from 'src/app/models/edocs/papel.model';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { EdocsService } from 'src/app/services/edocs.service';
import { PeaService } from 'src/app/services/pea.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-autuacao',
  templateUrl: './modal-autuacao.component.html',
  styleUrls: ['./modal-autuacao.component.scss']
})
export class ModalAutuacaoComponent implements OnInit {
  private peaId: number;
  public model = {} as IAutuacaoModel;
  public papeis = [] as IPapelModel[];
  public locaisAutuacao = [] as ILocalAtuacaoModel[];

  constructor(
    private dialogRef: MatDialogRef<ModalAutuacaoComponent>,
    public matDialog: MatDialog,
    public toastr: ToastrService,
    private edocsService: EdocsService,
    public aprovacaoService: AprovacaoService,
    private peaService: PeaService,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.peaId = data.peaId;
  }

  async ngOnInit() {
    await this.obterPapeis();
  }

  public async obterPapeis() {

    try {
      const res = await this.edocsService.obterPapeis();
      if (res.sucesso) {
        this.papeis = res.dados;
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async obterLocaisAutuacao(idPapel: string) {

    try {
      const res = await this.edocsService.obterLocaisAtuacao(idPapel);
      if (res.sucesso) {
        this.locaisAutuacao = res.dados;
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async concluir() {

    try {

      const res = await this.peaService.homologar(this.peaId, this.model);
          if (res.sucesso) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'success',
              text: 'Item homologado com sucesso!',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.dialogRef.close(true);
            this.matDialog.closeAll();
          } else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'warning',
              text: res.mensagem.descricao,
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }

    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async onSelectPapel() {
    await this.obterLocaisAutuacao(this.model.papelAutuadorId);
  }

  public cancelar() {
    this.dialogRef.close(false);
  }
}

