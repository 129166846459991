<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">{{nomeAcaoBotaoSalvar()}} dispensa</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">

                <div class="row">
                    <div class="form-group col-md-8">
                        <label for="selectTipoDispensa" class="mb-1">Tipo de dispensa</label>
                        <select
							id="selectTipoDispensa"
							#selectTipoDispensa
							[(ngModel)]="model.tipoDispensaId"
                            (change)="onSelectTpoDispensa($event)"
							class="form-control form-control-sm"
						>
							<option
								*ngFor="let item of tabelaTipoDispensa"
								[ngValue]="item.id"
							>
								{{ item.nome }}
							</option>
						</select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="mb-1">Qtd dias</label>
                        <input [attr.disabled]="true"
                        type="text"
                        id="inputQtdDias" [(ngModel)]="model.qtdDias"/>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6 was-validated"
                        [ngClass]="{ 'was-validated': dataObrigatoria === true }">
                        <label for="dataInicio" class="mb-1">Data Ínicio</label>
                        <input id="dataInicio" [(ngModel)]="model.dataInicio" required bsDatepicker
                            class="form-control form-control-sm suaClassDatePicker" [minDate]="minDate"
                            [maxDate]="maxDate" maxlength="10" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                            [daysDisabled]="[0,6]"
                            (ngModelChange)="ngOnChangeDataInicio($event);" />
                        <div class="invalid-feedback">
                            Insira uma data de ínicio.
                        </div>
                    </div>
                    <div class="form-group col-md-6 was-validated"
                        [ngClass]="{ 'was-validated': dataObrigatoria === true }">
                        <label for="dataFim" class="mb-1">Data Fim</label>
                        <input id="dataFim" [(ngModel)]="model.dataFim" required bsDatepicker
                            class="form-control form-control-sm suaClassDatePicker" [minDate]="minDate"
                            [maxDate]="maxDate" maxlength="10" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                            [daysDisabled]="[0,6]"
                            (ngModelChange)="ngOnChangeDataFim($event);" />
                        <div class=" invalid-feedback">
                            Insira uma data de fim.
                        </div>
                    </div>
                </div>

                
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
                    <i class="fas fa-check"></i>  {{ nomeAcaoBotaoSalvar()}}
                </button>

                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>