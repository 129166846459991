<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="centered-header bold">CÁLCULO DOS DIAS DISPONÍVEIS</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="onClose()"><i class="fas fa-times"></i></button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-container">
        <table>
          <thead>
            <tr class="header-row">
              <th colspan="3" class="centered-header">PERÍODO DE JAN A DEZ {{ano}}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row-darkerblue bold">
              <td>1</td>
              <td>Total dias do ano (DA)</td>
              <td>{{totalDiasAno}}</td>
            </tr>
            <tr class="spacer-row"><td colspan="3"></td></tr>
            <tr class="row-darkerblue bold">
              <td>2</td>
              <td>Dias não úteis</td>
              <td></td>
            </tr>
            <tr class="row-white">
              <td>2.1</td>
              <td>Sábados e domingos</td>
              <td>{{ sabDom || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>2.2</td>
              <td>Feriados nacionais</td>
              <td>{{ ferNac || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>2.3</td>
              <td>Feriados estaduais</td>
              <td>{{ ferEst || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>2.4</td>
              <td>Feriados municipais</td>
              <td>{{ ferMun || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>2.5</td>
              <td>Ponto Facultativos</td>
              <td>{{ pontoFac || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>2.6</td>
              <td>Outros</td>
              <td>{{ outros || 0 }}</td>
            </tr>
            <tr class="row-lightblue bold">
              <td></td>
              <td>Totais de dias não úteis (DNU)</td>
              <td>{{ totalDNU || 0 }}</td>
            </tr>
            <tr class="spacer-row"><td colspan="3"></td></tr>
            <tr class="row-darkerblue bold">
              <td>3</td>
              <td>Dias úteis (DU) = Dias no ano (-) Dias não úteis = (DA - DNU)</td>
              <td>{{du}}</td>
            </tr>
            <tr class="spacer-row"><td colspan="3"></td></tr>
            <tr class="row-darkerblue bold">
              <td>4</td>
              <td>Dispensas padrão</td>
              <td></td>
            </tr>            
            <tr class="row-white">
              <td>4.1</td>
              <td>Treinamento</td>
              <td>{{treinamento || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>4.2</td>
              <td>Reuniões</td>
              <td>{{reunioes || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>4.3</td>
              <td>Licenças médicas</td>
              <td>{{licencasMedicas || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>4.4 2</td>
              <td>Férias</td>
              <td>{{ferias || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>4.5</td>
              <td>Abonos</td>
              <td>{{abonos || 0 }}</td>
            </tr>
            <tr class="row-white">
              <td>4.6</td>
              <td>Outras tarefas administrativas</td>
              <td>{{outrasTarefas || 0 }}</td>
            </tr>
            <tr class="row-lightblue bold">
              <td></td>
              <td>Total Dispensas padrão (DP)</td>
              <td>{{totalDispensasPadrao}}</td>
            </tr>
            <tr class="spacer-row"><td colspan="3"></td></tr>
            <tr class="row-darkerblue bold">
              <td>5</td>
              <td>Dias disponíveis totais (DDT) = Dias úteis (-) Dispensas padrão = (DU - DP)</td>
              <td>{{diasDisponiveisTotais}}</td>
            </tr>
            <tr class="spacer-row"><td colspan="3"></td></tr>
            <tr class="row-darkerblue bold">
              <td>6</td>
              <td>Dias reservados para Auditorias Especiais (DAE) = {{ auditoriasEspeciaisValor }}% de DDT</td>
              <td>{{diasReservadosAuditoriasEspeciais}}</td>
            </tr>
            <tr class="spacer-row"><td colspan="3"></td></tr>
            <tr class="row-darkerblue bold">
              <td>7</td>
              <td>Dias disponíveis (DD) = Dias disponíveis totais (-) Dias reservados para Auditorias Especiais = (DDT - DAE) ou (DD = {{ auditoriaEspecialDD }}% de DDT)</td>
              <td>{{diasDisponiveis}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="onClose()">
        <i class="fas fa-times"></i> Fechar
      </button>
    </div>
  </div>
</div>
