  import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
  import { MatTableDataSource } from '@angular/material/table';
  import { MatPaginator } from '@angular/material/paginator';
  import { MatSort } from '@angular/material/sort';
  import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
  import { AuthService } from 'src/app/services/auth.service';
  import { OpaService } from 'src/app/services/opa.service';
  import { Router } from '@angular/router';
  import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
  import { ModalConfirmarInativacaoComponent } from 'src/app/shared/components/modal-confirmar-inativacao/modal-confirmar-inativacao.component';
  import { IFiltroOpa } from 'src/app/models/filtros/filtro-opa.model';
  import { IEnumModel } from 'src/app/models/enum.model';
  import { TipoAuditoriaService } from 'src/app/services/tipo-auditoria.service';
  import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
  import { SubtipoAuditoriaService } from 'src/app/services/subtipo-auditoria.service';
  import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
  import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
  import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
  import { CoordenacaoService } from 'src/app/services/coordenacao.service';
  import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';

  const sortingDataAccessor = (item, property) => {
    switch (property) {
      case 'numeroOpa':
        return item.numero;
      case 'unidadeGestora':
        return item.unidadeGestora.sigla;
      case 'origem':
        return item.origem.descricao;
      case 'dataInicio':
        return item.dataInicioRealizacao || item.dataInicioPrevista;
      case 'dataFim':
        return item.dataFimRealizacao || item.dataFimPrevista;
      case 'coordenacao':
        return item.tipoAuditoria.coordenacao.sigla;
      case 'ativo':
        return item.ativo;
      default:
        return item[property];
    }
  };
  @Component({
    selector: 'app-opa-list',
    templateUrl: './opa-list.component.html',
    styleUrls: ['./opa-list.component.scss']
  })
  export class OpaListComponent extends BaseListComponent implements OnInit, AfterViewInit {
    public dataSource = new MatTableDataSource<any>([]);
    public selectedModel: any;
    public pageEvent: any;
    public semDados = true;
    public totalItens: number;

    public displayedColumns: string[] = [
      'selecao',
      'numero',
      'unidadeGestora',
      'origem',
      'dataInicio',
      'dataFim',
      'coordenacao',
      'status',
      'ativo'
    ];

    private filtro: IFiltroOpa = {} as IFiltroOpa;
    public filtroModel: IFiltroOpa = {} as IFiltroOpa;
    public situacaoSelecionada: number;

    public origens = [] as IEnumModel[];
    public tiposAuditoria = [] as ITipoAuditoriaModel[];
    public subtiposAuditoria = [] as ISubtipoAuditoriaModel[];
    public statusOpa = [] as IEnumModel[];
    public unidadesGestoras = [] as IUnidadeGestoraModel[];
    public situacoes = [{codigo: 1, descricao: 'Todos'}, {codigo: 2, descricao: 'Ativos'}, {codigo: 3, descricao: 'Inativos'}] as IEnumModel[];
    public coordenadores = [] as IAuditorCoordenacaoModel[];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private opaService: OpaService,
                private tipoAuditoriaService: TipoAuditoriaService,
                private subtipoAuditoriaService: SubtipoAuditoriaService,
                private unidadeGestoraService: UnidadeGestoraService,
                private coordenacaoService: CoordenacaoService,
                public authService: AuthService,
                private router: Router,
                public matDialog: MatDialog) {
      super();
    }

    ngOnInit(): void {
      this.setarPermissoes();
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = sortingDataAccessor
    }
  
    private setarPermissoes(): void {
      this.permissoes = new IPermissaoModel();
      this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOPA, EnumAcaoRecurso.Consultar);
      this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOPA, EnumAcaoRecurso.Editar);
      this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOPA, EnumAcaoRecurso.Excluir);
      this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOPA, EnumAcaoRecurso.AtivarInativar);
      this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOPA, EnumAcaoRecurso.Inserir);
      this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.ManterOPA, EnumAcaoRecurso.Visualizar);
   }

    ngAfterViewInit() {
      this.configFiltro();
      this.obterOrigens();
      this.obterStatus();
      this.obterTiposAuditoria();
      this.obterSubtiposAuditoria();
      this.obterUnidadesGestoras();
      this.obterCoordenadores();
      this.buscar();
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = sortingDataAccessor
    }

    private obterOrigens() {

      this.opaService
      .obterOrigens()
      .then((res) => {
        this.origens = res.dados;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }

    private obterStatus() {

      this.opaService
      .obterStatus()
      .then((res) => {
        this.statusOpa = res.dados;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }

    private obterTiposAuditoria() {

      this.tipoAuditoriaService
      .obterAtivos()
      .then((res) => {
        this.tiposAuditoria = res.dados;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }

    private obterSubtiposAuditoria() {

      this.subtipoAuditoriaService
      .obterAtivos()
      .then((res) => {
        this.subtiposAuditoria = res.dados;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }

    private obterUnidadesGestoras() {

      this.unidadeGestoraService
      .obterAtivos()
      .then((res) => {
        this.unidadesGestoras = res.dados;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }

    public obterCoordenadores(){
      this.coordenacaoService
      .obterCoordenadoresAtivos()
      .then((res) => {
        if (res.sucesso) {
          this.coordenadores = res.dados;
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }

    public buscar() {
      this.selectedModel = null;

      this.opaService
      .obter(this.filtro)
      .then((res) => {
        this.dataSource = new MatTableDataSource<any>(res.dados.resultado);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = sortingDataAccessor ;
        this.totalItens = res.dados.totalItens

        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
    }

    buscarComFiltro() {
      this.filtro.dataInicial = this.filtroModel.dataInicial;
      this.filtro.dataFinal = this.filtroModel.dataFinal;
      this.filtro.ativo = this.situacaoSelecionada > 1 ? this.situacaoSelecionada === 2 : null;
      this.filtro.auditor = this.filtroModel.auditor;
      this.filtro.coordenador = this.filtroModel.coordenador;
      this.filtro.numeroObjeto = this.filtroModel.numeroObjeto;
      this.filtro.origem = this.filtroModel.origem;
      this.filtro.status = this.filtroModel.status;
      this.filtro.subtipoAuditoria = this.filtroModel.subtipoAuditoria;
      this.filtro.tipoAuditoria = this.filtroModel.tipoAuditoria;
      this.filtro.unidadeGestora = this.filtroModel.unidadeGestora;
      this.configFiltro();
      this.buscar();
    }

    configFiltro()
    {
      this.filtro.pagina = this.paginator.pageIndex + 1;
      this.filtro.itensPorPagina = this.paginator.pageSize;
      this.filtro.colunaOrder = this.sort.active;
      this.filtro.direcao = this.sort.direction;
    }

    pageChanged(e)
    {
      this.filtro.pagina = e.pageIndex + 1;
      e.pageIndex =  this.filtro.pagina;
      this.filtro.itensPorPagina = e.pageSize;

      this.buscar();
    }

    public selecionar(item: any) {
      this.selectedModel = !this.selectedModel || this.selectedModel !== item ? item : null;
    }

    public novo() {
      this.router.navigate(['/opa/novo']);
    }

    public editar(id: number){
      this.router.navigate([`/opa/${id}`]);
    }

    public novaVersao(id: number){
      this.router.navigate([`/opa/${id}/nova-versao`]);
    }

    public visualizar(id: number){
      this.router.navigate([`/opa/${id}/visualizar`]);
    }

    public reativar(id: number) {
      this.opaService
        .reativar(id)
        .then((res) => {
          if (res.sucesso) {
            this.exibirMensagemSucesso('OPA reativada com sucesso.');
          } else {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        })
        .catch((err) => {
          this.exibirMensagemErro(err.mensagem.descricao);
        })
        .finally(() => {
          this.buscar();
        });
    }

    public inativar(id: number) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = { titulo: 'Tem certeza que deseja inativar essa OPA?' };
      const modal = this.matDialog.open(
        ModalConfirmarInativacaoComponent,
        dialogConfig
      );

      modal.afterClosed().subscribe((data) => {
        if (data) {
          this.opaService
            .inativar(id, data)
            .then((res) => {
              if (res.sucesso) {
                this.exibirMensagemSucesso('OPA inativada com sucesso.');
              } else {
                this.exibirMensagemAlerta(res.mensagem.descricao);
              }
            })
            .catch((err) => {
              this.exibirMensagemErro(err.mensagem.descricao);
            })
            .finally(() => {
              this.buscar();
            });
        }
      });
    }

    public imprimir(id: number){
      this.router.navigate([`/opa/${id}/relatorio`]);
    }
  }
