import { OnInit, AfterViewInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { FiltroUnidadeGestora } from 'src/app/models/filtro-unidade-gestora';

import { IPermissaoModel } from 'src/app/models/permissao.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { AuthService } from 'src/app/services/auth.service';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';

import { ModalImportacaoComponent } from './modal-importacao/modal-importacao.component';

const sortingDataAccessor = (item, property) => {
    switch (property) {
        case 'id':
            return parseInt(item.codigoUg, 10);
        case 'codigoUg':
            return parseInt(item.codigoUg, 10);    
        case 'sigla':
            return item.sigla.trim().toLowerCase(); ;
        case 'nome':
            return item.nome.trim().toLowerCase(); ;
        case 'responsavel':
            return item.nomeResponsavel.trim().toLowerCase(); ;
        case 'ativo':
            return item.ativo.trim().toLowerCase(); ;
        default:
            return item.nome.trim().toLowerCase(); ;
    }
};

@Component({
    selector: 'app-unidade-gestora-list',
    templateUrl: './unidade-gestora-list.component.html',
    styleUrls: [ './unidade-gestora-list.component.scss' ],
})
export class UnidadeGestoraListComponent extends BaseListComponent implements OnInit, AfterViewInit {
    public dataSource = new MatTableDataSource<IUnidadeGestoraModel>([]);
    public selectedModel: IUnidadeGestoraModel;
    public displayedColumns: string[] = [ 'selecao', 'codigoUg', 'sigla', 'nome', 'responsavel', 'ativo' ];
    public searchNome: string;
    public searchAtivo = true;
    public semDados = true;
    public totalItens: number;
    public searchPesquisa: string;
    public pageEvent: any;
    public filtro = {} as FiltroUnidadeGestora;

    @ViewChild('TableOnePaginator', { static: true })
    tableOnePaginator: MatPaginator;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    constructor(
        private unidadeGestoraService: UnidadeGestoraService,
        private router: Router,
        private matDialog: MatDialog,
        private authService: AuthService
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.configurarFiltro();
        this.buscar();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = sortingDataAccessor;
    }

    ngOnInit(): void {
        this.setarPermissoes();
    }



    private setarPermissoes(): void {
        this.permissoes = new IPermissaoModel();
        this.permissoes.consultar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Consultar
        );
        this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.Cadastros, EnumAcaoRecurso.Editar);
        this.permissoes.excluir = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Excluir
        );
        this.permissoes.inativarReativar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.AtivarInativar
        );
        this.permissoes.incluir = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Inserir
        );
        this.permissoes.visualizar = this.authService.possuiPermissao(
            EnumRecursoPerfil.Cadastros,
            EnumAcaoRecurso.Visualizar
        );
    }

    public novo() {
        this.router.navigate([ '/unidades-gestoras/novo' ]);
    }

    public buscar() {
        this.configurarFiltro();
        //this.selectedModel = null;
        this.unidadeGestoraService
            .obter(this.filtro)
            .then((res) => {
                this.dataSource = new MatTableDataSource<IUnidadeGestoraModel>(res.dados.resultado.sort((a, b) =>
                    a['nome'].localeCompare(b['nome'])
                ) as IUnidadeGestoraModel[]);
                this.dataSource = new MatTableDataSource<IUnidadeGestoraModel>(res.dados.resultado);
                this.totalItens = res.dados.totalItens;
                this.paginator = this.tableOnePaginator;
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = sortingDataAccessor;
                this.dataSource._updateChangeSubscription();
                this.semDados = this.dataSource.filteredData.length === 0;
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            });
    }

    sortData(sort) {
        this.filtro.colunaOrder = sort.active;
        this.filtro.direcao = sort.direction;
    
        this.buscar();
    }

    private configurarFiltro() {
        this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
        this.filtro.itensPorPagina = +this.tableOnePaginator.pageSize;
        this.filtro.colunaOrder = this.sort.active;
        this.filtro.direcao = this.sort.direction;
        this.filtro.ativo = this.searchAtivo;
        this.filtro.pesquisa = this.searchNome == undefined ? '' : this.searchNome;
        this.filtro.nome = this.searchNome == undefined ? '' : this.searchNome;
        this.filtro.sigla = this.searchNome == undefined ? '' : this.searchNome;
    }

    pageChanged(e) {
        this.filtro.pagina = e.pageIndex + 1;
        e.pageIndex = this.filtro.pagina;
        this.filtro.itensPorPagina = e.pageSize;
        this.buscar();
    }

    public reativar(id: number) {
        this.unidadeGestoraService
            .reativar(id)
            .then((res) => {
                if (res.sucesso) {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        text: 'Unidade Gestora reativada com sucesso!',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                } else {
                    this.exibirMensagemAlerta(res.mensagem.descricao);
                }
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            })
            .finally(() => {
                this.buscar();
            });
    }

    public inativar(id: number) {
        this.unidadeGestoraService
            .inativar(id)
            .then((res) => {
                if (res.sucesso) {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        text: 'Unidade Gestora inativada com sucesso!',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                } else {
                    this.exibirMensagemAlerta(res.mensagem.descricao);
                }
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            })
            .finally(() => {
                this.buscar();
            });
    }

    public editar(id: number) {
        this.router.navigate([ `/unidades-gestoras/${id}` ]);
    }

    public selecionar(item: any) {
        this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
    }

    public excluir(id: number) {
        this.unidadeGestoraService
            .excluir(id)
            .then((res) => {
                if (res.sucesso) {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        text: 'Unidade Gestora excluída com sucesso.',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                } else {
                    this.exibirMensagemAlerta(res.mensagem.descricao);
                }
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Erro',
                    showConfirmButton: false,
                    timer: 5000,
                });
            })
            .finally(() => {
                this.buscar();
            });
    }

    public visualizar(id: number) {
        this.router.navigate([ `/unidades-gestoras/${id}/visualizar` ]);
    }

    public importarUgPorServico() {
        this.unidadeGestoraService
            .importarUgServico()
            .then((res) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    text: res.toString(),
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error na importação de UG via serviço',
                    text: err.mensagem.descricao,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            })
            .finally(() => {
                this.buscar();
            });
    }

    public importar() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '450px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        const dialogRef = this.matDialog.open(ModalImportacaoComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.unidadeGestoraService
                    .importar(data)
                    .then((res) => {
                        if (res.sucesso) {
                            Swal.fire({
                                toast: true,
                                position: 'top-end',
                                icon: 'success',
                                text: 'Importação Finalizada!',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        } else {
                            this.exibirMensagemAlerta(res.mensagem.descricao);
                        }
                    })
                    .catch((err) => {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'error',
                            text: err.mensagem.descricao,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    })
                    .finally(() => {
                        this.buscar();
                    });
            }
        });
    }
}
