import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ITipoEnfaseModel } from '../models/tipo-enfase.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { FiltroModel } from '../models/filtro.model';
import { BaseService } from './base.service';
import { filterBy } from '../core/helpers/filterby';

@Injectable({
  providedIn: 'root',
})
export class TipoEnfaseService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro: FiltroModel): Promise<IBaseModel<PaginacaoModel<ITipoEnfaseModel[]>>> {
    return this.httpClient
      .get<IBaseModel<PaginacaoModel<ITipoEnfaseModel[]>>>(filterBy(filtro,`${this.apiBaseUrl}/tipoEnfase`))
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<ITipoEnfaseModel>> {
    return this.httpClient
      .get<IBaseModel<ITipoEnfaseModel>>(`${this.apiBaseUrl}/tipoEnfase/${id}`)
      .toPromise();
  }

  public async inserir(data: ITipoEnfaseModel): Promise<IBaseModel<ITipoEnfaseModel>> {
    return this.httpClient
      .post<IBaseModel<ITipoEnfaseModel>>(`${this.apiBaseUrl}/tipoEnfase`, data)
      .toPromise();
  }

  public async atualizar(data: ITipoEnfaseModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/tipoEnfase/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipoEnfase/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipoEnfase/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/tipoEnfase/${id}/excluir`, {})
      .toPromise();
  }
}
