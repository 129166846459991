import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filterBy } from '../core/helpers/filterby';
import { IBaseModel } from '../models/base.model';
import { ICargaCriterioPeaModel } from '../models/carga-criterio-pea.model';
import { IAutuacaoModel } from '../models/edocs/autuacao.model';
import { IEnviarQuestionarioModel } from '../models/enviar-questionario.model';
import { FiltroModel } from '../models/filtro.model';
import { IImprimirPeaModel } from '../models/imprimir-pea.model';
import { IPaaModel } from '../models/paa.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { IPaineisDistribuicaoModel } from '../models/paineis-distribuicao.model';
import { IPeaCoordenacaoCapacidadeModel } from '../models/pea-coordenacao-capacidade.model';
import { IPeaCriterioQuestionarioModel } from '../models/pea-criterio-questionario.model';
import { IPeaCriterioRespostaModel } from '../models/pea-criterio-resposta.model';
import { IPeaRankingPrioridadeModel } from '../models/pea-ranking-prioridade.model';
import { IPeaModel } from '../models/pea.model';
import { IPendenciasRankingModel } from '../models/pendencias-ranking.model';
import { IQuestionarioModel } from '../models/questionario.model';
import { IResponsavelUnidadeGestoraModel } from '../models/responsavel-unidade-gestora.model';
import { IValidacaoNivelModel } from '../models/validaca-nivel-aprovacao.model';
import { BaseService } from './base.service';
import { IPeaDadosBasicosModel } from '../models/pea-dados-basicos.model';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class PeaService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(
    pesquisa: string,
    ativo: boolean,
    simplificado = false,
    filtro?: FiltroModel
  ): Promise<IBaseModel<PaginacaoModel<IPeaModel[]>>> {
    let params = new HttpParams();
    if (ativo !== undefined) {
      params = params.append('pesquisa', pesquisa || '');

      if (ativo !== undefined) {
        params = params.append('ativo', ativo.toString());
      }

      if (simplificado) {
        params = params.append('simplificado', simplificado.toString());
      }
    }

    if(filtro && Object.keys(filtro).length > 0) {
      return this.httpClient
      .get<IBaseModel<PaginacaoModel<IPeaModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/pea`), { params })
      .toPromise();
    }

    return this.httpClient
      .get<IBaseModel<PaginacaoModel<IPeaModel[]>>>(`${this.apiBaseUrl}/pea`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<IPeaModel>> {
    return this.httpClient
      .get<IBaseModel<IPeaModel>>(`${this.apiBaseUrl}/pea/${id}`)
      .toPromise();
  }

  public async obterDadosBasicos(id: number): Promise<IBaseModel<IPeaDadosBasicosModel>>{
    return this.httpClient
    .get<IBaseModel<IPeaDadosBasicosModel>>(`${this.apiBaseUrl}/pea/${id}/dadosbasicos`)
    .toPromise();
  }


  public async imprimirPea(id: number): Promise<IBaseModel<string>> {
    return this.httpClient
      .get<IBaseModel<string>>(`${this.apiBaseUrl}/pea/${id}/imprimir-pea`)
      .toPromise();
  }

  public async imprimirPeaConsolidado(ids: string): Promise<IBaseModel<IImprimirPeaModel[]>> {
      let params = new HttpParams();
      ids.split(',').map(_ => params = params.append('ids', _));
      
    return this.httpClient
      .get<IBaseModel<IImprimirPeaModel[]>>(`${this.apiBaseUrl}/pea/imprimir-pea-consolidado`, {params})
      .toPromise();
  }

  public async obterAnosPaa(id: number): Promise<IBaseModel<Array<number>>> {
    return this.httpClient
      .get<IBaseModel<Array<number>>>(
        `${this.apiBaseUrl}/pea/${id}/anos-disponiveis-paa`
      )
      .toPromise();
  }

  public async obterCargaCriteriosPorId(
    id: number
  ): Promise<IBaseModel<ICargaCriterioPeaModel[]>> {
    return this.httpClient
      .get<IBaseModel<ICargaCriterioPeaModel[]>>(
        `${this.apiBaseUrl}/pea/${id}/carga-criterios`
      )
      .toPromise();
  }

  public async inserir(data: IPeaDadosBasicosModel): Promise<IBaseModel<IPeaDadosBasicosModel>> {
    return this.httpClient
      .post<IBaseModel<IPeaModel>>(`${this.apiBaseUrl}/pea`, data)
      .toPromise();
  }

  public async atualizar(data: IPeaModel): Promise<IBaseModel<IPeaModel>> {
    return this.httpClient
      .put<IBaseModel<IPeaModel>>(`${this.apiBaseUrl}/pea/${data.id}`, data)
      .toPromise();
  }

  public async atualizarDadosBasicos(data: IPeaDadosBasicosModel): Promise<IBaseModel<IPeaDadosBasicosModel>>{
    return this.httpClient
    .put<IBaseModel<IPeaDadosBasicosModel>>(`${this.apiBaseUrl}/pea/${data.id}/dadosBasicos`, data)
    .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/pea/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/pea/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/pea/${id}/excluir`, {})
      .toPromise();
  }

  public async enviarAprovacao(id: number, idPerfil: string): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(
        `${this.apiBaseUrl}/pea/${id}/enviar-aprovacao/${idPerfil}`,
        {}
      )
      .toPromise();
  }

  public async enviarRespostasCriterio(
    id: number,
    dados: IPeaCriterioRespostaModel[]
  ): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(
        `${this.apiBaseUrl}/pea/${id}/responder-criterio`,
        dados
      )
      .toPromise();
  }

  public async importarRespostas(
    id: number,
    fileData: string
  ): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(
        `${this.apiBaseUrl}/pea/${id}/importar-respostas`,
        { fileData }
      )
      .toPromise();
  }

  public async obterCapacidadeTrabalhoPorId(
    id: number
  ): Promise<IBaseModel<IPeaCoordenacaoCapacidadeModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPeaCoordenacaoCapacidadeModel[]>>(
        `${this.apiBaseUrl}/pea/${id}/capacidade-trabalho`
      )
      .toPromise();
  }

  public async obterCapacidadeTrabalhoPorIdAndAno(
    id: number,
    ano: number
  ): Promise<IBaseModel<IPeaCoordenacaoCapacidadeModel[]>> {
    let params = new HttpParams();
    params = params.append('ano', ano.toString());

    return this.httpClient
      .get<IBaseModel<IPeaCoordenacaoCapacidadeModel[]>>(
        `${this.apiBaseUrl}/pea/${id}/capacidade-trabalho`,
        { params }
      )
      .toPromise();
  }

  public async obterPainelDistribuicaoPorPeaId(
    id: number
  ): Promise<IBaseModel<IPaineisDistribuicaoModel>> {
    return this.httpClient
      .get<IBaseModel<IPaineisDistribuicaoModel>>(
        `${this.apiBaseUrl}/pea/${id}/painel-distribuicao`
      )
      .toPromise();
  }

  public async atualizarCapacidadeTrabalho(
    id: number,
    item: IPeaCoordenacaoCapacidadeModel
  ): Promise<IBaseModel<IPeaCoordenacaoCapacidadeModel[]>> {
    return this.httpClient
      .post<IBaseModel<IPeaCoordenacaoCapacidadeModel[]>>(
        `${this.apiBaseUrl}/pea/${id}/capacidade-trabalho`,
        item
      )
      .toPromise();
  }

  public async enviarQuestionarios(id: number, model: IEnviarQuestionarioModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(
        `${this.apiBaseUrl}/pea/${id}/enviar-questionario`,
        model
      )
      .toPromise();
  }

  public async obterQuestionarioPendente(
    pesquisa: string
  ): Promise<IBaseModel<IPeaCriterioQuestionarioModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    return this.httpClient
      .get<IBaseModel<IPeaCriterioQuestionarioModel[]>>(
        `${this.apiBaseUrl}/questionario/pea`,
        { params }
      )
      .toPromise();
  }

  public async obterQuestionarioPorPeaIdeCriterioId(
    peaId: number,
    criterioId: number
  ): Promise<IBaseModel<IQuestionarioModel>> {
    return this.httpClient
      .get<IBaseModel<IQuestionarioModel>>(
        `${this.apiBaseUrl}/questionario/pea/${peaId}/criterio/${criterioId}`
      )
      .toPromise();
  }

  public async salvarRespostasQuestionarioPorPeaId(
    id: number,
    data: IPeaCriterioQuestionarioModel[]
  ): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/questionario/pea/${id}`, data)
      .toPromise();
  }

  public async enviarRespostasQuestionarioPorPeaId(
    id: number,
    data: IPeaCriterioQuestionarioModel[]
  ): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(
        `${this.apiBaseUrl}/questionario/${id}/enviar`,
        data
      )
      .toPromise();
  }

  public async aprovar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/pea/${id}/aprovar`, {})
      .toPromise();
  }

  public async homologar(
    id: number,
    autuacao: IAutuacaoModel
  ): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(
        `${this.apiBaseUrl}/pea/${id}/homologar`,
        autuacao
      )
      .toPromise();
  }

  public async rejeitar(
    id: number,
    justificativa: string
  ): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/pea/${id}/rejeitar`, {
        justificativa,
      })
      .toPromise();
  }

  public async gerarPaa(
    id: number,
    data: IPaaModel
  ): Promise<IBaseModel<IPaaModel>> {
    return this.httpClient
      .post<IBaseModel<IPaaModel>>(
        `${this.apiBaseUrl}/pea/${id}/gerar-paa`,
        data
      )
      .toPromise();
  }

  public async ObterResponsaveisUG(
    idUg: number
  ): Promise<IBaseModel<IResponsavelUnidadeGestoraModel>> {
    return this.httpClient
      .get<IBaseModel<IResponsavelUnidadeGestoraModel>>(
        `${this.apiBaseUrl}/questionario/pea/responsaveis-ug/${idUg}`
      )
      .toPromise();
  }

  public DelegarQuestionario(
    peaId: number,
    criterioId: number,
    idResponsavel: string,
    unidadeGestoraId: number
  ): Promise<IBaseModel<any>> {
    return this.httpClient
      .put<IBaseModel<boolean>>(
        `${this.apiBaseUrl}/questionario/pea/${peaId}/responsavel/${idResponsavel}/criterio/${criterioId}/ug/${unidadeGestoraId}`,
        {}
      )
      .toPromise();
  }

  public async obterVisualizacaoQuestionario(
    id: number
  ): Promise<IBaseModel<IPeaCriterioQuestionarioModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPeaCriterioQuestionarioModel[]>>(
        `${this.apiBaseUrl}/pea/${id}/visualizar-questionario`,
        {}
      )
      .toPromise();
  }

  public async enviarQuestionarioCancelado(
    id: number
  ): Promise<IBaseModel<IPeaModel>> {
    return this.httpClient
      .get<IBaseModel<IPeaModel>>(
        `${this.apiBaseUrl}/pea/${id}/cancelar-questionario`
      )
      .toPromise();
  }

  public async questionariosRespondidos(
    id: number
  ): Promise<IBaseModel<IPeaCriterioQuestionarioModel[]>> {
    return this.httpClient
      .get<IBaseModel<IPeaCriterioQuestionarioModel[]>>(
        `${this.apiBaseUrl}/pea/${id}/questionario-respondido`,
        {}
      )
      .toPromise();
  }

  public GerarRankingDistribuicao(
    peaId: number
  ): Promise<IBaseModel<IPendenciasRankingModel>> {
    return this.httpClient
      .put<IBaseModel<IPendenciasRankingModel>>(
        `${this.apiBaseUrl}/pearankingprioridade/ranking-distribuicao/${peaId}/peaId`,
        {}
      )
      .toPromise();
  }

  public AtualizarFaixaClassificacao(data: IPeaModel): Promise<IBaseModel<boolean>>{
    return this.httpClient
          .put<IBaseModel<boolean>>(`${this.apiBaseUrl}/pea/${data.id}/atualizar-faixaclassificacao`, data)
          .toPromise();
  }

  public imprimir(id: number): Promise<any>{
    return this.httpClient
      .get(`${this.apiBaseUrl}/pea/${id}/imprimir`)
      .toPromise();
  }

  public async verificarNivelAprovacao(id: number): Promise<IBaseModel<IValidacaoNivelModel>> {
    return this.httpClient
      .get<IBaseModel<IValidacaoNivelModel>>(`${this.apiBaseUrl}/pea/${id}/verificar-nivel-aprovacao`)
      .toPromise();
  }

  public async removerClassificacaoPersonalizada(id: number): Promise<IBaseModel<void>>{
    return this.httpClient
    .post<IBaseModel<void>>(`${this.apiBaseUrl}/pea/${id}/remover-classificacao-personalizada`, {})
    .toPromise();
  }

}
