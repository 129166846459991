import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { INaturezaResponsabilidadeModel } from 'src/app/models/natureza-responsabilidade';
import { NaturezaResponsabilidadeService } from 'src/app/services/natureza-responsabilidade.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';

@Component({
  selector: 'app-natureza-responsabilidade-form',
  templateUrl: './natureza-responsabilidade-form.component.html',
  styleUrls: ['./natureza-responsabilidade-form.component.scss'],
})
export class NaturezaResponsabilidadeFormComponent extends BaseFormComponent implements OnInit {
  public model: INaturezaResponsabilidadeModel = {} as INaturezaResponsabilidadeModel;

  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private naturezaResponsabilidadeService: NaturezaResponsabilidadeService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  async ngOnInit() {

    try {
      if (!this.novoRegistro) {
        const res = await this.naturezaResponsabilidadeService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.novoRegistro) {
            this.titulo = 'Novo Natureza da Responsabilidade';
          } else {
            this.titulo = 'Editar Natureza da Responsabilidade - ' + this.model.nome;
          }
          if (this.visualizar) {
            this.titulo = 'Visualizar Natureza da Responsabilidade - ' + this.model.nome;
          }

        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/natureza-responsabilidade']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
      this.router.navigate(['/natureza-responsabilidade']);
    }
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    this.submit = true;
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }
    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<void> = null;

      if (!this.novoRegistro) {
        res = await this.naturezaResponsabilidadeService.atualizar(this.model);
      } else {
        res = await this.naturezaResponsabilidadeService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: 'success',
          text: res.mensagem.descricao
        });

        if(salvarEFechar == true)
          this.router.navigate(['/natureza-responsabilidade']);
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    }
    catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

   public async onSubmit() {
    this.salvarDados(false);
  }

  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.submit = false;
    this.router.navigate(['/natureza-responsabilidade']);
  }

  public cancelar() {
    this.router.navigate(['/natureza-responsabilidade']);
  }
}
