import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { INaturezaResponsabilidadeModel } from '../models/natureza-responsabilidade';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class NaturezaResponsabilidadeService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(pesquisa: string, ativo: boolean, simplificado = false): Promise<IBaseModel<INaturezaResponsabilidadeModel[]>> {
    let params = new HttpParams();
    params = params.append('pesquisa', pesquisa || '');

    if (ativo !== undefined) {
      params = params.append('ativo', ativo.toString());
    }

    if (simplificado) {
      params = params.append('simplificado', simplificado.toString());
    }

    return this.httpClient
      .get<IBaseModel<INaturezaResponsabilidadeModel[]>>(`${this.apiBaseUrl}/naturezaresponsabilidade`, { params })
      .toPromise();
  }

  public async obterPorId(id: number): Promise<IBaseModel<INaturezaResponsabilidadeModel>> {
    return this.httpClient
      .get<IBaseModel<INaturezaResponsabilidadeModel>>(`${this.apiBaseUrl}/naturezaresponsabilidade/${id}`)
      .toPromise();
  }

  public async inserir(data: INaturezaResponsabilidadeModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/naturezaresponsabilidade`, data)
      .toPromise();
  }

  public async atualizar(data: INaturezaResponsabilidadeModel): Promise<IBaseModel<void>> {
    return this.httpClient
      .put<IBaseModel<void>>(`${this.apiBaseUrl}/naturezaresponsabilidade/${data.id}`, data)
      .toPromise();
  }

  public async reativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/naturezaresponsabilidade/${id}/reativar`, {})
      .toPromise();
  }

  public async inativar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/naturezaresponsabilidade/${id}/inativar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/naturezaresponsabilidade/${id}/excluir/`, {})
      .toPromise();
  }
  
}

