<div id="modal-content-a-equipe">
  <div
    class="card card-secondary card-outline"
    cdkDragBoundary="html"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
  >
    <div class="card-header bg-light" cdkDragHandle>
      <h4 class="card-title mb-0">{{titulo}}</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-0">
      <form
        [formGroup]="form"
        #f="ngForm"
        (ngSubmit)="concluir()"
      >
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="nome" class="mb-1">Nome</label>
              <input
                type="text"
                id="nome"
                class="form-control form-control-sm"
                formControlName="nome"
                maxlength="100"
                [ngClass]="{
                  'is-invalid': f.submitted && form.controls['nome'].invalid
                }"
              />
              <div
                *ngIf="f.submitted && form.controls['nome'].invalid"
                class="invalid-feedback"
              >
                <div *ngIf="form.controls['nome'].errors.required">
                  Campo obrigatório
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-8">
              <label for="conteudo" class="mb-1">Conteúdo</label>
              <angular-editor
                [config]="config"
                id="conteudo"
                rows="20"
                formControlName="conteudo"
                [ngClass]="{
                  'is-invalid':
                    f.submitted && form.controls['conteudo'].invalid
                }"
              ></angular-editor>
              <div
                *ngIf="f.submitted && form.controls['conteudo'].invalid"
                class="invalid-feedback"
              >
                <div *ngIf="form.controls['conteudo'].errors.required">
                  Campo obrigatório
                </div>
              </div>
            </div>
            <mat-form-field class="tags-chip-list col-md-4 mt-2" appearance="fill">
              <label for="chipList" class="mb-1">Tags</label>
              <mat-chip-list *ngIf="data?.tipoTrabalho == 1" #chipList aria-label="Tags" multiple>
                <mat-chip
                    *ngFor="let keyword of keywords"
                    color="primary"
                    [selected]="keyword"
                    [value]="keyword"
                    (click)="addTag(keyword)">
                  {{keyword}}
                </mat-chip>
              </mat-chip-list>
              <mat-chip-list *ngIf="data?.tipoTrabalho == 2" #chipList aria-label="Tags" multiple>
                <mat-chip
                    *ngFor="let keyword of keywordsPaa"
                    color="primary"
                    [selected]="keyword"
                    [value]="keyword"
                    (click)="addTag(keyword)">
                  {{keyword}}
                </mat-chip>
              </mat-chip-list>
              <mat-chip-list *ngIf="data?.tipoTrabalho == 8" #chipList aria-label="Tags" multiple>
                <mat-chip
                    *ngFor="let keyword of keywordsPaaEspecial"
                    color="primary"
                    [selected]="keyword"
                    [value]="keyword"
                    (click)="addTag(keyword)">
                  {{keyword}}
                </mat-chip>
              </mat-chip-list>
              <mat-chip-list *ngIf="data?.tipoTrabalho == 3" #chipList aria-label="Tags" multiple>
                <mat-chip
                    *ngFor="let keyword of keywordsOpa"
                    color="primary"
                    [selected]="keyword"
                    [value]="keyword"
                    (click)="addTag(keyword)">
                  {{keyword}}
                </mat-chip>
              </mat-chip-list>
              <mat-chip-list *ngIf="data?.tipoTrabalho == 7" #chipList aria-label="Tags" multiple>
                <mat-chip
                    *ngFor="let keyword of keywordsPlanejamento"
                    color="primary"
                    [selected]="keyword"
                    [value]="keyword"
                    (click)="addTag(keyword)">
                  {{keyword}}
                </mat-chip>
              </mat-chip-list>
              <mat-chip-list *ngIf="data?.tipoTrabalho == 4" #chipList aria-label="Tags" multiple>
                <mat-chip
                    *ngFor="let keyword of keywordsOsa"
                    color="primary"
                    [selected]="keyword"
                    [value]="keyword"
                    (click)="addTag(keyword)">
                  {{keyword}}
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>

        <div class="card-footer">
          <button type="submit" class="btn btn-primary btn-sm mr-1">
            <i class="far fa-save"></i> Salvar
          </button>

          <button
            type="button"
            class="btn btn-default btn-sm"
            (click)="cancelar()"
          >
            <i class="fas fa-times"></i> Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
