import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { IEnumModel } from '../models/enum.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class TabelaDominioService extends BaseService {

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  private obterValores(tabela: string): Promise<IBaseModel<IEnumModel[]>> {
    return this.httpClient
      .get<IBaseModel<IEnumModel[]>>(`${this.apiBaseUrl}/TabelaDominio/${tabela}`)
      .toPromise();
  }

  public obterTipoEntradaCriterio(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('TipoEntradaCriterio');
  }

  public obterTipoAgente(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('TipoAgente');
  }

  public obterTipoAto(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('TipoAto');
  }

  public obterTipoEntradaPergunta(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('TipoEntradaPergunta');
  }

  public obterTipoComparacao(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('TipoComparacao');
  }

  public obterTipoCapituloRelatorio(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('TipoCapituloRelatorio');
  }

  public async obterTipoAdministracao() {
    const request = this.httpClient
      .get(`${this.apiBaseUrl}/TipoAdministracao`)
      .toPromise();

    const response = await Promise.all([request]);
    return response;
  }

  public obterOperadorMembroAprovador(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('OperadorMembroAprovador');
  }

  public obterPerfilCargo(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('PerfilCargo');
  }

  public obterFuncaoResponsavelUnidadeGestora(): Promise<IBaseModel<IEnumModel[]>> {
    return this.obterValores('FuncaoResponsavelUnidadeGestora');
  }
}
