export enum EnumRecursoPerfil {
  Cadastros = 'CadastrosBasicos',
  ManterPAA = 'Manter PAA',
  ManterPEA = 'Manter PEA',
  ManterOPA = 'Manter OPA',
  Configuracoes = 'Configurações',
  Planejamento = 'Planejamento',
  ManterOSA='Manter OSA',
  Auditoria='Auditoria',
  Aprovacoes='Aprovações',
  LogsDoSistema='Logs do Sistema',
  Aberto='Aberto'
}